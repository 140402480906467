import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { generateChartOption, processData, processDataToLablesAndDataSets, } from "../Redux/processData/ProcessWater";
import { processDate } from "../../../components/WaterandEffluents/process";



const ChartComponent = ({ height, width, BreakDownOptions, data,Filterkey }) => {
  const [legendData, setLegendData] = useState([]);

  const [options, setOptions] = useState({
    title: {
      text: "Stacked Bar Chart",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: [],
    },
    // label: {
    //   show: true,
    //   position: "right",
    //   formatter: "{b}",
    // },
    xAxis: [
      {
        type: "category",
        data: [],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [],
  });
  const [breakPoint, setBreakPoint] = useState("");
  const [timeInterval, setTimeInterval] = useState("Quarter"); //default
  const handleChartIntervalChange = (event) => {
    setTimeInterval(event.target.value);
  };
  const handleChartBreakPointChange = (event) => {
    setBreakPoint(event.target.value);
  };
  useEffect(() => {
    const text="over Time"
    const proccesedData = processData(data, timeInterval, breakPoint,null,Filterkey);
  
    const { xAxisLabels, series } =
      processDataToLablesAndDataSets(proccesedData,Filterkey);
    
    const option = generateChartOption(
      Filterkey === "withdrawal_quantity"
      ? `Water Withdrawal ${text}`
      : Filterkey === "consumed_quantity"
      ? `Water Consumed ${text}`
      : Filterkey === "discharge_quantity"
      ? `Water Discharge ${text}`
      : "",
      legendData,
      xAxisLabels,
      series,
      Filterkey
    ); //generate chart options
    setOptions(option);
  }, [breakPoint, timeInterval, data]);

  const onLegendSelectChanged = (params) => {
    setLegendData(params.selected);
  };
  const legendMargin = {
    id: "legendMargin",
    beforeInit: function (chart) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.height += 40);
      };
    }
  };
  return (
    <>
      <div className=" w-full flex justify-end px-3 gap-3 items-center text-center">
        <label htmlFor="interval">Time Interval :</label>
        <select
          name="interval"
          id="timeInterval"
          className=" py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          value={timeInterval}
          onChange={handleChartIntervalChange}
        >
          <option value="" disabled>
            Select Option
          </option>

          <option value="Quarter">Quarterly</option>
          <option value="Month">Monthly</option>
          <option value="Year">Yearly</option>
        </select>

        <label htmlFor="breakpoint">Breakdown :</label>
        <select
          name="breakpoint"
          id="breakpoint"
          className="py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          value={breakPoint}
          onChange={handleChartBreakPointChange}
        >
          <option value="">No Breakdown</option>
          {BreakDownOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {options && (
        <ReactECharts
          style={{ minHeight:height, width }}
          option={options}
          notMerge={true}
          lazyUpdate={true}
          plu
          // onEvents={{
          //   legendselectchanged: onLegendSelectChanged,
          // }}
        />
      )}
    </>
  );
};

export default ChartComponent;
