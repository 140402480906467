import React, { useEffect } from "react";
import {
  filterDataByKeys,
  getLatestYearPreviousYearObjects,
  processBenefitData,
  ProcessRetirementData,
} from "../../function/DataProcessFunction";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../../customHooks/useApi";
import {
  fetchSocialEmpDemographicInputs,
  fetchSocialRetirementBenefitsInputs,
} from "../../../../features/social/socialThunk";

function RetirementBenifitDiscloser({ question }) {
  const { socialEmpDemographicsRecords, socialRetirementBenefitsRecords } =
    useSelector((state) => state.social);
    const { reportYear} =
    useSelector((state) => state.BRSR.FinancialYearFilter);
  const dispatch = useDispatch();
  const api = useApi();
  const { currentYear, previousYear } = getLatestYearPreviousYearObjects(
    socialEmpDemographicsRecords,reportYear
  );
  const {
    currentYear: currentYearRetirementData,
    previousYear: previousYearRetirementData,
  } = getLatestYearPreviousYearObjects(socialRetirementBenefitsRecords,reportYear);
const data =ProcessRetirementData(currentYear,previousYear,currentYearRetirementData,previousYearRetirementData)

  useEffect(() => {
    dispatch(fetchSocialRetirementBenefitsInputs({ api, params: {} }));
    // dispatch(fetchSocialEmpDemographicInputs({ api, params: {} }));
  }, []);
  return (
    <div>
      <table className="min-w-full border border-gray-300 text-sm text-gray-700">
        <thead>
          <tr className="bg-gray-300">
            <th
              colSpan={7}
              className="border border-gray-300 px-4 py-2 text-start text-base font-semibold"
            >
              {question}{" "}
            </th>
          </tr>
          <tr className="bg-gray-100">
            <th
              className="border border-gray-300 px-4 py-2 text-center"
              rowSpan={2}
            >
              Benefits
            </th>
            <th
              className="border border-gray-300 px-4 py-2 text-center"
              colSpan={3}
            >
              Current Reporting Year
            </th>
            <th
              className="border border-gray-300 px-4 py-2 text-center"
              colSpan={3}
            >
              Previous Reporting Year
            </th>
          </tr>
          <tr className="bg-gray-100 ">
            <th className="border border-gray-300 px-4 py-2 text-pretty">
              No. of employees covered as a % of total employees
            </th>
            <th className="border border-gray-300 px-4 py-2 text-pretty">
              No. of workers covered as a % of total workers
            </th>
            <th className="border border-gray-300 px-4 py-2 text-pretty">
              Deducted and deposited with the authority (Y/N/N.A.)
            </th>
            <th className="border border-gray-300 px-4 py-2 text-pretty">
              No. of employees covered as a % of total employees
            </th>
            <th className="border border-gray-300 px-4 py-2 text-pretty">
              No. of workers covered as a % of total workers
            </th>
            <th className="border border-gray-300 px-4 py-2 text-pretty">
              Deducted and deposited with the authority (Y/N/N.A.)
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="text-center">
              <td className="border border-gray-300 px-4 py-2">
                {item.benefit}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {item.currentYear.employeeCoverage}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {item.currentYear.workerCoverage}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {item.currentYear.deposited}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {item.previousYear.employeeCoverage}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {item.previousYear.workerCoverage}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {item.previousYear.deposited}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RetirementBenifitDiscloser;
