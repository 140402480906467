import React, { useEffect, useState } from "react";
import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";
import { ReactComponent as WaterIcon } from "../../app/assets/WasteProducedIcon.svg";
import Header from "../../components/common/Header";
import AnalyzeHeader from "../../components/Analyze/AnalyzeHeader";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../VisualizationFeature/components/Filter";
import useApi from "../../customHooks/useApi";
import WaterMain from "./WaterMain";
import { fetchWaterDataThunk } from "../VisualizationFeature/Redux/water/analyzeThunk";
import { calculateAggregateWaterMetrics } from "../VisualizationFeature/Redux/processData/ProcessWater";
function AnalyzeWater() {
  const dispatch = useDispatch();
  const api = useApi();
  const [StackBreakDown] = useState(["water_type","water_source"]);
  const {
 
    siteOptions,
    data,
    dataForCurrentYearChange,
    financialYear,
  } = useSelector((state) => state.water);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(
    financialYear.length ? financialYear[0] : ""
  );
  const [waterChange, setWaterChange] = useState({});
  const [chartDataArray] = useState([
    { title: "by Water Type", dataKey: "water_type" },
    { title: "by Water Source", dataKey: "water_source" },
    
    
  ]);
  const [fuelBreakDownOptions] = useState([
    { value: "site", label: "Facility" },
    { value: "water_source", label: "Water Source" },
    { value: "water_type", label: "Water Type" },
   
  
    
  ]);
  const [filterBy, setFilterBy] = useState({
    site: "",
   
  });
  const handleFilterChange = (name, value) => {
    if (name === "financialYear") {
      setCurrentFinancialYear(value);
    } else {
      setFilterBy((prevFilterBy) => ({
        ...prevFilterBy,
        [name]: value,
      }));
    }
  };

  const emissionData = [
    {
      title: "Water Withdrawal",
      info: (waterChange?.totalWithdrawal?.toFixed() || 0) + " Tonnes",
      time: "",
      status:
        (waterChange?.changes?.withdrawalChange|| 0) > 0 ? "positive" : "negative",
      per: `${
        waterChange?.changes?.withdrawalChange!== Infinity
          ? `${Math.abs(waterChange?.changes?.withdrawalChange)}% YOY${
              waterChange?.changes?.withdrawalChange> 0 ? "↑" : "↓"
            }`
          : ""
      }`,
      year: currentFinancialYear,
      theme: "red",
    },

  
  ];
  
  const energyData = [ {
        status: (waterChange?.changes?.consumedPercentageChange || 0) > 0 ? "positive" : "negative",
        title: "Water Consumed",
        info: `${waterChange?.consumedPercentage !== Infinity ? `${Math.abs(waterChange?.consumedPercentage)||0}%` : ""}`,
        percentage: `${waterChange?.changes?.consumedPercentageChange !== Infinity ?
           `${Math.abs(waterChange?.changes?.consumedPercentageChange)||0}% YOY${waterChange?.changes?.consumedPercentageChange > 0 ? "↑" : "↓"}`
            : ""}`
    },
    {
        status: (waterChange?.changes?.dischargePercentageChange || 0) > 0 ? "positive" : "negative",
        title: "Water Discharge",
        info: `${waterChange?.dischargePercentage !== Infinity ? `${Math.abs(waterChange?.dischargePercentage)||0}%` : ""}`,
        percentage: `${waterChange?.changes?.dischargePercentageChange !== Infinity ?
           `${Math.abs(waterChange?.changes?.dischargePercentageChange)||0}% YOY${waterChange?.changes?.dischargePercentageChange > 0 ? "↑" : "↓"}`
            : ""}`
    }
   
];

  useEffect(() => {
    setWaterChange(
      calculateAggregateWaterMetrics(
        dataForCurrentYearChange,
        Number(currentFinancialYear)
      )
    );
  }, [data, dataForCurrentYearChange, currentFinancialYear]);
  useEffect(() => {
    dispatch(fetchWaterDataThunk({ api }));
  }, [dispatch]);
  useEffect(() => {
    if (financialYear.length > 0) {
      setCurrentFinancialYear(financialYear[0]);
    }
  }, [financialYear]);

  return (
    <>
      <Header PageIcon={WaterIcon} pageTitle={"Water"} />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader emissionData={emissionData} energyData={energyData} />

        {/* filter */}

        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>

            <div className="flex justify-between items-center gap-2 px-2">
              <FilterMenuIcon className="cursor-pointer" />
              <Filter
                label="Financial Year"
                options={financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange("financialYear", value)}
              />
              <Filter
                label="Facility"
                options={siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange("site", value)}
              />

              {/* <Filter
                label="Water material"
                options={waterMaterialOptions}
                selectedValue={filterBy.water_material}
                onChange={(value) =>
                  handleFilterChange("water_material", value)
                }
              />

              <Filter
                label="Treatment mechanism"
                options={waterTreatmentOptions}
                selectedValue={filterBy.treatment_mechanism}
                onChange={(value) =>
                  handleFilterChange("treatment_mechanism", value)
                }
              /> */}
            </div>
          </div>
          <div className="border p-2 mt-2 bg-gray-100 rounded-md">
          <h2 className="text-xl font-bold my-4 w-full text-center">Water Withdrawal</h2>
          <div className="bg-white rounded-md">
            <WaterMain
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={data}
              chartDataArray={[
                { title: "by Water Type", dataKey: "water_type" },
    { title: "by Water Source", dataKey: "water_source" },
               
              ]}
              Options={fuelBreakDownOptions}
              StackBreakDown={["water_source","water_type"]}
              Filterkey={"withdrawal_quantity"}
              currentFinancialYear={Number(currentFinancialYear)}
            />
          </div> 
          </div> <div className="border p-2 mt-2 bg-gray-100 rounded-md">
          <h2 className="text-xl font-bold my-4 w-full text-center">Water Consumed</h2>
          <div className="bg-white rounded-md">
            <WaterMain
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={data}
              chartDataArray={chartDataArray}
              Options={[
                { value: "site", label: "Facility" },
              
                { value: "water_source", label: "Water Source" },
                { value: "water_quality", label: "Water Quality" },
                // { value: "discharge_destination", label: "Water Discharge Destination" },
              
                
              ]}
              StackBreakDown={StackBreakDown}
              Filterkey={"consumed_quantity"}
              currentFinancialYear={Number(currentFinancialYear)}
            />
          </div>
          </div>
          <div className="border p-2 mt-2 bg-gray-100 rounded-md">
          <h2 className="text-xl font-bold my-4 w-full text-center">Water Discharged</h2>
          <div className="bg-white rounded-md">
            <WaterMain
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={data}
              chartDataArray={[{ title: "by Water Quality", dataKey: "water_quality" },{ title: "by Water Discharge Destination", dataKey: "discharge_destination" }]}
              Options={[
                { value: "site", label: "Facility" },
               
                { value: "water_quality", label: "Water Quality" },
                { value: "discharge_destination", label: "Water Discharge Destination" },
              
                
              ]}
              StackBreakDown={["water_quality","discharge_destination"]}
              Filterkey={"discharge_quantity"}
              currentFinancialYear={Number(currentFinancialYear)}
            />
          </div>
          </div>
          
         
        </div>
      </div>
    </>
  );
}

export default AnalyzeWater;
