
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "../../customHooks/useApi";

import FormGroup from "./FormGroup";

import { calculateAllowableQuantity,calculateQuantity } from "./process";
import { setFormValues } from "../../features/waterandeffluents/WaterandEffluentsSlice";
import { postWaterandEffluentsDischargeData, WaterandEffluentsUploadEvidence } from "../../features/waterandeffluents/WaterandEffluentsThunk";

const WaterAndEffluentsDischargeForm = ({ closePurchasePopupForm }) => {
  const api = useApi();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.common.isLoading);
  const formValues = useSelector((state) => state.WaterAndEffluents.formValues);
  const withdrawalForm = useSelector(
    (state) => state.WaterAndEffluents.WaterAndEffluentsWithdrawalForm
  );
  
  const unitOptions = useSelector((state) => state.WaterAndEffluents.unitData);
  const siteData = useSelector((state) => state.WaterAndEffluents.siteData);
  const waterDischargeDestinations = useSelector(
    (state) => state.WaterAndEffluents.waterDischargeDestination
  );
  const waterDischargeQuality = useSelector(
    (state) => state.WaterAndEffluents.waterDischargeQuality
  );

  const [files, setFiles] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isExceed, setIsExceed] = useState(false);

  const initialFormValue = {
    discharge_period_start: "",
    discharge_period_end: "",
    discharge_site_id: withdrawalForm.withdrawal_site_id || "",
    discharge_quantity: "",
    discharge_quality_id: "",
    discharge_destination_id:"",
    discharge_unit_id: withdrawalForm.withdrawal_unit_id || "",
    discharge_evidence: null,
  };

  const handleFormChange = async(e, index) => {
    const { name, value, files } = e.target;
    const updatedValues = [...formValues]; // Copy the current form values
  
    // Handle file uploads specifically for discharge evidence
    if (name === "discharge_evidence" && files) {
       const selectedFiles = files;
            try {
              const res = await dispatch(
                WaterandEffluentsUploadEvidence({ api, files: selectedFiles })
              );
              if (res?.payload?.path) {
                updatedValues[index] = {
                  ...updatedValues[index],
                  evidence: res.payload.path,
                };
               console.log(res.payload.path)
                setFiles(selectedFiles);
              } else {
                toast.error("Failed to upload evidence");
              }
            } catch (error) {
              console.error("File upload error:", error);
            }
    }
  
    // Update the form values for the specific index
    updatedValues[index] = {
      ...updatedValues[index],
      [name]: value,
    };
  
    // Handle updates for discharge quality
    const updatedDischargeQuality = 
      name === "discharge_quality_id"
        ? waterDischargeQuality.find((item) => item.id == value)?.discharge_quality || value
        : value;
  
    const updatedDischargeQualityPayload = 
      name === "discharge_quality_id"
        ? {discharge_quality: updatedDischargeQuality, [name]: value }
        : { [name]: value };
  
    // Handle updates for discharge destination
    const updatedDestination = 
      name === "discharge_destination_id"
        ? waterDischargeDestinations.find((item) => item.id == value)?.discharge_destination || value
        : value;
  
    const updatedDestinationPayload = 
      name === "discharge_destination_id"
        ? {discharge_destination: updatedDestination, [name]: value }
        : { [name]: value };
  
    // Calculate discharge quantity if period start and end dates are available
    if (name === "discharge_period_start" || name === "discharge_period_end") {
      if (
        updatedValues[index].discharge_period_start &&
        updatedValues[index].discharge_period_end
      ) {
        const calculatedQuantity = calculateQuantity(
          updatedValues[index].discharge_period_start,
          updatedValues[index].discharge_period_end,
          withdrawalForm.withdrawal_period_start,
          withdrawalForm.withdrawal_period_end,
          Number(withdrawalForm.withdrawal_quantity),
          100 // Replace with the correct proportion if needed
        ).toFixed();
  
        updatedValues[index].discharge_quantity = calculatedQuantity > 0 ? calculatedQuantity : "";
      }
    }
  
    // Merge updated payload into the form values for the specific index
    updatedValues[index] = {
      ...updatedValues[index],
      ...(name === "discharge_quality_id" ? updatedDischargeQualityPayload : {}),
      ...(name === "discharge_destination_id" ? updatedDestinationPayload : {}),
    };
  
    dispatch(setFormValues(updatedValues));
  };
  

  const handleAddNew = () => {
    const form = document.querySelector("#water-effluents-form");
    if (!form.checkValidity()) {
      const firstInvalidControl = form.querySelector(":invalid");
      toast.error(`Error: ${firstInvalidControl.validationMessage}`);
      firstInvalidControl.focus();
      return;
    }

    if (!validateQuantities(formValues)) {
      return;
    }

    dispatch(setFormValues([...formValues, { ...initialFormValue }]));
  };

  const handleRemove = (index) => {
    const updatedValues = formValues.filter((_, i) => i !== index);
    dispatch(setFormValues(updatedValues));
  };

  const handleCopy = (index) => {
    const copiedValue = { ...formValues[index], discharge_quantity: 0 };
    const updatedValues = [...formValues];
    updatedValues.splice(index + 1, 0, copiedValue);

    if (!validateQuantities(updatedValues)) {
      return;
    }

    dispatch(setFormValues(updatedValues));
  };

  const validateQuantities = (values) => {
    const periods = values.map((item) => ({
      start: item.discharge_period_start,
      end: item.discharge_period_end,
      quantity: Number(item.discharge_quantity),
    }));

    const validationResult = calculateAllowableQuantity(
      withdrawalForm.withdrawal_period_start,
      withdrawalForm.withdrawal_period_end,
      Number(withdrawalForm.withdrawal_quantity),
      periods
    );

    if (validationResult === "All treated quantities are valid.") {
      setIsExceed(false);
      return true;
    } else {
      setIsExceed(true);
      toast.error(validationResult, { theme: "dark" });
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateQuantities(formValues)) {
      return;
    }
        const subIds = formValues.map(() => uuidv4());
        dispatch(
          postWaterandEffluentsDischargeData({
            api,
            i: 1,
            formValues: formValues.map((i, index) => {
              return {
                ...i,
                sub_id: subIds[index],
              };
            }),
          })
        );
        closePurchasePopupForm() 
    // if (files) {
    //   // Handle submission logic, including evidence upload
    //   closePurchasePopupForm();
    // } else {
    //   toast.error("Please select evidence");
    // }
  };

  useEffect(() => {
    const updatedValues = formValues.map((value) => ({
      ...value,
      discharge_site_id: withdrawalForm.withdrawal_site_id,
      discharge_unit_id: withdrawalForm.withdrawal_unit_id,
    }));
    dispatch(setFormValues(updatedValues));
  }, [withdrawalForm, shouldUpdate]);

  return (
    <div className="flex flex-col justify-center items-center rounded-t-md text-xs">
      <form
        id="water-effluents-form"
        className="flex flex-col w-full py-4"
        onSubmit={handleSubmit}
        style={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? "none" : "auto" }}
      >
        {formValues.length > 0 ? (
          formValues.map((formValue, index) => (
            <FormGroup
              key={index}
              formValue={formValue}
              handleFormChange={(e) => handleFormChange(e, index)}
              handleRemove={() => handleRemove(index)}
              handleCopy={() => handleCopy(index)}
              unitOptions={unitOptions}
              siteData={siteData}
              waterDischargeDestinations={waterDischargeDestinations}
              waterDischargeQuality={waterDischargeQuality}
            />
          ))
        ) : (
          <h1 className="w-full flex items-center justify-center py-6">
            No discharge records for the specified time period
          </h1>
        )}

        <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
          <button
            type="button"
            onClick={handleAddNew}
            className="py-2 px-6 text-green-600 text-sm rounded-md"
          >
            Add New
          </button>
          <button
            type="button"
            onClick={closePurchasePopupForm}
            className="py-2 px-6 text-green-600 text-sm rounded-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className={`py-2 px-6 text-white text-sm ${
              loading ? "bg-green-500 cursor-wait" : "bg-green-600"
            } rounded-md`}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default WaterAndEffluentsDischargeForm;
