export function getLatestYearObjects(data,financialYear) {
    // Find the maximum financial year
    const latestYear = financialYear || Math.max(...data.map(item => parseInt(item.financial_year, 10)));
    const latestYearData = data.filter(item => parseInt(item.financial_year, 10) === Number(latestYear));

if(latestYearData[0]?.site){
  return latestYearData.filter(item => item?.site === "OverAll" || item?.site)  
}
return latestYearData
}
export function getLatestYearPreviousYearObjects(data,financialYear) {
    // Find the maximum financial year
    const latestYear = financialYear || Math.max(...data.map(item => parseInt(item.financial_year, 10)));

    const latestYearData = data.filter(item => parseInt(item.financial_year, 10) === latestYear);
    const previousYearData = data.filter(item => parseInt(item.financial_year, 10) === latestYear-1);

if(latestYearData[0]?.site){
  return {currentYear:latestYearData.filter(item => item?.site === "OverAll" || item?.site),previousYear:previousYearData.filter(item => item?.site === "OverAll" || item?.site)}  
}
return {currentYear:latestYearData,previousYear:previousYearData}
}





export function filterDataByKeys(data, keyValues) {
  if (!Array.isArray(data)) {
    throw new Error("The data should be an array of objects.");
  }

  return data.filter(item =>
    Object.entries(keyValues).every(([key, value]) =>
      Array.isArray(value) ? value.includes(item[key]) : item[key] === value
    )
  );
}






export function getGenderwiseDataWithTotal(data) {
  const result = {
    male: {
      life_insurance_coverage: 0,
      disability_invalidity_coverage: 0,
      stock_ownership_coverage: 0,
      health_insurance_coverage: 0,
      accident_insurance_coverage: 0,
      maternity_benefits_coverage: 0,
      paternity_benefits_coverage: 0,
      day_care_facilities_coverage: 0,
      count: 0,
    },
    female: {
      life_insurance_coverage: 0,
      disability_invalidity_coverage: 0,
      stock_ownership_coverage: 0,
      health_insurance_coverage: 0,
      accident_insurance_coverage: 0,
      maternity_benefits_coverage: 0,
      paternity_benefits_coverage: 0,
      day_care_facilities_coverage: 0,
      count: 0,
    },
    other: {
      life_insurance_coverage: 0,
      disability_invalidity_coverage: 0,
      stock_ownership_coverage: 0,
      health_insurance_coverage: 0,
      accident_insurance_coverage: 0,
      maternity_benefits_coverage: 0,
      paternity_benefits_coverage: 0,
      day_care_facilities_coverage: 0,
      count: 0,
    },
    'not disclosed': {
      life_insurance_coverage: 0,
      disability_invalidity_coverage: 0,
      stock_ownership_coverage: 0,
      health_insurance_coverage: 0,
      accident_insurance_coverage: 0,
      maternity_benefits_coverage: 0,
      paternity_benefits_coverage: 0,
      day_care_facilities_coverage: 0,
      count: 0,
    },
  };

  // Initialize a total object
  const total = {
    life_insurance_coverage: 0,
    disability_invalidity_coverage: 0,
    stock_ownership_coverage: 0,
    health_insurance_coverage: 0,
    accident_insurance_coverage: 0,
    maternity_benefits_coverage: 0,
    paternity_benefits_coverage: 0,
    day_care_facilities_coverage: 0,
    count: 0,
  };

  // Iterate through the data
  data.forEach((item) => {
    const { gender } = item;

    // Ensure gender category exists in result
    if (!result[gender]) {
      return; // Skip if gender is not recognized
    }

    // Aggregate values for the gender
    result[gender].life_insurance_coverage += Number(item.life_insurance_coverage);
    result[gender].disability_invalidity_coverage += Number(item.disability_invalidity_coverage);
    result[gender].stock_ownership_coverage += Number(item.stock_ownership_coverage);
    result[gender].health_insurance_coverage += Number(item.health_insurance_coverage);
    result[gender].accident_insurance_coverage += Number(item.accident_insurance_coverage);
    result[gender].maternity_benefits_coverage += Number(item.maternity_benefits_coverage);
    result[gender].paternity_benefits_coverage += Number(item.paternity_benefits_coverage);
    result[gender].day_care_facilities_coverage += Number(item.day_care_facilities_coverage);
    result[gender].count++;

    // Aggregate values for the total
    total.life_insurance_coverage += Number(item.life_insurance_coverage);
    total.disability_invalidity_coverage += Number(item.disability_invalidity_coverage);
    total.stock_ownership_coverage += Number(item.stock_ownership_coverage);
    total.health_insurance_coverage += Number(item.health_insurance_coverage);
    total.accident_insurance_coverage += Number(item.accident_insurance_coverage);
    total.maternity_benefits_coverage += Number(item.maternity_benefits_coverage);
    total.paternity_benefits_coverage += Number(item.paternity_benefits_coverage);
    total.day_care_facilities_coverage += Number(item.day_care_facilities_coverage);
    total.count++;
  });

  // Add total category to result
  result.total = total;

  return result;
}



export function processBenefitData(rawData) {
  const calculateCoverage = (covered, total) => {
    return total > 0 ? `${((covered / total) * 100).toFixed()}%` : "0%";
  };

  const getDepositedStatus = (data) => {
    if (data.length === 0) return "X";
    const statuses = data.map((entry) => entry.deducted_and_deposited_with_authority);
   
    return statuses.includes("no") ? "No": statuses.includes("na") ? "N.A." : "Yes";
  };

  const benefits = [
    { key: "pf", name: "PF" },
    { key: "gratuity", name: "Gratuity" },
    { key: "esi", name: "ESI" },
  ];

  const processedData = benefits.map(({ key, name }) => {
    const currentYearEmployee = rawData[`${key}CurrentYearEmployee`];
    const currentYearWorker = rawData[`${key}CurrentYearWorker`];
    const previousYearEmployee = rawData[`${key}PreviousYearEmployee`];
    const previousYearWorker = rawData[`${key}PreviousYearWorker`];

    return {
      benefit: name,
      currentYear: {
        employeeCoverage: calculateCoverage(
          currentYearEmployee.reduce((sum, entry) => sum + Number(entry.number_of_employees_covered), 0),
          rawData.empDemographicCurrentYearCount
        ),
        workerCoverage: calculateCoverage(
          currentYearWorker.reduce((sum, entry) => sum + Number(entry.number_of_employees_covered), 0),
          rawData.workersDemographicCurrentYearCount
        ),
        deposited: getDepositedStatus(currentYearEmployee.concat(currentYearWorker)),
      },
      previousYear: {
        employeeCoverage: calculateCoverage(
          previousYearEmployee.reduce((sum, entry) => sum + Number(entry.number_of_employees_covered), 0),
          rawData.empDemographicPreviousYearCount
        ),
        workerCoverage: calculateCoverage(
          previousYearWorker.reduce((sum, entry) => sum + Number(entry.number_of_employees_covered), 0),
          rawData.workersDemographicPreviousYearCount
        ),
        deposited: getDepositedStatus(previousYearEmployee.concat(previousYearWorker)),
      },
    };
  });

  return processedData;
}





export function processParentalLeaveData(rawData) {
  const summary = {
      Male: { totalTookLeave: 0, totalReturned: 0, totalRetained: 0 },
      Female: { totalTookLeave: 0, totalReturned: 0, totalRetained: 0 },
      Total: { totalTookLeave: 0, totalReturned: 0, totalRetained: 0 },
  };

  // Aggregate data for Male and Female
  rawData.forEach((entry) => {
      const gender = entry.gender.toLowerCase();
      if (gender === "male" || gender === "female") {
          const genderKey = gender.charAt(0).toUpperCase() + gender.slice(1);
          summary[genderKey].totalTookLeave += entry.employees_took_parental_leave;
          summary[genderKey].totalReturned += entry.employees_returned_after_parental_leave;
          summary[genderKey].totalRetained += entry.employees_still_employed_12_months_after_return;
      }

      // Aggregate into Total
      summary["Total"].totalTookLeave += entry.employees_took_parental_leave;
      summary["Total"].totalReturned += entry.employees_returned_after_parental_leave;
      summary["Total"].totalRetained += entry.employees_still_employed_12_months_after_return;
  });

  // Transform summary into the desired structure
  const result = ["Male", "Female", "Total"].map((gender) => {
      const data = summary[gender];
      const returnToWorkRate =
          data.totalTookLeave > 0 ? data.totalReturned / data.totalTookLeave : 0;
      const retentionRate =
          data.totalTookLeave > 0 ? data.totalRetained / data.totalTookLeave : 0;

      return {
          gender,
          permanentEmployee: {
              returnToWorkRate: parseFloat(returnToWorkRate.toFixed(2)),
              retentionRate: parseFloat(retentionRate.toFixed(2)),
          },
          permanentWorker: {
              returnToWorkRate: parseFloat(returnToWorkRate.toFixed(2)),
              retentionRate: parseFloat(retentionRate.toFixed(2)),
          },
      };
  });

  return result;
}




export function processSafetyData(employeesCurrentYear, employeesPreviousYear, workersCurrentYear, workersPreviousYear) {
  // Helper function to calculate aggregated values
  function calculateAggregates(data, category) {
    return data
      .filter(item => item.employement_category === category)
      .reduce(
        (acc, item) => {
          acc.lost_time_injury_frequency_rate += parseFloat(item.lost_time_injury_frequency_rate || 0);
          acc.total_recordable_work_related_injuries += parseInt(item.total_recordable_work_related_injuries || 0, 10);
          acc.fatalities += parseInt(item.fatalities || 0, 10);
          acc.high_consequence_work_related_injury_or_ill_health += parseInt(item.high_consequence_work_related_injury_or_ill_health || 0, 10);
          return acc;
        },
        {
          lost_time_injury_frequency_rate: 0,
          total_recordable_work_related_injuries: 0,
          fatalities: 0,
          high_consequence_work_related_injury_or_ill_health: 0,
        }
      );
  }

  // Calculate aggregates for each category and year
  const employeesCurrentYearAgg = calculateAggregates(employeesCurrentYear, "Employees");
  const employeesPreviousYearAgg = calculateAggregates(employeesPreviousYear, "Employees");
  const workersCurrentYearAgg = calculateAggregates(workersCurrentYear, "Workers");
  const workersPreviousYearAgg = calculateAggregates(workersPreviousYear, "Workers");

  // Map data to the required format
  const result = [
    {
      incident: "Injury Frequency Rate (LTIFR per million-person hour worked)",
      categories: [
        { type: "Employees", currentYear: employeesCurrentYearAgg.lost_time_injury_frequency_rate, previousYear: employeesPreviousYearAgg.lost_time_injury_frequency_rate },
        { type: "Workers", currentYear: workersCurrentYearAgg.lost_time_injury_frequency_rate, previousYear: workersPreviousYearAgg.lost_time_injury_frequency_rate },
      ],
    },
    {
      incident: "Total recordable work-related injuries",
      categories: [
        { type: "Employees", currentYear: employeesCurrentYearAgg.total_recordable_work_related_injuries, previousYear: employeesPreviousYearAgg.total_recordable_work_related_injuries },
        { type: "Workers", currentYear: workersCurrentYearAgg.total_recordable_work_related_injuries, previousYear: workersPreviousYearAgg.total_recordable_work_related_injuries },
      ],
    },
    {
      incident: "No. of fatalities; consequence work-related injury",
      categories: [
        { type: "Employees", currentYear: employeesCurrentYearAgg.fatalities, previousYear: employeesPreviousYearAgg.fatalities },
        { type: "Workers", currentYear: workersCurrentYearAgg.fatalities, previousYear: workersPreviousYearAgg.fatalities },
      ],
    },
    {
      incident: "No. of fatalities; consequence work-related ill-health (excluding fatalities)",
      categories: [
        {
          type: "Employees",
          currentYear: employeesCurrentYearAgg.high_consequence_work_related_injury_or_ill_health,
          previousYear: employeesPreviousYearAgg.high_consequence_work_related_injury_or_ill_health,
        },
        {
          type: "Workers",
          currentYear: workersCurrentYearAgg.high_consequence_work_related_injury_or_ill_health,
          previousYear: workersPreviousYearAgg.high_consequence_work_related_injury_or_ill_health,
        },
      ],
    },
  ];

  return result;
}




export function ProcessHealthAndSafetyData(currentYear, previousYear ,previousYearTrainingData,currentYearTrainingData){

  
  const empDemographicCurrentYear = filterDataByKeys(currentYear, {
  employee_type: ["Employees"],
});
const workersDemographicCurrentYear = filterDataByKeys(currentYear, {
  employee_type: ["Workers"],
});
const empDemographicPreviousYear = filterDataByKeys(previousYear, {
  employee_type: ["Employees"],
});
const workersDemographicPreviousYear = filterDataByKeys(previousYear, {
  employee_type: ["Workers"],
});
const empTrainingCurrentYear = filterDataByKeys(currentYearTrainingData, {
  employement_category: ["Permanent Employees"],
});
const workersTrainingCurrentYear = filterDataByKeys(currentYearTrainingData, {
  employement_category: ["Permanent Workers"],
});
const empTrainingPreviousYear = filterDataByKeys(previousYearTrainingData, {
  employement_category: ["Permanent Employees"],
});
const workersTrainingPreviousYear = filterDataByKeys(previousYearTrainingData, {
  employement_category: ["Permanent Workers"],
});
const empTrainingCurrentYearMale = filterDataByKeys(currentYearTrainingData, {
  employement_category: ["Permanent Employees"],gender:["male"]
});
const workersTrainingCurrentYearMale = filterDataByKeys(currentYearTrainingData, {
  employement_category: ["Permanent Workers"],gender:["male"]
});
const empTrainingPreviousYearMale = filterDataByKeys(previousYearTrainingData, {
  employement_category: ["Permanent Employees"],gender:["male"]
});
const workersTrainingPreviousYearMale = filterDataByKeys(previousYearTrainingData, {
  employement_category: ["Permanent Workers"],gender:["male"]
});
const empTrainingCurrentYearFemale = filterDataByKeys(currentYearTrainingData, {
  employement_category: ["Permanent Employees"],gender:["female"]
});
const workersTrainingCurrentYearFemale = filterDataByKeys(currentYearTrainingData, {
  employement_category: ["Permanent Workers"],gender:["female"]
});
const empTrainingPreviousYearFemale = filterDataByKeys(previousYearTrainingData, {
  employement_category: ["Permanent Employees"],gender:["female"]
});
const workersTrainingPreviousYearFemale = filterDataByKeys(previousYearTrainingData, {
  employement_category: ["Permanent Workers"],gender:["female"]
});


const empDemographicCurrentYearCount = empDemographicCurrentYear.reduce(
  (acc, curr) => acc + Number(curr.no_of_employees),
  0
);
const workersDemographicCurrentYearCount =
  workersDemographicCurrentYear.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
const empDemographicPreviousYearCount = empDemographicPreviousYear.reduce(
  (acc, curr) => acc + Number(curr.no_of_employees),
  0
);
const workersDemographicPreviousYearCount =
  workersDemographicPreviousYear.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
const sumReducer = (acc,curr) => {
  acc.health_safety_training+=Number(curr.health_safety_training);
  acc.skill_upgradation_training+=Number(curr.skill_upgradation_training);
  return acc;
}
const empTrainingCurrentYearMaleCount = empTrainingCurrentYearMale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });
const workersTrainingCurrentYearMaleCount = workersTrainingCurrentYearMale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });
const empTrainingPreviousYearMaleCount = empTrainingPreviousYearMale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });
const workersTrainingPreviousYearMaleCount = workersTrainingPreviousYearMale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });

const empTrainingCurrentYearFemaleCount = empTrainingCurrentYearFemale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });
const workersTrainingCurrentYearFemaleCount = workersTrainingCurrentYearFemale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });
const empTrainingPreviousYearFemaleCount = empTrainingPreviousYearFemale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });
const workersTrainingPreviousYearFemaleCount = workersTrainingPreviousYearFemale.reduce(sumReducer, { health_safety_training: 0, skill_upgradation_training: 0 });

const empTrainingCurrentYearCount = empTrainingCurrentYear.reduce(sumReducer,{health_safety_training:0,skill_upgradation_training:0});
const workersTrainingCurrentYearCount = workersTrainingCurrentYear.reduce(sumReducer,{health_safety_training:0,skill_upgradation_training:0});
const empTrainingPreviousYearCount = empTrainingPreviousYear.reduce(sumReducer,{health_safety_training:0,skill_upgradation_training:0});
const workersTrainingPreviousYearCount = workersTrainingPreviousYear.reduce(sumReducer,{health_safety_training:0,skill_upgradation_training:0});
const calculatePercentage = (value, total) => {
  return total > 0 ? `${((value / total) * 100).toFixed(2)}%` : "0%";
}
  const data = {
    employees: {
      currentYear: {
        male: { healthSafety: { number: empTrainingCurrentYearMaleCount.health_safety_training, percentage: calculatePercentage(empTrainingCurrentYearMaleCount.health_safety_training,empDemographicCurrentYearCount) }, skillUpgrade: { number: empTrainingCurrentYearMaleCount.skill_upgradation_training, percentage: calculatePercentage(empTrainingCurrentYearMaleCount.skill_upgradation_training,empDemographicCurrentYearCount) } },
        female: { healthSafety: { number: empTrainingCurrentYearFemaleCount.health_safety_training, percentage: calculatePercentage(empTrainingCurrentYearFemaleCount.health_safety_training,empDemographicCurrentYearCount) }, skillUpgrade: { number: empTrainingCurrentYearFemaleCount.skill_upgradation_training, percentage: calculatePercentage(empTrainingCurrentYearFemaleCount.skill_upgradation_training,empDemographicCurrentYearCount) } },
        total: { healthSafety: { number: empTrainingCurrentYearCount.health_safety_training, percentage: calculatePercentage(empTrainingCurrentYearCount.health_safety_training,empDemographicCurrentYearCount) }, skillUpgrade: { number: empTrainingCurrentYearCount.skill_upgradation_training, percentage: calculatePercentage(empTrainingCurrentYearCount.skill_upgradation_training,empDemographicCurrentYearCount) } },
      },
      previousYear: {
        male: { healthSafety: { number: empTrainingPreviousYearMaleCount.health_safety_training, percentage: calculatePercentage(empTrainingPreviousYearMaleCount.health_safety_training,empDemographicPreviousYearCount) }, skillUpgrade: { number: empTrainingPreviousYearMaleCount.skill_upgradation_training, percentage: calculatePercentage(empTrainingPreviousYearMaleCount.skill_upgradation_training,empDemographicPreviousYearCount) } },
        female: { healthSafety: { number: empTrainingPreviousYearFemaleCount.health_safety_training, percentage: calculatePercentage(empTrainingPreviousYearFemaleCount.health_safety_training,empDemographicPreviousYearCount) }, skillUpgrade: { number: empTrainingPreviousYearFemaleCount.skill_upgradation_training, percentage: calculatePercentage(empTrainingPreviousYearFemaleCount.skill_upgradation_training,empDemographicPreviousYearCount) } },
        total: { healthSafety: { number: empTrainingPreviousYearCount.health_safety_training, percentage: calculatePercentage(empTrainingPreviousYearCount.health_safety_training,empDemographicPreviousYearCount) }, skillUpgrade: { number: empTrainingPreviousYearCount.skill_upgradation_training, percentage: calculatePercentage(empTrainingPreviousYearCount.skill_upgradation_training,empDemographicPreviousYearCount) } },
      },
      totals: { totalA: empDemographicCurrentYearCount, totalD: empDemographicPreviousYearCount },
    },
    workers: {
      currentYear: {
        male: { healthSafety: { number: workersTrainingCurrentYearMaleCount.health_safety_training, percentage: calculatePercentage(workersTrainingCurrentYearMaleCount.health_safety_training,workersDemographicCurrentYearCount) }, skillUpgrade: { number: workersTrainingCurrentYearMaleCount.skill_upgradation_training, percentage: calculatePercentage(workersTrainingCurrentYearMaleCount.skill_upgradation_training,workersDemographicCurrentYearCount) } },
        female: { healthSafety: { number: workersTrainingCurrentYearFemaleCount.health_safety_training, percentage: calculatePercentage(workersTrainingCurrentYearFemaleCount.health_safety_training,workersDemographicCurrentYearCount) }, skillUpgrade: { number: workersTrainingCurrentYearFemaleCount.skill_upgradation_training, percentage: calculatePercentage(workersTrainingCurrentYearFemaleCount.skill_upgradation_training,workersDemographicCurrentYearCount) } },
        total: { healthSafety: { number: workersTrainingCurrentYearCount.health_safety_training, percentage: calculatePercentage(workersTrainingCurrentYearCount.health_safety_training,workersDemographicCurrentYearCount) }, skillUpgrade: { number: workersTrainingCurrentYearCount.skill_upgradation_training, percentage: calculatePercentage(workersTrainingCurrentYearCount.skill_upgradation_training,workersDemographicCurrentYearCount) } },
      },
      previousYear: {
        male: { healthSafety: { number: workersTrainingPreviousYearMaleCount.health_safety_training, percentage: calculatePercentage(workersTrainingPreviousYearMaleCount.health_safety_training,workersDemographicPreviousYearCount) }, skillUpgrade: { number: workersTrainingPreviousYearMaleCount.skill_upgradation_training, percentage: calculatePercentage(workersTrainingPreviousYearMaleCount.skill_upgradation_training,workersDemographicPreviousYearCount) } },
        female: { healthSafety: { number: workersTrainingPreviousYearFemaleCount.health_safety_training, percentage: calculatePercentage(workersTrainingPreviousYearFemaleCount.health_safety_training,workersDemographicPreviousYearCount) }, skillUpgrade: { number: workersTrainingPreviousYearFemaleCount.skill_upgradation_training, percentage: calculatePercentage(workersTrainingPreviousYearFemaleCount.skill_upgradation_training,workersDemographicPreviousYearCount) } },
        total: { healthSafety: { number: workersTrainingPreviousYearCount.health_safety_training, percentage: calculatePercentage(workersTrainingPreviousYearCount.health_safety_training,workersDemographicPreviousYearCount) }, skillUpgrade: { number: workersTrainingPreviousYearCount.skill_upgradation_training, percentage: calculatePercentage(workersTrainingPreviousYearCount.skill_upgradation_training,workersDemographicPreviousYearCount) } },
      },
      totals: { totalA: workersDemographicCurrentYearCount, totalD: workersDemographicPreviousYearCount },
    },
  }
  return data
}



export function ProcessRetirementData(
  currentYear,
  previousYear,
  currentYearRetirementData,
  previousYearRetirementData
) {
  const empDemographicCurrentYear = filterDataByKeys(currentYear, {
    employee_type: ["Employees"],
  });
  const workersDemographicCurrentYear = filterDataByKeys(currentYear, {
    employee_type: ["Workers"],
  });
  const empDemographicPreviousYear = filterDataByKeys(previousYear, {
    employee_type: ["Employees"],
  });
  const workersDemographicPreviousYear = filterDataByKeys(previousYear, {
    employee_type: ["Workers"],
  });

  const empDemographicCurrentYearCount = empDemographicCurrentYear.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
  const workersDemographicCurrentYearCount =
    workersDemographicCurrentYear.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );
  const empDemographicPreviousYearCount = empDemographicPreviousYear.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
  const workersDemographicPreviousYearCount =
    workersDemographicPreviousYear.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );
  const retirementDataCurrentYearEmployee = filterDataByKeys(
    currentYearRetirementData,
    { employement_category: ["Permanent Employees"] }
  );
  const retirementDataCurrentYearWorker = filterDataByKeys(
    currentYearRetirementData,
    { employement_category: ["Permanent Workers"] }
  );
  const retirementDataPreviousYearEmployee = filterDataByKeys(
    previousYearRetirementData,
    { employement_category: ["Permanent Employees"] }
  );
  const retirementDataPreviousYearWorker = filterDataByKeys(
    previousYearRetirementData,
    { employement_category: ["Permanent Workers"] }
  );

  const esiCurrentYearEmployee = filterDataByKeys(
    retirementDataCurrentYearEmployee,
    { benefit_type: ["esi"] }
  );
  const gratuityCurrentYearEmployee = filterDataByKeys(
    retirementDataCurrentYearEmployee,
    { benefit_type: ["gratuity"] }
  );
  const pfCurrentYearEmployee = filterDataByKeys(
    retirementDataCurrentYearEmployee,
    { benefit_type: ["pf"] }
  );

  const esiCurrentYearWorker = filterDataByKeys(
    retirementDataCurrentYearWorker,
    { benefit_type: ["esi"] }
  );
  const gratuityCurrentYearWorker = filterDataByKeys(
    retirementDataCurrentYearWorker,
    { benefit_type: ["gratuity"] }
  );
  const pfCurrentYearWorker = filterDataByKeys(
    retirementDataCurrentYearWorker,
    { benefit_type: ["pf"] }
  );

  const esiPreviousYearEmployee = filterDataByKeys(
    retirementDataPreviousYearEmployee,
    { benefit_type: ["esi"] }
  );
  const gratuityPreviousYearEmployee = filterDataByKeys(
    retirementDataPreviousYearEmployee,
    { benefit_type: ["gratuity"] }
  );
  const pfPreviousYearEmployee = filterDataByKeys(
    retirementDataPreviousYearEmployee,
    { benefit_type: ["pf"] }
  );

  const esiPreviousYearWorker = filterDataByKeys(
    retirementDataPreviousYearWorker,
    { benefit_type: ["esi"] }
  );
  const gratuityPreviousYearWorker = filterDataByKeys(
    retirementDataPreviousYearWorker,
    { benefit_type: ["gratuity"] }
  );
  const pfPreviousYearWorker = filterDataByKeys(
    retirementDataPreviousYearWorker,
    { benefit_type: ["pf"] }
  );
 
  const data = processBenefitData({
    empDemographicCurrentYearCount,
    workersDemographicCurrentYearCount,
    empDemographicPreviousYearCount,
    workersDemographicPreviousYearCount,
    esiCurrentYearEmployee,
    gratuityCurrentYearEmployee,
    pfCurrentYearEmployee,
    esiCurrentYearWorker,
    gratuityCurrentYearWorker,
    pfCurrentYearWorker,
    esiPreviousYearEmployee,
    gratuityPreviousYearEmployee,
    pfPreviousYearEmployee,
    esiPreviousYearWorker,
    gratuityPreviousYearWorker,
    pfPreviousYearWorker,
  });
  return data
}

export  function ProcessUnionData(currentYear, previousYear,unionCurrentYear,unionPreviousYear){

  const unionCurrentYearMale = filterDataByKeys(unionCurrentYear, {
    gender: ["male"],
  });
  const unionCurrentYearFemale = filterDataByKeys(unionCurrentYear, {
    gender: ["female"],
  });
  const unionPreviousYearMale = filterDataByKeys(unionPreviousYear, {
    gender: ["male"],
  });
  const unionPreviousYearFemale = filterDataByKeys(unionPreviousYear, {
    gender: ["female"],
  });
  const unionPreviousYearTotal = filterDataByKeys(unionPreviousYear, {});
  const unionCurrentYearTotal = filterDataByKeys(unionCurrentYear, {});
  const calculateTotals = (data, switchCase) => {
    const switchCases = {
        "employee": "no_of_employees_in_union",
        "worker": "no_of_workers_in_union",
    }
    return data.reduce(
        (total, item) => {
            total += parseInt(item[switchCases[switchCase]], 10);
            return total
        },
        0
    );
};





  const empDemographicCurrentYearMale = filterDataByKeys(currentYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["male"],
    employee_type: ["Employees"],
  });
  const empDemographicCurrentYearFemale = filterDataByKeys(currentYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["female"],
    employee_type: ["Employees"],
  });
  const empDemographicPreviousYearMale = filterDataByKeys(previousYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["male"],
    employee_type: ["Employees"],
  });
  const empDemographicPreviousYearFemale = filterDataByKeys(previousYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["female"],
    employee_type: ["Employees"],
  });
  const workersDemographicCurrentYearMale = filterDataByKeys(currentYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["male"],
    employee_type: ["Workers"],
  });
  const workersDemographicCurrentYearFemale = filterDataByKeys(currentYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["female"],
    employee_type: ["Workers"],
  });
  const workersDemographicPreviousYearMale = filterDataByKeys(previousYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["male"],
    employee_type: ["Workers"],
  });
  const workersDemographicPreviousYearFemale = filterDataByKeys(previousYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    gender: ["female"],
    employee_type: ["Workers"],
  });
  const empDemographicCurrentYearTotal = filterDataByKeys(currentYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    employee_type: ["Employees"],
  });
  const empDemographicPreviousYearTotal = filterDataByKeys(previousYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    employee_type: ["Employees"],
  });
  const workersDemographicPreviousYearTotal = filterDataByKeys(previousYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    employee_type: ["Workers"],
  });
  const workersDemographicCurrentYearTotal = filterDataByKeys(currentYear, {
    employement_category: ["Full Time", "Permanent Employee"],
    employee_type: ["Workers"],
  });

  const empDemographicCurrentYearMaleCount =
    empDemographicCurrentYearMale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const empDemographicCurrentYearFemaleCount =
    empDemographicCurrentYearFemale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const workersDemographicCurrentYearMaleCount =
    workersDemographicCurrentYearMale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const workersDemographicCurrentYearFemaleCount =
    workersDemographicCurrentYearFemale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const empDemographicPreviousYearMaleCount =
    empDemographicPreviousYearMale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const empDemographicPreviousYearFemaleCount =
    empDemographicPreviousYearFemale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const workersDemographicPreviousYearMaleCount =
    workersDemographicPreviousYearMale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const workersDemographicPreviousYearFemaleCount =
    workersDemographicPreviousYearFemale.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const empDemographicCurrentYearTotalCount =
    empDemographicCurrentYearTotal.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const empDemographicPreviousYearTotalCount =
    empDemographicPreviousYearTotal.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const workersDemographicPreviousYearTotalCount =
    workersDemographicPreviousYearTotal.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const workersDemographicCurrentYearTotalCount =
    workersDemographicCurrentYearTotal.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );


    const unionCurrentYearTotalEmployee = calculateTotals(unionCurrentYearTotal, "employee");
    const unionCurrentYearTotalWorker = calculateTotals(unionCurrentYearTotal, "worker");
    const unionPreviousYearTotalEmployee = calculateTotals(unionPreviousYearTotal, "employee");
    const unionPreviousYearTotalWorker = calculateTotals(unionPreviousYearTotal, "worker");
    const unionCurrentYearTotalMaleEmployee = calculateTotals(unionCurrentYearMale, "employee");
    const unionCurrentYearTotalFemaleEmployee = calculateTotals(unionCurrentYearFemale, "employee");
    const unionCurrentYearTotalMaleWorker = calculateTotals(unionCurrentYearMale, "worker");
    const unionCurrentYearTotalFemaleWorker = calculateTotals(unionCurrentYearFemale, "worker");
    const unionPreviousYearTotalMaleEmployee = calculateTotals(unionPreviousYearMale, "employee");
    const unionPreviousYearTotalFemaleEmployee = calculateTotals(unionPreviousYearFemale, "employee");
    const unionPreviousYearTotalMaleWorker = calculateTotals(unionPreviousYearMale, "worker");
    const unionPreviousYearTotalFemaleWorker = calculateTotals(unionPreviousYearFemale, "worker");

  const calculatePercentage = (total, associationMembers) => {
    return total > 0 ? ((associationMembers / total) * 100).toFixed(2) : "x";
  };

  const data = [
    {
      category: "Total Permanent Employees",
      currentYear: {
        totalEmployees: empDemographicCurrentYearTotalCount,
        associationMembers: unionCurrentYearTotalEmployee,
        percentage: calculatePercentage(
          empDemographicCurrentYearTotalCount,
          unionCurrentYearTotalEmployee
        ),
      },
      previousYear: {
        totalEmployees: empDemographicPreviousYearTotalCount,
        associationMembers: unionPreviousYearTotalEmployee,
        percentage: calculatePercentage(
          empDemographicPreviousYearTotalCount,
          unionPreviousYearTotalEmployee
        ),
      },
      // materiality: 'High, Sector Dependent',
    },
    {
      category: "Male Permanent Employees",
      currentYear: {
        totalEmployees: empDemographicCurrentYearMaleCount,
        associationMembers: unionCurrentYearTotalMaleEmployee,
        percentage: calculatePercentage(
          empDemographicCurrentYearMaleCount,
          unionCurrentYearTotalMaleEmployee
        ),
      },
      previousYear: {
        totalEmployees: empDemographicPreviousYearMaleCount,
        associationMembers: unionPreviousYearTotalMaleEmployee,
        percentage: calculatePercentage(
          empDemographicPreviousYearMaleCount,
          unionPreviousYearTotalMaleEmployee
        ),
      },
      // materiality: 'High, Sector Dependent',
    },
    {
      category: "Female Permanent Employees",
      currentYear: {
        totalEmployees: empDemographicCurrentYearFemaleCount,
        associationMembers: unionCurrentYearTotalFemaleEmployee,
        percentage: calculatePercentage(
          empDemographicCurrentYearFemaleCount,
          unionCurrentYearTotalFemaleEmployee
        ),
      },
      previousYear: {
        totalEmployees: empDemographicPreviousYearFemaleCount,
        associationMembers: unionPreviousYearTotalFemaleEmployee,
        percentage: calculatePercentage(
          empDemographicPreviousYearFemaleCount,
          unionPreviousYearTotalFemaleEmployee
        ),
      },
      // materiality: 'High, Sector Dependent',
    },
    {
      category: "Total Permanent Workers",
      currentYear: {
        totalEmployees: workersDemographicCurrentYearTotalCount,
        associationMembers: unionCurrentYearTotalWorker,
        percentage: calculatePercentage(
          workersDemographicCurrentYearTotalCount,
          unionCurrentYearTotalWorker
        ),
      },
      previousYear: {
        totalEmployees: workersDemographicPreviousYearTotalCount,
        associationMembers: unionPreviousYearTotalWorker,
        percentage: calculatePercentage(
          workersDemographicPreviousYearTotalCount,
          unionPreviousYearTotalWorker
        ),
      },
      // materiality: 'High, Sector Dependent',
    },
    {
      category: "Male Permanent Workers",
      currentYear: {
        totalEmployees: workersDemographicCurrentYearMaleCount,
        associationMembers: unionCurrentYearTotalMaleWorker,
        percentage: calculatePercentage(
          workersDemographicCurrentYearMaleCount,
          unionCurrentYearTotalMaleWorker
        ),
      },
      previousYear: {
        totalEmployees: workersDemographicPreviousYearMaleCount,
        associationMembers: unionPreviousYearTotalMaleWorker,
        percentage: calculatePercentage(
          workersDemographicPreviousYearMaleCount,
          unionPreviousYearTotalMaleWorker
        ),
      },
      // materiality: 'High, Sector Dependent',
    },
    {
      category: "Female Permanent Workers",
      currentYear: {
        totalEmployees: workersDemographicCurrentYearFemaleCount,
        associationMembers: unionCurrentYearTotalFemaleWorker,
        percentage: calculatePercentage(
          workersDemographicCurrentYearFemaleCount,
          unionCurrentYearTotalFemaleWorker
        ),
      },
      previousYear: {
        totalEmployees: workersDemographicPreviousYearFemaleCount,
        associationMembers: unionPreviousYearTotalFemaleWorker,
        percentage: calculatePercentage(
          workersDemographicPreviousYearFemaleCount,
          unionPreviousYearTotalFemaleWorker
        ),
      },
      // materiality: 'High, Sector Dependent',
    },
  ];
  return data
}

export function ProcessWellBeingData(
  socialEmpDemographicsRecords,
  socialEmpWellBeingsRecords,reportYear
) {
  const returnSocialEmpWellBeingsRecords = getLatestYearObjects(
    socialEmpWellBeingsRecords,reportYear
  );
  const returnSocialEmpDemographicsRecords = getLatestYearObjects(
    socialEmpDemographicsRecords,reportYear
  );

  const permanentEmpDemographicMale = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Full Time", "Permanent Employee"],
      gender: ["male"],
      employee_type: ["Employees"],
    }
  );
  const permanentEmpDemographicFemale = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Full Time", "Permanent Employee"],
      gender: ["female"],
      employee_type: ["Employees"],
    }
  );
  const permanentEmpDemographicTotal = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Full Time", "Permanent Employee"],
      employee_type: ["Employees"],
    }
  );
  const nonPermanentEmpDemographicMale = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Part Time", "Temporary"],
      gender: ["male"],
      employee_type: ["Employees"],
    }
  );
  const nonPermanentEmpDemographicFemale = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Part Time", "Temporary"],
      gender: ["female"],
      employee_type: ["Employees"],
    }
  );
  const nonPermanentEmpDemographicTotal = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Part Time", "Temporary"],
      employee_type: ["Employees"],
    }
  );
  const permanentEmpDemographicMaleWorkers = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Full Time", "Permanent Employee"],
      gender: ["male"],
      employee_type: ["Workers"],
    }
  );
  const permanentEmpDemographicFemaleWorkers = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Full Time", "Permanent Employee"],
      gender: ["female"],
      employee_type: ["Workers"],
    }
  );
  const permanentEmpDemographicTotalWorkers = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Full Time", "Permanent Employee"],
      employee_type: ["Workers"],
    }
  );
  const nonPermanentEmpDemographicMaleWorkers = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Part Time", "Temporary"],
      gender: ["male"],
      employee_type: ["Workers"],
    }
  );
  const nonPermanentEmpDemographicFemaleWorkers = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Part Time", "Temporary"],
      gender: ["female"],
      employee_type: ["Workers"],
    }
  );
  const nonPermanentEmpDemographicTotalWorkers = filterDataByKeys(
    returnSocialEmpDemographicsRecords,
    {
      employement_category: ["Part Time", "Temporary"],
      employee_type: ["Workers"],
    }
  );
  const maleNonPermanentEmpWorkersCount =
    nonPermanentEmpDemographicMaleWorkers.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );
  const femaleNonPermanentEmpWorkersCount =
    nonPermanentEmpDemographicFemaleWorkers.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );
  const totalNonPermanentEmpWorkersCount =
    nonPermanentEmpDemographicTotalWorkers.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );
  const malePermanentEmpWorkersCount =
    permanentEmpDemographicMaleWorkers.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );
  const femalePermanentEmpWorkersCount =
    permanentEmpDemographicFemaleWorkers.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );
  const totalPermanentEmpWorkersCount =
    permanentEmpDemographicTotalWorkers.reduce(
      (acc, curr) => acc + Number(curr.no_of_employees),
      0
    );

  const maleNonPermanentEmpCount = nonPermanentEmpDemographicMale.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
  const femaleNonPermanentEmpCount = nonPermanentEmpDemographicFemale.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
  const totalNonPermanentEmpCount = nonPermanentEmpDemographicTotal.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
  const malePermanentEmpCount = permanentEmpDemographicMale.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
  const femalePermanentEmpCount = permanentEmpDemographicFemale.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );
  const totalPermanentEmpCount = permanentEmpDemographicTotal.reduce(
    (acc, curr) => acc + Number(curr.no_of_employees),
    0
  );

  const permanentEmpWellBeings = filterDataByKeys(
    returnSocialEmpWellBeingsRecords,
    { employement_category: ["Permanent Employees"] }
  );
  const NonPermanentEmpWellBeings = filterDataByKeys(
    returnSocialEmpWellBeingsRecords,
    { employement_category: ["Non-Permanent Employees"] }
  );
  const permanentWorkersWellBeings = filterDataByKeys(
    returnSocialEmpWellBeingsRecords,
    { employement_category: ["Permanent Workers"] }
  );
  const NonPermanentWorkersWellBeings = filterDataByKeys(
    returnSocialEmpWellBeingsRecords,
    { employement_category: ["Non-Permanent Workers"] }
  );
  const permanentEmpWellBeingsGenderwiseData = getGenderwiseDataWithTotal(
    permanentEmpWellBeings
  );
  const NonPermanentEmpWellBeingsGenderwiseData = getGenderwiseDataWithTotal(
    NonPermanentEmpWellBeings
  );
  const PermanentEmpWorkersWellBeingsGenderwiseData =
    getGenderwiseDataWithTotal(permanentWorkersWellBeings);
  const NonPermanentWorkersWellBeingsGenderwiseData =
    getGenderwiseDataWithTotal(NonPermanentWorkersWellBeings);

  const calculateCoveragePercentage = (coverage, total) => {
  return total > 0 ? `${((coverage / total) * 100).toFixed()}%` : "0%";
};

const generateWellBeingData = (wellBeingData, countData) => {
  return [
    "male",
    "female",
    "total"
  ].map((category) => ({
    category: category.charAt(0).toUpperCase() + category.slice(1),
    total: countData[category],
    healthInsurance: {
      number: wellBeingData[category].health_insurance_coverage,
      percentage: calculateCoveragePercentage(
        wellBeingData[category].health_insurance_coverage,
        countData[category]
      ),
    },
    accidentInsurance: {
      number: wellBeingData[category].accident_insurance_coverage,
      percentage: calculateCoveragePercentage(
        wellBeingData[category].accident_insurance_coverage,
        countData[category]
      ),
    },
    maternityBenefits: {
      number: wellBeingData[category].maternity_benefits_coverage,
      percentage: calculateCoveragePercentage(
        wellBeingData[category].maternity_benefits_coverage,
        countData[category]
      ),
    },
    paternityBenefits: {
      number: wellBeingData[category].paternity_benefits_coverage,
      percentage: calculateCoveragePercentage(
        wellBeingData[category].paternity_benefits_coverage,
        countData[category]
      ),
    },
    dayCareFacilities: {
      number: wellBeingData[category].day_care_facilities_coverage,
      percentage: calculateCoveragePercentage(
        wellBeingData[category].day_care_facilities_coverage,
        countData[category]
      ),
    },
  }));
};

const dataPermanentEmp = generateWellBeingData(
  permanentEmpWellBeingsGenderwiseData,
  {
    male: malePermanentEmpCount,
    female: femalePermanentEmpCount,
    total: totalPermanentEmpCount,
  }
);

const dataOtherThenPermanentEmp = generateWellBeingData(
  NonPermanentEmpWellBeingsGenderwiseData,
  {
    male: maleNonPermanentEmpCount,
    female: femaleNonPermanentEmpCount,
    total: totalNonPermanentEmpCount,
  }
);

const dataNonPermanentWorkers = generateWellBeingData(
  NonPermanentWorkersWellBeingsGenderwiseData,
  {
    male: maleNonPermanentEmpWorkersCount,
    female: femaleNonPermanentEmpWorkersCount,
    total: totalNonPermanentEmpWorkersCount,
  }
);

const dataPermanentWorkers = generateWellBeingData(
  PermanentEmpWorkersWellBeingsGenderwiseData,
  {
    male: malePermanentEmpWorkersCount,
    female: femalePermanentEmpWorkersCount,
    total: totalPermanentEmpWorkersCount,
  }
);
  return {
    dataPermanentEmp,
    dataOtherThenPermanentEmp,
    dataNonPermanentWorkers,
    dataPermanentWorkers,
  };
}