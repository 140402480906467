import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../../customHooks/useApi';
import { getLatestYearPreviousYearObjects } from '../../function/DataProcessFunction';
import { fetchSocialComplaintsInputs } from '../../../../features/social/socialThunk';

const GrievanceTable = ({question}) => {
  const dispatch = useDispatch();
  const api = useApi();
  const { reportYear} =
  useSelector((state) => state.BRSR.FinancialYearFilter);
  const { socialComplaintsRecords } =
    useSelector((state) => state.social);
    const { currentYear, previousYear } = getLatestYearPreviousYearObjects(
      socialComplaintsRecords,reportYear
    );

    const complaintTypes = ['Working_Conditions', 'Health_and_Safety'];
    const data = complaintTypes.map((complaintType) => ({
      category: complaintType,
      currentFiled: currentYear?.find(item => item?.complaint_type === complaintType)?.no_of_complaint_per_year||0,
      currentPending: currentYear?.find(item => item?.complaint_type === complaintType)?.pending_resolution||0,
      currentRemarks: currentYear?.find(item => item?.complaint_type === complaintType)?.remark||0,
      previousFiled: previousYear?.find(item => item?.complaint_type === complaintType)?.no_of_complaint_per_year||0,
      previousPending: previousYear?.find(item => item?.complaint_type === complaintType)?.pending_resolution||0,
      previousRemarks: previousYear?.find(item => item?.complaint_type === complaintType)?.remark||0,
    }));

useEffect(()=>{
  dispatch(fetchSocialComplaintsInputs({api,params:{}}))
},[])
  return (
    <table className="min-w-full border border-gray-300 text-sm text-gray-700">
      <thead>
        <tr className="bg-gray-300">
          <th className="border border-gray-300 px-4 py-2" colSpan="7">{question}</th>
          
        </tr>
        <tr className="bg-gray-300">
          <th className="border border-gray-300 px-4 py-2 text-center" rowSpan="2">Category</th>
          <th colSpan="3" className="border border-gray-300 px-4 py-2">Current Reporting Year (Current Financial Year)</th>
          <th colSpan="3" className="border border-gray-300 px-4 py-2">Previous Reporting Year (Previous Financial Year)</th>
        </tr>
        <tr className="bg-gray-100">
          <th className="border border-gray-300 px-4 py-2">Filed during the year</th>
          <th className="border border-gray-300 px-4 py-2">Pending resolution at the end of year</th>
          <th className="border border-gray-300 px-4 py-2">Remarks</th>
          <th className="border border-gray-300 px-4 py-2">Filed during the year</th>
          <th className="border border-gray-300 px-4 py-2">Pending resolution at the end of year</th>
          <th className="border border-gray-300 px-4 py-2">Remarks</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="text-center">
            <td className="border border-gray-300 px-4 py-2"> {item.category.replace(/_/g, " ").charAt(0).toUpperCase() +  item.category.replace(/_/g, " ").slice(1)}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentFiled}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentPending}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentRemarks}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousFiled}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousPending}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousRemarks}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GrievanceTable;
