// Social thunk
import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "../common/commonSlice";
import {
  resetSocialFormData,
  setSocialForm,
  setSocialRecords,
  setSocialTypeData,
  setSiteData,
  setSiteDataForFilters,
  deleteSocialRecord,
  setSocialTypeDataForFilters,
  setSocialFormData,
  setSocialEmpDemographicsRecords,
  setSocialEmpWellBeingsRecords,
  setSocialHiringTurnoverRecords,
  setSocialPaternityRecords,
  setSocialRetirementBenefitsRecords,
  setSocialSafetyRelatedIncidentsRecords,
  setSocialTrainingRecords,
  setSocialUnionAssociationParticipationRecords,
  setSocialGovernanceDiversityRecords,
  setSocialComplaintsRecords,
  setsocialContratualEmpDetailsRecords,
  setsocialOHSSystemCoverageRecords,
  setsocialRehabilitedRecords,
} from "./socialSlice";
import { toast } from "react-toastify";
import { getOrganizationId } from "../../components/common/parseJwt";
import { BASE_URL } from "../../appconfig";
import {
  transformEmpDemographicsData,
  transformBenefitData,
  transformDataHiringAndTernOver,
  transformGovernance,
  transformComplaintData,
  transformParentalLeaveData,
  transformRetirementBenefitData,
  transformSafetyRelatedData,
  transformTrainingData,
  transformUnionData,
  transformRehabilitationData,
  transformOhsSystemCoverageNonEmployeesData,
  transformContratualEmpDetailsData,
} from "./functions/Functions";
import { setFinancialYearFilter, setReportYearDates } from "../../pages/BRSR/Redux/BRSRSlices";

const removeFalsyValues = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, value]) => value));

export const fetchSocialEmpDemographicInputs = createAsyncThunk(
  "social/fetchSocialEmpDemographicInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/emp-demographics/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        const data=response.data.data
        dispatch(setSocialEmpDemographicsRecords(data));
      const letestYear=Math.max(...data.map(item => parseInt(item.financial_year, 10)));
        dispatch(setReportYearDates({ startDate:letestYear+"-04", endDate: "" }))
       
      }

      dispatch(setIsLoading(false));
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSocialEmpWellBeingInputs = createAsyncThunk(
  "social/fetchSocialEmpWellBeingInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/emp-well-being/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setSocialEmpWellBeingsRecords(response.data.data));
      }
//  console.log(response)
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postSocialEmpDemographicInputs = createAsyncThunk(
  "social/postSocialEmpDemographicInputs",
  async ({ api, formData }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/emp-demographics/${organizationId}`;

    dispatch(setIsLoading(true));

    try {
      const response = await api.post(
        `${apiUrl}`,
        transformEmpDemographicsData(formData, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        // dispatch(setSocialEmpDemographicInputs(response.data.data));
        toast.success("Data saved successfully");
        dispatch(fetchSocialEmpDemographicInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error("Error posting data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialEmpWellBeingInputs = createAsyncThunk(
  "social/postSocialEmpWellBeingInputs",
  async ({ api, formData }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/emp-well-being/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformBenefitData(formData, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        // dispatch(setSocialEmpWellBeingInputs(response.data.data));
        toast.success("Data saved successfully");
        dispatch(fetchSocialEmpWellBeingInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error("Error posting data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSocialComplaintsInputs = createAsyncThunk(
  "social/fetchSocialComplaintsInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/complaints-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        console.log(response)
        dispatch(setSocialComplaintsRecords(response.data.data));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching complaints data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialComplaintsInputs = createAsyncThunk(
  "social/postSocialComplaintsInputs",
  async ({ api, formData, employementType }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/complaints/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformComplaintData(formData, employementType, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Complaints data saved successfully");
        dispatch(fetchSocialComplaintsInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error("Error posting complaints data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchSocialGovernanceDiversityInputs = createAsyncThunk(
  "social/fetchSocialGovernanceDiversityInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/governance-diversity-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setSocialGovernanceDiversityRecords(response.data.data));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching governance diversity data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialGovernanceDiversityInputs = createAsyncThunk(
  "social/postSocialGovernanceDiversityInputs",
  async ({ api, formData, employementType }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/governance-diversity/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformGovernance(formData, "Full time", organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Governance diversity data saved successfully");
        dispatch(fetchSocialGovernanceDiversityInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error("Error posting governance diversity data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSocialHiringTurnoverInputs = createAsyncThunk(
  "social/fetchSocialHiringTurnoverInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/hiring-turnover-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });
         console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setSocialHiringTurnoverRecords(response.data.data));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching hiring turnover data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialHiringTurnoverInputs = createAsyncThunk(
  "social/postSocialHiringTurnoverInputs",
  async ({ api, formData, employementType }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/hiring-turnover/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformDataHiringAndTernOver(formData, employementType,organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Hiring turnover data saved successfully");
        dispatch(fetchSocialHiringTurnoverInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Error posting hiring turnover data"
      );
      console.error("Error posting hiring turnover data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

// Paternity
export const fetchSocialPaternityInputs = createAsyncThunk(
  "social/fetchSocialPaternityInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/paternity-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setSocialPaternityRecords(response.data.data));
      }

      return response.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Error fetching  data"
      );
      console.error("Error fetching paternity data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialPaternityInputs = createAsyncThunk(
  "social/postSocialPaternityInputs",
  async ({ api, formData, employementType }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/paternity/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformParentalLeaveData(formData, employementType, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Paternity data saved successfully");
        dispatch(fetchSocialPaternityInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Error posting  data"
      );
      console.error("Error posting paternity data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

// Retirement Benefits
export const fetchSocialRetirementBenefitsInputs = createAsyncThunk(
  "social/fetchSocialRetirementBenefitsInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/retirement-benefits-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setSocialRetirementBenefitsRecords(response.data.data));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching retirement benefits data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialRetirementBenefitsInputs = createAsyncThunk(
  "social/postSocialRetirementBenefitsInputs",
  async ({ api, formData, employementType }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/retirement-benefits/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformRetirementBenefitData(
          formData,
          employementType,
          organizationId
        )
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Retirement benefits data saved successfully");
        dispatch(fetchSocialRetirementBenefitsInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error("Error posting retirement benefits data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

// Safety Related Incidents
export const fetchSocialSafetyRelatedIncidentsInputs = createAsyncThunk(
  "social/fetchSocialSafetyRelatedIncidentsInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/safety-related-incidents-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setSocialSafetyRelatedIncidentsRecords(response.data.data));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching safety related incidents data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialSafetyRelatedIncidentsInputs = createAsyncThunk(
  "social/postSocialSafetyRelatedIncidentsInputs",
  async ({ api, formData, employementType }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/safety-related-incidents/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformSafetyRelatedData(formData, employementType, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Safety related incidents data saved successfully");
        dispatch(fetchSocialSafetyRelatedIncidentsInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error("Error posting safety related incidents data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

// Training
export const fetchSocialTrainingInputs = createAsyncThunk(
  "social/fetchSocialTrainingInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/training-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setSocialTrainingRecords(response.data.data));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching training data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialTrainingInputs = createAsyncThunk(
  "social/postSocialTrainingInputs",
  async ({ api, formData, employementType }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/training/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformTrainingData(formData, employementType, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Training data saved successfully");
        dispatch(fetchSocialTrainingInputs({ api, params: {} }));
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error("Error posting training data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

// Union Association Participation
export const fetchSocialUnionAssociationParticipationInputs = createAsyncThunk(
  "social/fetchSocialUnionAssociationParticipationInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/union-association-participation-inputs/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(
          setSocialUnionAssociationParticipationRecords(response.data.data)
        );
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error(
        "Error fetching union association participation data:",
        error
      );
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialUnionAssociationParticipationInputs = createAsyncThunk(
  "social/postSocialUnionAssociationParticipationInputs",
  async ({ api, formData }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/union-association-participation/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformUnionData(formData, "fulltime", organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success(
          "Union association participation data saved successfully"
        );
        dispatch(
          fetchSocialUnionAssociationParticipationInputs({ api, params: {} })
        );
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error||"Error posting Data")
      console.error(
        "Error posting union association participation data:",
        error
      );
      throw error;
    }
  }
);
 
// POST routes
export const postSocialContratualEmpDetailsInputs = createAsyncThunk(
  "social/postSocialContratualEmpDetailsInputs",
  async ({ api, formData }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/contratual-emp-details/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformContratualEmpDetailsData(formData, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Contratual employee details data saved successfully");
        dispatch(fetchSocialContratualEmpDetailsInputs({api,params:{}}))
        // Dispatch any additional actions if needed
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error posting Data");
      console.error("Error posting contratual employee details data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialOhsSystemCoverageNonEmployeesInputs = createAsyncThunk(
  "social/postSocialOhsSystemCoverageNonEmployeesInputs",
  async ({ api, formData }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/ohs-system-coverage-non-employees/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformOhsSystemCoverageNonEmployeesData(formData, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("OHS system coverage for non-employees data saved successfully");
        dispatch(fetchSocialOhsSystemCoverageNonEmployeesInputs({api,params:{}}))
        // Dispatch any additional actions if needed
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error posting Data");
      console.error("Error posting OHS system coverage data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialRehabilitationInputs = createAsyncThunk(
  "social/postSocialRehabilitationInputs",
  async ({ api, formData }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/rehabilitation/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.post(
        `${apiUrl}`,
        transformRehabilitationData(formData, organizationId)
      );

      if (response.status === 201 && response.data.success) {
        toast.success("Rehabilitation data saved successfully");
        dispatch(fetchSocialRehabilitationInputs({api,params:{}}))
        // Dispatch any additional actions if needed
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error posting Data");
      console.error("Error posting rehabilitation data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

// GET routes
export const fetchSocialContratualEmpDetailsInputs = createAsyncThunk(
  "social/fetchSocialContratualEmpDetailsInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/contratual-emp-details/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setsocialContratualEmpDetailsRecords(response?.data?.data))
        // Dispatch any additional actions if needed
      }
// console.log(response)
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error fetching Data");
      console.error("Error fetching contratual employee details data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSocialOhsSystemCoverageNonEmployeesInputs = createAsyncThunk(
  "social/fetchSocialOhsSystemCoverageNonEmployeesInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/ohs-system-coverage-non-employees/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setsocialOHSSystemCoverageRecords(response?.data?.data))
        // Dispatch any additional actions if needed
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error fetching Data");
      console.error("Error fetching OHS system coverage data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSocialRehabilitationInputs = createAsyncThunk(
  "social/fetchSocialRehabilitationInputs",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    let apiUrl = `/social/rehabilitation/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setsocialRehabilitedRecords(response?.data?.data))
        // Dispatch any additional actions if needed
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error fetching Data");
      console.error("Error fetching rehabilitation data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

















export const fetchSocialData = createAsyncThunk(
  "social/fetchSocialData",
  async ({ api, params }, { getState, dispatch }) => {
    const organizationId = getOrganizationId();
    const socialRecordType = getState().Social.SocialRecordType;
    let apiUrl = "";

    apiUrl = `/social/${organizationId}`;

    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      //   set all data SocialREcords, pageDetails, totalCount etc.
      if (response.status === 200 && response.data.success) {
        dispatch(
          setSocialRecords(
            response.data?.data.map((i) => {
              return {
                ...i,
                sourceType:
                  i.sourceType === "non-renewable"
                    ? "Non-Renewable"
                    : "Renewable",
              };
            })
          )
        );
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // dispatch(setIsLoading(false));
        // console.log(response)
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      // toast(error.message||"Error getting Social information");
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchSiteData = createAsyncThunk(
  "social/fetchSiteData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organizationId = getOrganizationId();
    try {
      const response = await api.get(`/social/${organizationId}/sites`);
      // /social/:organization_id/sites
      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilters(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      toast.error(error?.response?.data?.error||"Error fetching Data")
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchSocialTypeData = createAsyncThunk(
  "social/fetchSocialTypeData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/social/Social-types`);

      //   set all data SocialREcords, pageDetails, totalCount etc.
      // console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setSocialTypeData(response.data.data));
        dispatch(setSocialTypeDataForFilters(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors

      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postSocialData = createAsyncThunk(
  "social/postSocialData",
  async ({ api }, { getState, dispatch }) => {
    const { id, ...socialForm } = getState().Social.SocialForm;

    const socialRecordType = getState().Social.SocialRecordType;
    const organizationId = getOrganizationId();
    dispatch(resetSocialFormData());

    dispatch(setIsLoading(true));
    // ))
    try {
      const response = await api.post(
        `/social/${organizationId}/data/${socialRecordType}`,
        Object.fromEntries(
          Object.entries(socialForm).filter(([_, value]) => value !== "")
        )
      );

      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        toast.success("Record created successfully", {
          theme: "dark",
        });
        dispatch(fetchSocialData({ api, params: {} }));
      }
    } catch (error) {
      // Handle other API call errors
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record created failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const updateSocialData = createAsyncThunk(
  "social/updateSocialData",
  async ({ api, id }, { getState, dispatch }) => {
    const socialForm = getState().Social.SocialForm;
    const socialRecordType = getState().Social.SocialRecordType;
    const organizationId = getOrganizationId();
    dispatch(resetSocialFormData());

    dispatch(setIsLoading(true));
    try {
      const response = await api.put(
        `/social/${organizationId}/data/${id}`,
        socialForm
      );

      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        toast.success("Record Updated successfully", {
          theme: "dark",
        });
        dispatch(fetchSocialData({ api }));
      }
    } catch (error) {
      // Handle other API call errors
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record created failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deleteSocialData = createAsyncThunk(
  "social/deleteSocialData",
  async ({ api, id }, { getState, dispatch }) => {
    const socialForm = getState().Social.SocialForm;
    const organizationId = getOrganizationId();

    dispatch(setIsLoading(true));
    try {
      // /social/:organization_id/data/:id
      const response = await api.delete(
        `/social/${organizationId}/data/${id}`,
        socialForm
      );

      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        toast.success("Record Deleted successfully", {
          theme: "dark",
        });
        dispatch(deleteSocialRecord(id));
      }
    } catch (error) {
      // Handle other API call errors
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record deleting failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const uploadSocialEvidence = createAsyncThunk(
  "social/uploadSocialEvidence",
  async ({ api, files }, { getState, dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const organizationId = getOrganizationId();
      const accessToken = localStorage.getItem("accessToken");
      // console.log(files)
      const socialRecordType = getState().Social.SocialRecordType;
      const response = await api.post(
        `${BASE_URL}/social/${organizationId}/data/${socialRecordType}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setIsLoading(false));

        dispatch(setSocialFormData({ evidence: response?.data?.path }));
        toast.success("Evidence Uploaded successfully");
        return response.data;
      } else {
        dispatch(setIsLoading(false));

        toast("Evidence Upload Failed");
        throw new Error("Failed to upload files");
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      toast(error?.response?.data?.error || "Evidence Upload Failed");

      console.log("Error:", error);
      throw error;
    }
  }
);
