import EmployeeUnionTable from "../BRSRContent/Tables/EmployeeUnionTable";
import EqualOpportunity from "../BRSRContent/Tables/EqualOpportunity";
import GrievanceMechanismTable from "../BRSRContent/Tables/GrievanceMechanismTable";
import GrievanceTable from "../BRSRContent/Tables/GrievanceTableCompaints";
import HealthAndSafetyAssessmentTable from "../BRSRContent/Tables/HealthAndSafetyAssessmentTable";
import HealthSafetyTable from "../BRSRContent/Tables/HealthAndSefty";
import HealthSafetySkillTable from "../BRSRContent/Tables/HealthSafetySkillTable";
import PerformanceReviewTable from "../BRSRContent/Tables/PerformanceReviewTable";
import QuestionAnswerBox from "../BRSRContent/Tables/QuestionAnswerBox";
import RetirementBenifitDiscloser from "../BRSRContent/Tables/RetirementBenifitDiscloser";
import SafetyTable from "../BRSRContent/Tables/SafetyInsidence";
import SafetyLifeLineSystem from "../BRSRContent/Tables/SafetyLifeLineSystem";
import SafetyMeasuresTable from "../BRSRContent/Tables/SafetyMeasuresTable";
import WellBeingTable from "../BRSRContent/Tables/WellBeingTable";
import Tooltip from "./Tooltip";
import { ReactComponent as IIcon } from "../../../app/assets/InfoIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../app/assets/ExpandIcon.svg";
import CompensatorySupportTable from "../BRSRContent/Tables/CompensatorySupportTable";

export const DisclosureSubRow = ({financialYear, sub, expanded, toggleExpand,openCommentModal, handleDiscloserType,handleStatus,id,subDisclosure}) => {
  
   
    
    function handleSubmit(answer){
        console.log({answer})
    }
    const tables={
      1: <WellBeingTable 
       question={sub?.details}/>,
      2: <RetirementBenifitDiscloser question={sub?.details}/>,
      3:<QuestionAnswerBox onSubmit={handleSubmit} question={sub?.details} />,
      4:<EqualOpportunity question={sub?.details}/>,
      5:<GrievanceMechanismTable question={sub?.details}/>,
      6:<EmployeeUnionTable question={sub?.details}/>,
      7:<HealthSafetySkillTable question={sub?.details}/>,
      8:<PerformanceReviewTable question={sub?.details}/>,
      9:<HealthSafetyTable question={sub?.details}/>,
      10:<SafetyTable question={sub?.details}/>,
      11:<SafetyMeasuresTable question={sub?.details}/>,
      12:<GrievanceTable   question={sub?.details}/>,
      13:<HealthAndSafetyAssessmentTable  question={sub?.details}/>,
      14:<SafetyLifeLineSystem  question={sub?.details}/>,
      15:<CompensatorySupportTable question={sub?.details}/>
    }  
      

  const isExpanded = expanded[sub.id];
  return (
    <>
      <tr className="border-b transition-all duration-300 text-sm bg-gray-50">
        <td
          onClick={() => toggleExpand(sub.id)}
          className="p-4 font-semibold flex content-center items-center cursor-pointer"
         
        >
             <span
              // onClick={() => toggleExpand(sub.id)}
              className={`flex justify-center items-center text-center mr-2 `}
              style={{
                transform: `rotate(${isExpanded ? 90 : 0}deg)`,
                transition: "transform 0.2s ease-in-out",
              }}
            >
              <ArrowIcon className={`w-3 h-3 font-bold text-center` } />
            </span>
          {/* {isExpanded ? <CollapseIcon /> : <ExpandIcon />} */}
          {sub.id}
        </td>
        <td  onClick={() => toggleExpand(sub.id)} className="px-1 py-4 max-w-72 min-w-60 overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer">
          {sub.title}
        </td>
        <td className="p-4 w-fit">
            <div className="flex items-center justify-center">
              <select value={sub.disclosure_type} onChange={(e)=>handleDiscloserType(e,sub.id,false,id,subDisclosure)} className="border-none rounded-md p-2 w-36">

                <option value={""}>Select option</option>
                <option value={"Relevant"}>Relevant</option>
                <option value={"Irrelevant"}>Irrelevant</option>
              </select>
            </div>
          </td>
          <td className="p-4">
            <div className="flex items-center justify-center">
              <select  value={sub?.status} onChange={(e)=>handleStatus(e,sub.id,false,id,subDisclosure)} className="border-none rounded-md p-2 w-36">
                <option value={""}>Select option</option>
                <option value={"Completed"}>Completed</option>
                <option value={"Pending"}>Pending</option>
              </select>
            </div>
          </td>
        <td className="p-4 ">
          <button
            onClick={(e) => {
              e.stopPropagation();
              openCommentModal(id,sub.id,false,subDisclosure);
            }}
            className="bg-green-500 text-white px-2 py-1 rounded"
          >
            Comment
          </button>
        </td>
        <td className="p-1">
          <Tooltip message={sub?.guidance}>
  {/* <button className="p-2 bg-blue-500 text-white rounded-md">Hover me</button> */}
          <IIcon className="w-4 h-4"/>
</Tooltip>
          </td>
          
      </tr>
      {isExpanded && (
        <tr className="border-b bg-gray-50">
          <td colSpan="6" className="p-4">
            <span>
              {tables[sub.table]}
            </span>
          </td>
        </tr>
      )}
    </>
  );
};



const ExpandIcon = () => (
    <svg
      className="w-4 h-4 mr-2"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g>
        {" "}
        <path
          d="M10 7L15 12L10 17"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
  
  const CollapseIcon = () => (
    <svg
      className="w-4 h-4 mr-2"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M7 10L12 15L17 10"
          stroke="#000000"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );