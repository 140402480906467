import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
Font.register({
  family: "Lato Bold",
  fonts: [
    { src: "https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30wWw.ttf" }, // Regular font
    {
      src: "https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh7USSwaP.ttf",
      fontWeight: "heavy",
    }, // Bold font
  ],
});
// Define styles
const styles = StyleSheet.create({
  body: {
    padding: "10px 55px 10px 55px",
    fontWeight:"thin"
    // fontFamily: 'Arial, sans-serif',
  },
  heading: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
    fontSize: 14,
    // fontFamily: 'Lato Bold',
    // fontWeight: "bold",
  },
  subheading: {
    marginTop: 10,
    marginBottom: 5,
    fontSize: 8.5,
    lineHeight: 1.5,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000",
    marginBottom: 6,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#0000",
    padding: 2,
  },
  tableCell: {
    margin: 3,
    fontSize: 8,
   
  },
  bold: {
   
    fontSize: 8,
  },
  tableHeader: {
   
    fontSize: 8,
  },
  textBold: {
    margin: 2, // Handles spacing between words
    fontSize:8,
    textAlign: "justify",
    color: "#000000",
    fontWeight: "heavy",
  },
  note: {
    // marginLeft: 20,
    // marginTop: 10,
    // marginBottom: 10,
    fontSize: 8,
  },
});

export const TempleteDocument = ({ data }) => (
  <Document>
    <Page style={styles.body} size="A4">
      <Text style={styles.heading}>
        PRINCIPLE 6: Businesses should respect and make efforts to protect and
        restore the environment
      </Text>

      <Text
        style={{
          justifyContent: "center",
          display: "flex",
          textAlign: "center",
          fontSize: 12,
          fontWeight: "bold",
          // marginVertical:"20px"
        }}
      >
        Essential Indicators
      </Text>
      <Text style={styles.subheading}>
        1. Details of total energy consumption (in Joules or multiples) and
        energy intensity, in the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell,styles.textBold
            ]}>
              Parameter
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year){" "}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total electricity consumption (A)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_electricity_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_electricity_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total fuel consumption (B)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_fuel_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_fuel_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Energy consumption through other sources (C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total energy consumption (A+B+C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_energy_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_energy_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Energy intensity per rupee of turnover{" "}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data?.energy_intensity_perunit_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data?.energy_intensity_perunit_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Energy intensity (optional) – the relevant metric may be selected
              by the entity
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.energy_intensity_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.energy_intensity_2023}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>

      {/* <Text style={styles.subheading}>
        2. Does the entity have any sites / facilities identified as designated
        consumers (DCs) under the Performance, Achieve and Trade (PAT) Scheme of
        the Government of India? (Y/N) If yes, disclose whether targets set
        under the PAT scheme have been achieved. In case targets have not been
        achieved, provide the remedial action taken, if any.
      </Text> */}
      <Text style={styles.subheading}>
        2. Provide details of the following disclosures related to water, in the
        following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, { fontWeight: "extrabold" }]}>
              Parameter
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year){" "}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water withdrawal by source (in kilolitres)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              {/* Water withdrawal by source (in kilolitres) */}
              {""}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Surface water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Groundwater</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(iii) Third party water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              (iv) Seawater / desalinated water
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(v) Others</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total volume of water withdrawal (in kilolitres) (i + ii + iii +
              iv + v)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total volume of water consumption (in kilolitres)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water intensity per rupee of turnover (Water consumed / turnover)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water intensity (optional) – the relevant metric may be selected
              by the entity
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
      </View>
      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>

      {/* <Text style={styles.subheading}>
        4. Has the entity implemented a mechanism for Zero Liquid Discharge? If
        yes, provide details of its coverage and implementation.
      </Text>
      <Text style={styles.subheading}>
        5. Please provide details of air emissions (other than GHG emissions) by
        the entity, in the following format::
      </Text> */}
      {/* <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Please specify unit
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year){" "}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>NOx</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.nox_2023}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.sox_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>SOx</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.sox_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.sox_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Particulate matter (PM)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Persistent organic pollutants (POP)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Volatile organic compounds (VOC)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Hazardous air pollutants (HAP)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Others – please specify
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
      </View> */}
     
      <Text style={styles.subheading}>
        3. Provide details of greenhouse gas emissions (Scope 1 and Scope 2
        emissions) & its intensity, in the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>unit</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year){" "}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O,
              HFCs, PFCs, SF6, NF3, if available)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              Metric tonnes of CO2 equivalent{" "}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope1_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope1_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O,
              HFCs, PFCs, SF 6, NF3, if available)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              Metric tonnes of CO2 equivalent{" "}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope2_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope2_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total Scope 1 and Scope 2 emissions per rupee of turnover
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope_unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_scope1_scope2_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_scope1_scope2_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total Scope 1 and Scope 2 emission intensity (optional) – the
              relevant metric may be selected by the entity
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data?.scope1_scope2_intensity_unit}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.scope1_scope2_intensity_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.scope1_scope2_intensity_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Volatile organic compounds (VOC)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
      </View>
       <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>
      {/* <Text style={styles.subheading}>
        7. Does the entity have any project related to reducing Green House Gas
        emission? If Yes, then provide details.
      </Text> */}
      <Text style={styles.subheading}>
        4. Provide details related to waste management by the entity, in the
        following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year)
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
              Total waste generated (in metric tonnes)
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Plastic waste (A)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.plastic_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.plastic_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>E-waste (B)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Bio-medical waste (C)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Construction and demolition waste (D)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Battery waste (E)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Radioactive waste (F)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Other Hazardous waste. Please specify, if any. (G)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.hazardous_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.hazardous_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Other Non-hazardous waste generated (H). Please specify, if any.
              (Break-up by composition i.e. by materials relevant to the sector)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total (A+B + C + D + E + F + G + H)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
              For each category of waste generated, total waste recovered
              through recycling, re-using or other recovery operations (in
              metric tonnes)
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>Category of waste</Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Recycled</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Re-used</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              (iii) Other recovery operations
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
              For each category of waste generated, total waste disposed by
              nature of disposal method (in metric tonnes)
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>Category of waste</Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Incineration</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Landfilling</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              (iii) Other recovery operations
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.note}>
        Note: Indicate if any independent assessment/ evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.{" "}
      </Text>
 {/*
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>S. No.</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Location of operations/offices
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Type of operations
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Whether the entity has obtained necessary approvals/ clearances
              from the relevant authorities; and reasons thereof and corrective
              action taken, if any.
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>1</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>2</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "40%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "15%" }]}>
              <Text style={[styles.tableCell, styles.bold]}>S. No.</Text>
            </View> 
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Name and brief details of project
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              EIA Notification No.
            </Text>
          </View>

          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Date</Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Whether conducted by independent external agency (Yes / No)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Results communicated in public
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Relevant Web link domain
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "16%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>S. No.</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Specify the law / regulation / guidelines which was not complied
              with
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Provide details the of non compliance
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Any fined/penalties / action taken by regulatory agencies such as
              pollution control boards or by courts
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Corrective taken, if any action
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>1</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>2</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}> </Text>
          </View>
        </View>
      </View>
       */}
       </Page>
       <Page style={styles.body} size="A4">
      <Text
        style={{
          justifyContent: "center",
          display: "flex",
          textAlign: "center",
          fontSize: 12,
          fontWeight: "bold",
          marginVertical: "20px",
        }}
      >
        Leadership Indicators
      </Text>
      <Text style={styles.subheading}>
        1.Provide break-up of the total energy consumed (in Joules or multiples)
        from renewable and non-renewable sources, in the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.textBold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year)
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>From renewable sources</Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total electricity consumption (A)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_electricity_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_electricity_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total fuel consumption (B)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_fuel_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_fuel_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Energy consumption through other sources (C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total energy consumed from renewable sources (A+B+C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_energy_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_energy_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>From non renewable sources</Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total electricity consumption (D)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_electricity_2024"]}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_electricity_2023"]}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total fuel consumption (E)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_fuel_2024"]}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_fuel_2023"]}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Energy consumption through other sources (F)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total energy consumed from non-renewable sources (D+E+F)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_energy_2024"]}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_energy_2024"]}
            </Text>
          </View>
        </View>
      </View>

      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>
      <Text style={styles.subheading}>
        2. Provide the following details related to water discharged:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year)
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water discharge by destination and level of treatment (in
              kilolitres)
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Surface water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Groundwater</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(iii) Third party water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              (iv) Seawater / desalinated water
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(v) Others</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total water discharged (in kilolitres)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
      </View>

      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>
      </Page>
      <Page style={styles.body} size="A4">
      <Text style={styles.subheading}>
        3. Water withdrawal, consumption, and discharge in areas of water stress
        (in kilolitres):
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year)
            </Text>
          </View>
        </View>

        {/* Water withdrawal by source */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water withdrawal by source (in kilolitres)
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Surface water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Groundwater</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(iii) Third-party water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              (iv) Seawater / desalinated water
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(v) Others</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total volume of water withdrawal (in kilolitres)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_withdrawal_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_withdrawal_2023}</Text>
          </View>
        </View>

        {/* Water consumption */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total volume of water consumption (in kilolitres)
            </Text>
          </View>
        </View>

        {/* <View style={styles.tableRow}>
    <View style={[styles.tableCol, { width: "50%" }]}>
      <Text style={styles.tableCell}>{data.total_consumption_2024}</Text>
    </View>
    <View style={[styles.tableCol, { width: "25%" }]}>
      <Text style={styles.tableCell}>{data.total_consumption_2023}</Text>
    </View>
  </View> */}

        {/* Water intensity */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Water intensity per rupee of turnover (Water consumed / turnover)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.water_intensity_turnover_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.water_intensity_turnover_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Water intensity (optional) – relevant metric selected by the
              entity
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.water_intensity_metric_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.water_intensity_metric_2023}
            </Text>
          </View>
        </View>

        {/* Water discharge by destination and treatment */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water discharge by destination and level of treatment (in
              kilolitres)
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Into Surface water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.surface_water_discharge_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.surface_water_discharge_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_surface_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_surface_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.treated_surface_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.treated_surface_2023}</Text>
          </View>
        </View>

        {/* Groundwater Discharge */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Into Groundwater</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.groundwater_discharge_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.groundwater_discharge_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_groundwater_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_groundwater_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.treated_groundwater_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.treated_groundwater_2023}
            </Text>
          </View>
        </View>

        {/* Seawater Discharge */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(iii) Into Seawater</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_discharge_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_discharge_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_seawater_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_seawater_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.treated_seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.treated_seawater_2023}</Text>
          </View>
        </View>

        {/* Third-Party Discharge */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(iv) Sent to third-parties</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.third_party_discharge_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.third_party_discharge_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_third_party_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.no_treatment_third_party_2023}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.treated_third_party_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.treated_third_party_2023}
            </Text>
          </View>
        </View>

        {/* Other Discharges */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(v) Others</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_discharge_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_discharge_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.no_treatment_other_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.no_treatment_other_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              - With treatment – please specify level of treatment
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.treated_other_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.treated_other_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total water discharged (in kilolitres)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_discharge_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_discharge_2023}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.note}>
        Note: Indicate if any independent assessment/ evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>

      <Text style={styles.subheading}>
        4. Please provide details of total Scope 3 emissions & its intensity, in
        the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>unit</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2024 (Current Financial Year)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              FY 2023 (Previous Financial Year){" "}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total Scope 3 emissions (Break-up of the GHG into CO2, CH4, N2O,
              HFCs, PFCs, SF6, NF3, if available)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              Metric tonnes of CO2 equivalent{" "}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope1_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope1_2023}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total Scope 3 emissions per rupee of turnover
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope_unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_scope1_scope2_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_scope1_scope2_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total Scope 3 emission intensity (optional) – the relevant metric
              may be selected by the entity
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data?.scope1_scope2_intensity_unit}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.scope1_scope2_intensity_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.scope1_scope2_intensity_2023}
            </Text>
          </View>
        </View>
      </View>
      <Text style={styles.note}>
        Note: Indicate if any independent assessment/ evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>
    </Page>
  </Document>
);
