import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationId } from "../../../../components/common/parseJwt";
import {
  setWaterData,
  setdataForCurrentYearChange,
  setFinancialYear,
  setSiteOptions,
  setwaterTypeOptions,
 
} from "./analyzeSlice";
import { setIsLoading } from "../../../../features/common/commonSlice";
import { toast } from "react-toastify";

export const fetchWaterDataThunk = createAsyncThunk(
  "water/fetchWaterDataThunk",
  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/water/data/${organization_id}`;
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      const response = await api.get(`${apiUrl}`);
      const data = response?.data?.data.map((item) => {
        return {
          ...item,
         site: item?.site,
          year: Number(item.year),
          quarter: Number(item.quarter),
          usage_in_kwh:0,
          withdrawal_quantity:Number(item.water_withdrawal_quantity),
          water_type:item.water_type,
          water_source:item.water_source,
          water_quality:item.discharge_quality,
          discharge_quantity:Number(item.water_discharge_quantity),
          total_co2e_kg: 0
        };
      });
      dispatch(setIsLoading(false));

      const siteOptions = Array.from(new Set(data?.map((entry) => entry.site)));
      const waterOptions = Array.from(
        new Set(data?.map((entry) => entry.water_type))
      );
     
      const financialYearOptions = Array.from(
        new Set(data?.map((entry) => entry.year))
      );
      console.log({data})
      dispatch(setwaterTypeOptions(waterOptions));
      dispatch(setdataForCurrentYearChange(data));
      dispatch(setWaterData(data));
      dispatch(setSiteOptions(siteOptions));
      dispatch(setFinancialYear(financialYearOptions.sort((a, b) => b - a)));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(
        error?.response?.data?.message || "Error getting information"
      );
      return error?.response?.data;
    }
  }
);
