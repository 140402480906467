
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RemoveIcon } from "../../app/assets/DeleteIcon.svg";
import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";

const FormGroup = ({
  formValue,
  waterDischargeQuality,
  waterDischargeDestinations,
  handleFormChange,
  unitOptions,
  siteData,
  index,
  handleRemove,
  handleCopy,
}) => {
  const { WaterAndEffluentsWithdrawalForm } = useSelector(
    (state) => state.WaterAndEffluents
  );
  const { withdrawal_period_start, withdrawal_period_end } =
    WaterAndEffluentsWithdrawalForm;

  return (
    <div className="relative grid grid-cols-4 gap-4 px-8 mb-4 mx-4 justify-around border rounded-md p-4">
      <button
        type="button"
        className="absolute right-2 top-5 transform -translate-y-1/2 text-red-500"
        onClick={() => handleRemove(index)}
      >
        <RemoveIcon className="fill-red-500" />
      </button>
      <button
        type="button"
        className="absolute right-10 top-5 transform -translate-y-1/2 text-green-500"
        onClick={() => handleCopy(index, formValue)}
      >
        <CopyIcon width={16} height={22} className="fill-green-500" />
      </button>

      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`discharge_period_start_${index}`}
          className="text-xs py-1.5"
        >
          Period Start<span className="text-red-500">*</span>
        </label>
        <input
          max={withdrawal_period_end}
          min={withdrawal_period_start}
          type="date"
          name="discharge_period_start"
          id={`discharge_period_start_${index}`}
          value={formValue.discharge_period_start || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        />
      </div>

      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`discharge_period_end_${index}`}
          className="text-xs py-1.5"
        >
          Period End<span className="text-red-500">*</span>
        </label>
        <input
          max={withdrawal_period_end}
          type="date"
          name="discharge_period_end"
          id={`discharge_period_end_${index}`}
          value={formValue.discharge_period_end || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          min={formValue.discharge_period_start || ""}
          required
        />
      </div>

      <div className="col-span-1 flex flex-col">
        <label htmlFor={`discharge_site_id_${index}`} className="text-xs py-1.5">
          Facility<span className="text-red-500">*</span>
        </label>
        <select
          name="discharge_site_id"
          id={`discharge_site_id_${index}`}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
          value={formValue.discharge_site_id || ""}
        >
          <option value="" disabled>
            Choose the site
          </option>
          {siteData?.map((site) => (
            <option key={site.id} value={site.id}>
              {site.site}
            </option>
          ))}
        </select>
      </div>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`discharge_destination_${index}`}
          className="text-xs py-1.5"
        >
          Discharge Destination<span className="text-red-500">*</span>
        </label>
        <select
          name="discharge_destination_id"
          id={`discharge_destination_${index}`}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
          value={formValue.discharge_destination_id || ""}
        >
          <option value="" disabled>
            Choose the discharge destination
          </option>
          {waterDischargeDestinations?.map((destination) => (
            <option key={destination.id} value={destination.id}>
              {destination.discharge_destination}
            </option>
          ))}
        </select>
      </div>

      <div className="col-span-1 flex flex-col">
        <label htmlFor={`discharge_quality_${index}`} className="text-xs py-1.5">
          Discharge Quality<span className="text-red-500">*</span>
        </label>
        <select
          name="discharge_quality_id"
          id={`discharge_quality_${index}`}
          value={formValue.discharge_quality_id || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        >
          <option value="">Choose the discharge quality</option>
          {waterDischargeQuality?.map((quality) => (
            <option key={quality.id} value={quality.id}>
              {quality.discharge_quality}
            </option>
          ))}
        </select>
      </div>

      <div className="col-span-1 flex flex-col">
        <label htmlFor={`discharge_quantity_${index}`} className="text-xs py-1.5">
          Discharge Quantity
        </label>
        <input
          type="number"
          name="discharge_quantity"
          id={`discharge_quantity_${index}`}
          value={formValue.discharge_quantity || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          placeholder="Type the quantity"
          min="0"
        />
      </div>

      <div className="col-span-1 flex flex-col">
        <label htmlFor={`discharge_unit_id_${index}`} className="text-xs py-1.5">
          Unit
        </label>
        <select
          name="discharge_unit_id"
          id={`discharge_unit_id_${index}`}
          value={formValue.discharge_unit_id || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        >
          <option value="" disabled>
            Choose the unit
          </option>
          {unitOptions?.map((unit) => (
            <option key={unit.id} value={unit.id}>
              {unit.unit}
            </option>
          ))}
        </select>
      </div>
      <div className=" flex flex-col">
        <label htmlFor={`discharge_evidence_${index}`} className="text-xs py-1.5 flex flex-col">
          <span>Evidence <span className="text-red-500">*</span></span> 
          {formValue.evidence ? (
            <p className="text-green-600 mt-1 underline cursor-pointer">
              Evidence Uploaded
            </p>
          ) : (
           ""
          )}
        </label>
        <input
          required={!formValue.evidence}
          type="file"
          name="discharge_evidence"
          id={`discharge_evidence_${index}`}
          multiple
          accept=".jpg, .jpeg, .png, .pdf, .zip"
          onChange={(e) => handleFormChange(e, index)}
          className={`appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none ${formValue.discharge_evidence ? 'hidden' : ''}`}
        />
      </div>
    </div>
  );
};

export default FormGroup;

