import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../../customHooks/useApi';
import { fetchSocialPaternityInputs } from '../../../../features/social/socialThunk';
import { getLatestYearObjects, processParentalLeaveData } from '../../function/DataProcessFunction';

const EqualOpportunity = ({question}) => {
  const { reportYear} =
  useSelector((state) => state.BRSR.FinancialYearFilter);
  const {socialPaternityRecords}=useSelector(state=>state.social)
  const returnSocialPaternityRecords = getLatestYearObjects(
    socialPaternityRecords,reportYear
  );
  
  const dispatch=useDispatch()
  const api=useApi()
  const data  = processParentalLeaveData(returnSocialPaternityRecords);

      useEffect(()=>{
        dispatch(fetchSocialPaternityInputs({api,params:{}}))
      },[])
  return (
    <table className="min-w-full border border-gray-300">
      <thead className="bg-gray-100 text-gray-700">
        <tr className="bg-gray-300">
          <th colSpan={6} className="border border-gray-300 px-4 py-2 text-start font-semibold text-base">
          {question}          </th>
        </tr>
        <tr className="text-sm text-gray-700">
          <th rowSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            Gender
          </th>
          <th colSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            Permanent Employees
          </th>
          <th colSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            Permanent Workers
          </th>
          {/* <th rowSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            MATERIALITY
          </th> */}
        </tr>
        <tr className="text-sm text-gray-700">
          <th className="border border-gray-300 px-4 py-2">Return to Work Rate</th>
          <th className="border border-gray-300 px-4 py-2">Retention Rate</th>
          <th className="border border-gray-300 px-4 py-2">Return to Work Rate</th>
          <th className="border border-gray-300 px-4 py-2">Retention Rate</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="text-center text-sm text-gray-700">
            <td className="border border-gray-300 px-4 py-2">{item.gender}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentEmployee.returnToWorkRate}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentEmployee.retentionRate}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentWorker.returnToWorkRate}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentWorker.retentionRate}</td>
            {/* <td className="border border-gray-300 px-4 py-2">{item.materiality}</td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EqualOpportunity;
