import { jwtDecode } from "jwt-decode";

// src/config/routePermissions.js
export const routePermissions = {
  "/energy/fuel": { module: 1, submodule: 1, type: "data_entry" },
  "/energy/electricity": { module: 2, submodule: 2, type: "data_entry" },
  "/scope3/category-1": { module: 3, submodule: 3, type: "data_entry" },
  "/scope3/category-2": { module: 3, submodule: 4, type: "data_entry" },
  "/scope3/category-4": { module: 3, submodule: 5, type: "data_entry" },
  "/scope3/category-9": { module: 3, submodule: 6, type: "data_entry" },
  "/scope3/category-7": { module: 3, submodule: 8, type: "data_entry" },
  "/scope3/category-6": { module: 3, submodule: 7, type: "data_entry" },
  "/scope3/category-5": { module: 8, submodule: 21, type: "data_entry" },
  "/scope3/End-of-life treatment of sold products": {
    module: 3,
    submodule: 18,
    type: "data_entry",
  },
  "/scope3/social": {
    module: 10,
    submodule: 24,
    type: "data_entry",
  },
  "/Water and Effluents": {
    module: 11,
    submodule: 25,
    type: "data_entry",
  },
  "/scope3/governance": {
    module: 12,
    submodule: 26,
    type: "data_entry",
  },
  "/scope3/Analyze/social": {
    module: 10,
    submodule: 24,
    type: "visualization",
  },
  "/scope3/Analyze/Water and Effluents": {
    module: 11,
    submodule: 25,
    type: "visualization",
  },
  "/scope3/Analyze/governance": {
    module: 12,
    submodule: 26,
    type: "visualization",
  },
  "/scope3/Downstream leased assets": {
    module: 3,
    submodule: 19,
    type: "data_entry",
  },
  "/energy/fuelAnalyze/scope1": {
    module: 1,
    submodule: 1,
    type: "visualization",
  },
  "/energy/fuelAnalyze/scope2": {
    module: 2,
    submodule: 2,
    type: "visualization",
  },

  "/scope3/Analyze/category-1": {
    module: 3,
    submodule: 3,
    type: "visualization",
  }, //Purchased Goods and Services

  "/scope3/Analyze/category-2": {
    module: 3,
    submodule: 4,
    type: "visualization",
  }, //Capital Goods

  "/scope3/Analyze/category-4": {
    module: 3,
    submodule: 5,
    type: "visualization",
  }, //Upstream Transportation and Distribution

  "/scope3/Analyze/category-9": {
    module: 3,
    submodule: 6,
    type: "visualization",
  }, //Downstream Transportation and Distribution

  "/scope3/Analyze/category-7": {
    module: 3,
    submodule: 8,
    type: "visualization",
  }, //Employee Commute

  "/scope3/Analyze/category-6": {
    module: 3,
    submodule: 7,
    type: "visualization",
  }, //Business Travel
  "/scope3/Analyze/Waste from Operations": {
    module: 3,
    submodule: 22,
    type: "visualization",
  }, //category-5
  "/scope3/Analyze/waste-material": {
    module: 8,
    submodule: 23,
    type: "visualization",
  }, //category-5
  "/scope3/Analyze/Upstream leased assets": {
    module: 3,
    submodule: 17,
    type: "visualization",
  },
  "/scope3/Analyze/End-of-life treatment of sold products": {
    module: 3,
    submodule: 18,
    type: "visualization",
  },
  "/scope3/Analyze/Downstream leased assets": {
    module: 3,
    submodule: 19,
    type: "visualization",
  },
  "/scope3/Analyze/Franchises": {
    module: 3,
    submodule: 20,
    type: "visualization",
  },
  "/scope3/Analyze/Fuel and Energy related activities": {
    module: 3,
    submodule: 16,
    type: "visualization",
  },
  "/settings": { type: "admin", module: -1, submodule: -1 },
  "/energy/emissions": { type: "visualization", module: -1, submodule: -1 },
  "/general-info": { type: "data_entry", module: -1, submodule: -1 },
  "/custom-report": { type: "reporting", module: 4, submodule: 9 },
  "/green-report": { type: "reporting", module: 4, submodule: 10 },
  "/GRI": { type: "reporting", module: 5, submodule: 11 },
  "/BRSR": { type: "reporting", module: 5, submodule: 12 },
  "/CDP": { type: "reporting", module: 5, submodule: 13 },
  "/reduce": { type: "management", module: 7, submodule: 15 },
  "/offset": { type: "management", module: 6, submodule: 14 },
};
export const getRouteByModuleAndSubmodule = (accessToken) => {
  // console.log(accessToken)
  if (!accessToken) {
    return "/energy/emissions";
  }
  // console.log(accessToken)
  const decodedToken = jwtDecode(accessToken);
  const permissions = decodedToken.member.access;
  if (permissions) {
  }
  const { modules, submodules, is_enabled } = permissions?.data_entry;
  if (
    (!is_enabled && permissions?.visualization?.is_enabled) ||
    (is_enabled && permissions?.visualization?.is_enabled)
  ) {
    return "/energy/emissions";
  } else if (permissions?.admin?.is_enabled) {
    return "/energy/emissions";
  }
  // console.log({modules, submodules,is_enabled,routePermissions})
  // Handle the case where both module and submodule are -1
  if (modules.includes(-1) && submodules.includes(-1)) {
    return Object.entries(routePermissions)
      .filter(([_, value]) => value.type === "data_entry")
      .map(([route]) => route)[0];
  }

  // Handle the case where module is -1 (ignore module filter)
  if (modules.includes(-1)) {
    return Object.entries(routePermissions)
      .filter(
        ([_, value]) =>
          submodules.includes(Number(value.submodule)) &&
          value.type === "data_entry"
      )
      .map(([route]) => route)[0];
  }

  // Handle the case where submodule is -1 (ignore submodule filter)
  if (submodules.includes(-1)) {
    return Object.entries(routePermissions)
      .filter(
        ([_, value]) =>
          modules.includes(Number(value.module)) && value.type === "data_entry"
      )
      .map(([route]) => route)[0];
  }

  // Handle the case where neither is -1
  return Object.entries(routePermissions)
    .filter(
      ([_, value]) =>
        modules.includes(Number(value.module)) &&
        submodules.includes(Number(value.submodule)) &&
        value.type === "data_entry"
    )
    .map(([route]) => route)[0];
};
