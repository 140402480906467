import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";

import { getOrganizationId } from "../../components/common/parseJwt.js";
import { removeFalsyValues } from "../../components/common/removeFaslyValues.js";
import { removeDuplicates } from "../../pages/inputScope3/function/removeDuplicates.js";
import { setIsLoading } from "../common/commonSlice.js";
import { setDischargeDestinationData, setSiteData, setSiteDataForFilter, setStoreDischarged, setUnitData,  setWaterAndEffluentsRecordsDischarged, setWaterAndEffluentsWithdrawaldInputRecords, setWaterDischargeQuality, setWaterSourceData, setWaterType } from "./WaterandEffluentsSlice.js";
export const fetchWaterandEffluentsInputData = createAsyncThunk(
  "WaterAndEffluents/fetchWaterandEffluentsInputData",
  async ({ api, params }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();

    let apiUrl = "water/inputdata/" + organization_id;

    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });
      if (response.status === 200 && response.data.success) {
        let WithdrawalData = [];
        let DischargeData = [];
        response?.data?.data.map((item) => {
          WithdrawalData.push({
            id: item.id,
            ...item.water_withdrawal_input_data,
          });
          DischargeData.push(...item.water_discharged_input_data);
        });
    
        
        dispatch(setWaterAndEffluentsRecordsDischarged(DischargeData));
        dispatch(setWaterAndEffluentsWithdrawaldInputRecords(WithdrawalData))
        // dispatch(setStoreDischarged(DischargeData))
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSiteData = createAsyncThunk(
  "WaterAndEffluents/fetchSiteData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    try {
      const response = await api.get(
        `water/${organization_id}/sites`
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "WaterAndEffluents/fetchUnitData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`water/units`);

      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchWaterSourceMaster = createAsyncThunk(
  "WaterAndEffluents/fetchWaterSourceMaster",
  async ({ api }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`water/water-source`);
      
      if (response.status === 200 && response.data.success) {
        dispatch(setWaterSourceData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchWaterTypeMaster = createAsyncThunk(
  "WaterAndEffluents/fetchWaterTypeMaster",
  async ({ api }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`water/water-type`);
      
      if (response.status === 200 && response.data.success) {
        dispatch(setWaterType(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchDischargeQualityMaster = createAsyncThunk(
  "WaterAndEffluents/fetchDischargeQualityMaster",
  async ({ api }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`water/discharge-quality`);

      if (response.status === 200 && response.data.success) {
        dispatch(setWaterDischargeQuality(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchDischargeDestinationMaster = createAsyncThunk(
  "WaterAndEffluents/fetchDischargeDestinationMaster",
  async ({ api }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`water/discharge-destination`);

      if (response.status === 200 && response.data.success) {
        dispatch(setDischargeDestinationData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postWaterandEffluentsWithdrawalData = createAsyncThunk(
  "WaterAndEffluents/postWaterandEffluentsWithdrawalata",
  async ({ api, i, formValues }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));

    const { WaterandEffluentsWithdrawalForm } = getState().WaterandEffluents;

    const organization_id = getOrganizationId();
    try {
      const response = await api.post(
        `water/data/${organization_id}`,
        {
          withdrawal: Object.keys(WaterandEffluentsWithdrawalForm).reduce(
            (object, key) => {
              if (key !== "id") {
                object[key] = WaterandEffluentsWithdrawalForm[key];
              }
              return object;
            },
            {}
          ),
          discharge: formValues?.map((obj) => {
            if (obj.id) {
              const { id, ...rest } = obj;
              return rest;
            }
            return obj;
          }),
        },
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
      // 
      if (response.status === 200 || response.status === 201) {
        // dispatch(resetWaterandEffluentsForm());
        dispatch(fetchWaterandEffluentsInputData({ api, params: {} }));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postWaterandEffluentsDischargeData = createAsyncThunk(
  "WaterAndEffluents/postWaterandEffluentsDischargeData",
  async ({ api, formValues }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));

    const { WaterAndEffluentsWithdrawalForm } = getState().WaterAndEffluents;

    const organization_id = getOrganizationId();
    try {
      const response = await api.post(
        `water/data/${organization_id}`,
        {
          withdrawal: Object.keys(WaterAndEffluentsWithdrawalForm).reduce(
            (object, key) => {
              if (key !== "id") {
                object[key] = WaterAndEffluentsWithdrawalForm[key];
              }
              return object;
            },
            {}
          ),
          discharge: formValues?.map((obj) => {
            if (obj.id) {
              const { id, ...rest } = obj;
              return rest;
            }
            return obj;
          }),
        },
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
      // 
      if (response.status === 200 || response.status === 201) {
        // dispatch(resetWaterandEffluentsForm());

        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
      dispatch(fetchWaterandEffluentsInputData({ api, params: {} }));
    }
  }
);
export const WaterandEffluentsUploadEvidence = createAsyncThunk(
  "WaterAndEffluents/WaterandEffluentsUploadEvidence",
  async ({ files, api, type }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    const accessToken = localStorage.getItem("accessToken");
    const organization_id = getOrganizationId();
    console.log(formData);
    try {
      const response = await api.post(
        `water/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
        // return response?.data?.path
      }
      return response.data;
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateWaterandEffluentsData = createAsyncThunk(
  "WaterAndEffluents/updateWaterandEffluentsData",
  async ({ api, id, formValues }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    const { WaterAndEffluentsWithdrawalForm } = getState().WaterAndEffluents;
// console.log({WaterandEffluentsWithdrawalForm})
    try {
      const response = await api.put(
        `water/data/${organization_id}/${id}`,
        {
          withdrawal: Object.keys(WaterAndEffluentsWithdrawalForm).reduce(
            (object, key) => {
              if (key !== "id") {
                object[key] = WaterAndEffluentsWithdrawalForm[key];
              }
              return object;
            },
            {}
          ),
          discharge: formValues?.map((obj) => {
            if (obj.id) {
              const { id, ...rest } = obj;
              return rest;
            }
            return obj;
          }),
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
      dispatch(fetchWaterandEffluentsInputData({ api, params: {} }));
    }
  }
);
export const deleteWaterandEffluentsData = createAsyncThunk(
  "WaterAndEffluents/deleteWaterandEffluentsData",
  async ({ api, id }, { _, dispatch }) => {
    dispatch(setIsLoading(true));

    const organization_id = getOrganizationId();
    try {
      const response = await api.delete(
        `water/data/${organization_id}/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(fetchWaterandEffluentsInputData({ api, params: {} }));
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
