import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import useApi from "../../customHooks/useApi";

import { useState, useCallback} from "react";
import { setWaterAndEffluentsWithdrawalForm } from "../../features/waterandeffluents/WaterandEffluentsSlice";
import { WaterandEffluentsUploadEvidence } from "../../features/waterandeffluents/WaterandEffluentsThunk";


const WaterandEffluentsWithdrawalForm = ({  setActiveTab,setIsWaterWithdrawalFormValid }) => {
 
  const [files, setFiles] = useState(null);

  const formValue = useSelector((state) => state.WaterAndEffluents.WaterAndEffluentsWithdrawalForm);
  
  const api = useApi();
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const unitOptions = useSelector((state) => state.WaterAndEffluents?.unitData);
  const { siteData, waterSourceData, waterType } = useSelector((state) => state.WaterAndEffluents);

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();
    const form = document.querySelector("#purchase-Scope3Category5-form");
    
    if (form.checkValidity()) {
      // if (files||formValue.withdrawal_evidence) {
        setActiveTab("waterDischarge");
        setIsWaterWithdrawalFormValid(true);
      // } else {
      //   toast.warning("Please upload evidence");
      // }
    } else {
      form.reportValidity();
    }
  }, [files, setActiveTab, setIsWaterWithdrawalFormValid]);

  const handleFormChange = useCallback(
    async (e) => {
      const { name, type, value, files: selectedFiles } = e.target;
  
      if (type === "file") {
        setFiles(null); // Reset the file state
  
        try {
          const res = await dispatch(
            WaterandEffluentsUploadEvidence({ api, files: selectedFiles })
          );
          if (res?.payload?.path) {
            dispatch(setWaterAndEffluentsWithdrawalForm({ evidence: res.payload.path }));
            setFiles(selectedFiles); // Set selected files after upload
          } else {
            toast.error("Failed to upload evidence");
          }
        } catch (error) {
          console.error("File upload error:", error);
        }
      } else {
        const updatedValue = name === "water_source_id"
          ? waterSourceData.find((item) => item.id == value)?.water_source || value
          : value;
  
        const updatePayload = name === "water_source_id"
          ? { withdrawal_water_source: updatedValue, [name]: value }
          : { [name]: value };
        const updatedWaterType = name === "water_type_id"
          ? waterType.find((item) => item.id == value)?.water_type || value
          : value;
  
        const updateWaterTypePayload = name === "water_type_id"
          ? { withdrawal_water_type: updatedWaterType, [name]: value }
          : { [name]: value };
        dispatch(setWaterAndEffluentsWithdrawalForm(updatePayload));
        dispatch(setWaterAndEffluentsWithdrawalForm(updateWaterTypePayload));
      }
    },
    [dispatch, waterSourceData, api]
  );
  

  return (
    <div className="flex flex-col justify-start items-start text-xs rounded-t-md h-full">
      <form
        id="purchase-Scope3Category5-form"
        className="flex flex-col w-full py-4"
        style={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? "none" : "auto" }}
        onSubmit={handleFormSubmit}
      >
        <div className="grid grid-cols-4 gap-4 px-8 mb-4">
          <div className="col-span-1 flex flex-col">
            <label htmlFor="withdrawal_period_start" className="text-xs py-1.5">
              Period Start<span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="withdrawal_period_start"
              id="withdrawal_period_start"
              max={new Date().toISOString().split("T")[0]}
              value={formValue.withdrawal_period_start || ""}
              onChange={handleFormChange}
              className="bg-gray-50 text-neutral-700 text-xs py-1.5 px-4 border-0 focus:outline-none"
              required
            />
          </div>

          <div className="col-span-1 flex flex-col">
            <label htmlFor="withdrawal_period_end" className="text-xs py-1.5">
              Period End<span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="withdrawal_period_end"
              id="withdrawal_period_end"
              max={new Date().toISOString().split("T")[0]}
              value={formValue.withdrawal_period_end || ""}
              onChange={handleFormChange}
              className="bg-gray-50 text-neutral-700 text-xs py-1.5 px-4 border-0 focus:outline-none"
              min={formValue.withdrawal_period_start || ""}
              required
            />
          </div>

          <div className="col-span-1 flex flex-col">
            <label htmlFor="withdrawal_site_id" className="text-xs py-1.5">
              Site<span className="text-red-500">*</span>
            </label>
            <select
              name="withdrawal_site_id"
              value={formValue.withdrawal_site_id || ""}
              onChange={handleFormChange}
              className="bg-gray-50 text-neutral-700 text-xs py-1.5 px-4 border-0 focus:outline-none"
              required
            >
              <option value="" disabled>Choose the site</option>
              {siteData?.map((site) => (
                <option key={site.id} value={site.id}>
                  {site.site}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-1 flex flex-col">
            <label htmlFor="water_source_id" className="text-xs py-1.5">
              Water Source<span className="text-red-500">*</span>
            </label>
            <select
              name="water_source_id"
              value={formValue.water_source_id || ""}
              onChange={handleFormChange}
              className="bg-gray-50 text-neutral-700 text-xs py-1.5 px-4 border-0 focus:outline-none"
              required
            >
              <option value="" disabled>Choose the water source</option>
              {waterSourceData
                .map(({id,water_source}) => (
                  <option key={id} value={id}>
                    {water_source}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-span-1 flex flex-col">
            <label htmlFor="water_type_id" className="text-xs py-1.5">
              Water Type <span className="text-red-500">*</span>
            </label>
            <select
              name="water_type_id"
              value={formValue.water_type_id || ""}
              onChange={handleFormChange}
              className="bg-gray-50 text-neutral-700 text-xs py-1.5 px-4 border-0 focus:outline-none"
              required
            >
              <option value="" disabled>Choose the water type</option>
              {waterType?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.water_type}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-1 flex flex-col">
            <label htmlFor="withdrawal_quantity" className="text-xs py-1.5">
              Withdrawal Quantity<span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="withdrawal_quantity"
              value={formValue.withdrawal_quantity || ""}
              onChange={handleFormChange}
              className="bg-gray-50 text-neutral-700 text-xs py-1.5 px-4 border-0 focus:outline-none"
              placeholder="Type the quantity"
              min="0"
              required
            />
          </div>

          <div className="col-span-1 flex flex-col">
            <label htmlFor="withdrawal_unit_id" className="text-xs py-1.5">
              Unit<span className="text-red-500">*</span>
            </label>
            <select
              name="withdrawal_unit_id"
              value={formValue.withdrawal_unit_id || ""}
              onChange={handleFormChange}
              className="bg-gray-50 text-neutral-700 text-xs py-1.5 px-4 border-0 focus:outline-none"
              required
            >
              <option value="" disabled>Choose the unit</option>
              {unitOptions?.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.unit}
                </option>
              ))}
            </select>
          </div>
       <div className=" flex flex-col">
        <label htmlFor={`withdrawal_evidence`} className="text-xs py-1.5 flex flex-col">
          <span>Evidence </span> 
          {formValue.evidence ? (
            <p className="text-green-600 mt-1 underline cursor-pointer">
              Evidence Uploaded
            </p>
          ) : (
           ""
          )}
        <input
          // required={!formValue.evidence}
          type="file"
          name="withdrawal_evidence"
          id={`withdrawal_evidence`}
          multiple
          accept=".jpg, .jpeg, .png, .pdf, .zip"
          onChange={handleFormChange}
          className={`appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none ${formValue.evidence ? 'hidden' : ''}`}
        />
        </label>
      </div>
        </div>

        <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
          
            <div className="w-1/6 flex flex-col">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-6 text-white text-sm ${
                  loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                } rounded-md`}
              >
                Save and Continue
              </button>
            </div>
          </div>
      </form>
    </div>
  );
};

export default WaterandEffluentsWithdrawalForm;

