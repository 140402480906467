import React, { useEffect } from "react";
import Header from "../../components/common/Header";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as BRSR } from "../../app/assets/BRSRIcon.svg";
import BRSRReport from "./BRSRContent/BRSR";



const Index = () => {
  return (
    <div className="h-screen">
      <Header PageIcon={BRSR} pageTitle={"BRSR"} />
      <div className="p-2 px-4">

 <BRSRReport/>
      </div>
    </div>
  );
};

export default Index;



