import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../app/assets/CloseIcon.svg";
// import { ReactComponent as waterDischargeIcon } from "../../app/assets/waterDischargeIcon.svg";
import { ReactComponent as WaterWithdrawalIcon } from "../../app/assets/WaterandEffluents.svg";
import WaterandEffluentsDischargeForm from "./WaterandEffluentsDischargeForm";
import WaterandEffluentsDischargeEditAndCopyForm from "./WaterandEffluentsDischargeFormEditAndCopyForm";
import WaterandEffluentsWithdrawalForm from "./WaterandEffluentsWithdrawalForm";
import WaterandEffluentsFormWithdrawalEditAndCopyForm from "./WaterandEffluentsFormWithdrawalEditAndCopyForm";



const WaterandEffluentsFormPopUp = ({
  closePopup,
  type,
  data,
  handleFormChange,
  files,
  startDate,
  selectedWasteType,
}) => {
  const [activeTab, setActiveTab] = useState("WaterWithdrawal");
  const [isWaterWithdrawalFormValid, setIsWaterWithdrawalFormValid] = useState(false);
  useEffect(() => {
    
    if (type !== "newEntry") {
      setIsWaterWithdrawalFormValid(true)
      if (selectedWasteType === 2) {
        setActiveTab("waterDischarge");
      } else {
        setActiveTab("WaterWithdrawal");
      }
    } else {
      if (selectedWasteType === 2 && isWaterWithdrawalFormValid) {
        setActiveTab("waterDischarge");
      } else {
        setActiveTab("WaterWithdrawal");
      }
    }
  }, [selectedWasteType, type]); 
  const renderForm = () => {
    switch (activeTab) {
      case "waterDischarge":
        switch (type) {
          case "newEntry":
            return (
              <WaterandEffluentsDischargeForm
                closePurchasePopupForm={closePopup}
                
              />
            );
          case "edit":
          case "copy":
            return (
              <WaterandEffluentsDischargeEditAndCopyForm
                closePurchasePopupForm={closePopup}
                actionType={type}
                data={data}
              />
            );
          default:
            break;
        }
        break;
      case "WaterWithdrawal":
        switch (type) {
          case "newEntry":
            return (
              <WaterandEffluentsWithdrawalForm
                setActiveTab={setActiveTab}
                closePurchasePopupForm={closePopup}
                files={files}
                handleFormChange={handleFormChange}
                startDate={startDate}
                setIsWaterWithdrawalFormValid={setIsWaterWithdrawalFormValid}
                
              />
            );
          case "edit":
          case "copy":
            return (
              <WaterandEffluentsFormWithdrawalEditAndCopyForm
                closePurchasePopupForm={closePopup}
                actionType={type}
                data={data}
                setActiveTab={setActiveTab}
                setIsWaterWithdrawalFormValid={setIsWaterWithdrawalFormValid}
              />
            );
          default:
            break;
        }
        break;
      default:
        break;
    }
  };
  
  return (
    <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
      <div className="flex flex-col justify-start gap-4 w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
        <div className="flex flex-col justify-between  w-full bg-gray-50 py-2 px-4 border-b border-gray-300">
          <div className="flex justify-between  w-full bg-gray-50 px-4">
            <span className="cursor-pointer">Water and Effluents</span>
            <span className="cursor-pointer" onClick={closePopup}>
              <CloseIcon />
            </span>
          </div>

          <div className="flex flex-shrink-0 w-fit gap-3 px-2 p-1">
            <button
              className={`flex border border-green-600 items-center rounded-md mr-4 cursor-pointer px-2  flex-shrink-0 h-8 ${
                activeTab === "WaterWithdrawal"
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600 transition-all duration-500 ease-in-out transform translate-x-0"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform "
              } text-center p-1 px-2`}
              onClick={() => setActiveTab("WaterWithdrawal")}
            >
             <WaterWithdrawalIcon className="fill-current w-4 h-4" />
            <span className="px-2">Water Withdrawal</span>
            </button>
            <button
              className={`flex border border-green-600 items-center rounded-md mr-4 cursor-pointer px-2  flex-shrink-0 h-8 ${
                activeTab === "waterDischarge"
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600 transition-all duration-500 ease-in-out transform translate-x-0"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
              } text-center`}
              onClick={() => isWaterWithdrawalFormValid && setActiveTab("waterDischarge")}
            > <WaterWithdrawalIcon className="fill-current w-4 h-4 stroke-inherit" />
              <span className="px-2">Water Discharged</span>
            </button>
          </div>
        </div>
        <div className="px-2 h-[430px] overflow-auto">{renderForm()}</div>
      </div>
    </div>
  );
};

export default WaterandEffluentsFormPopUp;

