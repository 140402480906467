export function calculateDaysBetween(date1, date2) {
  const firstDate = typeof date1 === "string" ? new Date(date1) : date1;
  const secondDate = typeof date2 === "string" ? new Date(date2) : date2;

  const timeDifference = Math.abs(firstDate - secondDate);

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}
export function calculatequantity(days, totalDays) {
  if (!totalDays) {
    return 0;
  }
  if (!days) {
    return 0;
  }
  return (days / totalDays) * 100;
}
function formatDate(dateStr) {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`;
  }
  
  function isValidDate(dateStr) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateStr);
  }
  
  export function processDate(dateStr) {
    if(!dateStr){
  return null
    }
    if (isValidDate(dateStr)) {
      return dateStr;
    } else {
      return formatDate(dateStr);
    }
  }
  
  
  export function calculateProportion(
    treatedPeriodStart,
    treatedPeriodEnd,
    producedPeriodStart,
    producedPeriodEnd,
    producedQuantity,
    quantity
  ) {
    if(!treatedPeriodStart || !treatedPeriodEnd || !producedPeriodStart || !producedPeriodEnd || !producedQuantity || !quantity){
      return 0
    }
  
    const daysInTreatedPeriod = calculateDaysBetween(
      treatedPeriodStart,
      treatedPeriodEnd
    );
    const daysInProducedPeriod = calculateDaysBetween(
      producedPeriodStart,
      producedPeriodEnd
    );
    const proportion = (quantity / producedQuantity) * (daysInProducedPeriod + 1) / (daysInTreatedPeriod + 1);
  
    return proportion?Number(proportion*100):0;
  }
  
  
  export function calculateQuantity(
    treatedPeriodStart,
    treatedPeriodEnd,
    producedPeriodStart,
    producedPeriodEnd,
    producedQuantity,
    proportion
  ) {
    if(!treatedPeriodStart || !treatedPeriodEnd || !producedPeriodStart || !producedPeriodEnd || !producedQuantity || !proportion){
      return 0
    }
    if (!proportion) {
      return 0;
    }
    const daysInTreatedPeriod = calculateDaysBetween(
      treatedPeriodStart,
      treatedPeriodEnd
    );
    const daysInProducedPeriod = calculateDaysBetween(
      producedPeriodStart,
      producedPeriodEnd
    );
    const quantity = (daysInTreatedPeriod + 1) / (daysInProducedPeriod + 1) * producedQuantity * proportion;
  
    return quantity?Number(quantity/100):0;
  }

  export function calculatePeriodQuantity(produced_start, produced_end, total_quantity, start, end) {
  
    const producedStartTime = new Date(produced_start).getTime();
    const producedEndTime = new Date(produced_end).getTime();
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    if (endTime < producedStartTime || startTime > producedEndTime) {
      return 0;
    }
  
    const overlapStart = Math.max(producedStartTime, startTime);
    const overlapEnd = Math.min(producedEndTime, endTime);
    const totalProducedDuration = (producedEndTime - producedStartTime)+1;
    const overlappingDuration =( overlapEnd - overlapStart)+1;
    console.log({totalProducedDuration,overlappingDuration})
    const proportion = overlappingDuration / totalProducedDuration;
    const quantityForPeriod = total_quantity * proportion;
  
    return (quantityForPeriod);
  }
  
  // // Example usage:
  // const produced_start = "2023-07-01";
  // const produced_end = "2023-12-31";
  // const total_quantity = 10000;
  // const start = "2023-07-01"; // Period start for which we need to calculate
  // const end = "2023-09-31";   // Period end for which we need to calculate
  
  // console.log(`Quantity for the period: ${result.toFixed(2)}`);
  // const result = calculatePeriodQuantity(produced_start, produced_end, total_quantity, start, end);
  // return result
  
  const calculateOverlapDays = (start1, end1, start2, end2) => {
    const start = new Date(Math.max(new Date(start1), new Date(start2)));
    const end = new Date(Math.min(new Date(end1), new Date(end2)));
    const timeDiff = end - start+1;
    
    return timeDiff >= 0 ? Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) : 0;
  };
  
  // Function to calculate how much of the produced quantity is available for a given period
  export const calculateAllowableQuantity = (producedStart, producedEnd, producedQuantity, treatedPeriods) => {
    const producedDays = calculateOverlapDays(producedStart, producedEnd, producedStart, producedEnd);
    const treatedTrack = {};  // To track cumulative treated quantities across overlapping periods

    // Iterate over treated periods to validate quantities
    for (const treated of treatedPeriods) {
        const { start, end, quantity } = treated;

        // Calculate overlapping days between produced and treated periods
        const treatedDays = calculateOverlapDays(producedStart, producedEnd, start, end);
        if (treatedDays > 0) {
            // Proportion of produced quantity for this treated period
            const allowableQuantityForTreated = (producedQuantity * treatedDays) / producedDays;


            // We need to check if this treated period overlaps with any previous treated entries
            let totalTreatedForPeriod = 0;

            // Go through existing treated periods and accumulate their quantities if they overlap with the current treated entry
            for (const previousTreated of treatedPeriods) {
                const overlapDays = calculateOverlapDays(start, end, previousTreated.start, previousTreated.end);
                if (overlapDays > 0) {
                    totalTreatedForPeriod += previousTreated.quantity;
                }
            }

            // Check if adding the current treated entry would exceed the allowable quantity
    
            if (totalTreatedForPeriod  > allowableQuantityForTreated) {
                return `Treated quantity (${totalTreatedForPeriod}) exceeds allowed quantity (${allowableQuantityForTreated}) for the period ${start} to ${end}`;
            }
        } else {
            return `No overlapping days for the period ${start} to ${end}`;
        }
    }

    return `All treated quantities are valid.`;
    
};