// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setFinancialYearFilter } from '../Redux/BRSRSlices';

// const InputField = ({ label, startPlaceholder, endPlaceholder, startDate, endDate, setFinancialYear, fieldKey,setStartDate ,setEndDate}) => {
//   const { baseYear, reportYear } =useSelector(state=>state.BRSR.FinancialYearFilter)
 
//   const [maxEndDate, setMaxEndDate] = useState('');
// const dispatch=useDispatch()


//   // Function to calculate end of financial year based on startDate
//   const calculateFinancialYearEnd = (start) => {
//     const startMonth = start.getMonth();
//     const startYear = start.getFullYear();
    
//     if (startMonth < 3) { // Jan, Feb, Mar (treated as previous financial year)
//       return new Date(startYear, 2, 31); // March 31 of the same year
//     } else { // April and beyond
//       return new Date(startYear + 1, 2, 31); // March 31 of the following year
//     }
//   };

//   const handleStartDateChange = (e) => {
//     const selectedStartDate = new Date(e.target.value + '-01'); // Convert month input to Date
//     const startDate=e.target.value
//     setStartDate(startDate);
//     const startYear = new Date(`${startDate}-01`).getFullYear();
//         const currentFinancialYear = startYear - (new Date(`${startDate}-01`).getMonth() < 3 ? 1 : 0);
//         setFinancialYear(currentFinancialYear);
//         dispatch(setFinancialYearFilter({[fieldKey]:currentFinancialYear}))
//         // Calculate the max end date for the financial year based on selected start date
//         const calculatedEndDate = calculateFinancialYearEnd(selectedStartDate);
//     setMaxEndDate(calculatedEndDate.toISOString().slice(0, 7)); // Format as 'yyyy-mm'
    
//     // Reset endDate if it exceeds the new financial year end
//     if (endDate && endDate > maxEndDate) {
//       setEndDate('');
//     }
//   };

//   const handleEndDateChange = (e) => {
//     const selectedEndDate = e.target.value;
    
//     // Ensure end date is within the max allowed date and not before start date
//     if (selectedEndDate <= maxEndDate && selectedEndDate >= startDate) {
//       setEndDate(selectedEndDate);
//     } else {
//       alert('Please select an end date within the financial year range.');
//     }
//   };
  
   
//   useEffect(() => {
//     if(reportYear) {
//       const baseYearDate = new Date(`${baseYear}-04-01`);
//       setStartDate(`${baseYearDate.getFullYear()}-${('0' + (baseYearDate.getMonth() + 1)).slice(-2)}`);
//       const calculatedEndDate = calculateFinancialYearEnd(baseYearDate);
//       setMaxEndDate(calculatedEndDate.toISOString().slice(0, 7)); // Format as 'yyyy-mm'
//     }
//   }, [baseYear, reportYear]);
//   return (
//     <div className="flex flex-col">
//       <label className="text-sm font-medium mb-2">{label}</label>
//       <div className="flex items-center space-x-4">
//         <input
//           type="month"
//           value={startDate}
//           onChange={handleStartDateChange}
//           placeholder={startPlaceholder}
//           className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none"
//         />
//         <span className="text-gray-500">to</span>
//         <input
//           type="month"
//           min={startDate}
//           value={endDate}
//           onChange={handleEndDateChange}
//           placeholder={endPlaceholder}
//           max={maxEndDate} // Set the max attribute dynamically based on start date
//           className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none"
//         />
//       </div>
//     </div>
//   );
// };

// export default InputField;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFinancialYearFilter } from "../Redux/BRSRSlices";

const InputField = ({ label, startDate, endDate, setStartDate, setEndDate, setFinancialYear, fieldKey }) => {
 
  const [maxEndDate, setMaxEndDate] = useState("");
  const dispatch = useDispatch();

  const calculateFinancialYearEnd = (start) => {
    const startMonth = start.getMonth();
    const startYear = start.getFullYear();
    return startMonth < 3 ? new Date(startYear, 2, 31) : new Date(startYear + 1, 2, 31);
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = new Date(e.target.value + "-01");
    setStartDate(e.target.value);
    const financialYear = selectedStartDate.getFullYear() - (selectedStartDate.getMonth() < 3 ? 1 : 0);
    setFinancialYear(financialYear);
    

    const calculatedEndDate = calculateFinancialYearEnd(selectedStartDate);
    setMaxEndDate(calculatedEndDate.toISOString().slice(0, 7));

    if (endDate && endDate > calculatedEndDate.toISOString().slice(0, 7)) {
      setEndDate("");
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (selectedEndDate <= maxEndDate && selectedEndDate >= startDate) {
      setEndDate(selectedEndDate);
    } else {
      alert("Please select an end date within the financial year range.");
    }
  };

  useEffect(() => {
    if (startDate) {
      const start = new Date(startDate + "-01");
      const calculatedEndDate = calculateFinancialYearEnd(start);
      setMaxEndDate(calculatedEndDate.toISOString().slice(0, 7));
    }
  }, [startDate]);

  return (
    <div className="flex flex-col">
      <label className="text-sm font-medium mb-2">{label}</label>
      <div className="flex items-center space-x-4">
        <input
          type="month"
          value={startDate}
          onChange={handleStartDateChange}
          className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none"
        />
        <span className="text-gray-500">to</span>
        <input
          type="month"
          value={endDate}
          onChange={handleEndDateChange}
          min={startDate}
          max={maxEndDate}
          className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default InputField;
