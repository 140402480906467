import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import useApi from "../../customHooks/useApi";


import FormGroup from "./FormGroup";

import { calculateAllowableQuantity, calculateProportion, calculateQuantity } from "./process";
import { setFormValues } from "../../features/waterandeffluents/WaterandEffluentsSlice";
import { postWaterandEffluentsDischargeData, updateWaterandEffluentsData, WaterandEffluentsUploadEvidence } from "../../features/waterandeffluents/WaterandEffluentsThunk";

const WaterandEffluentsDischargeEditAndCopyForm = ({closePurchasePopupForm, actionType }) => {
  const api = useApi();
  // const [totalTreatedQuantity, settotalTreatedQuantity] = useState(0);
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
 
  const [isExceed, setisExceed] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
 

  const unitOptions = useSelector((state) => state.WaterAndEffluents.unitData);
  const siteData = useSelector((state) => state.WaterAndEffluents.siteData);

  const initialFormValue = {
    discharge_period_start: "",
    discharge_period_end: "",
    discharge_site_id: "",
 
    discharge_quantity: "",
    discharge_unit_id: "",
   
   
    discharge_evidence: null,
  };
  let {
    WaterAndEffluentsWithdrawalForm,
    formValues,waterDischargeDestination,waterDischargeQuality
  } = useSelector((state) => state.WaterAndEffluents);
  const { withdrawal_period_start, withdrawal_period_end, withdrawal_quantity } =
    WaterAndEffluentsWithdrawalForm;
 
  const handleFormChange = async (e, index) => {
    const { name, value, files } = e.target;
  
    // Copy the current form values
    const updatedFormValues = [...formValues];
  
    // Handle file upload for discharge evidence
    if (name === "discharge_evidence" && files) {
      const selectedFiles = files;
      try {
        const res = await dispatch(
          // Uncomment and provide your upload action here
          WaterandEffluentsUploadEvidence({ api, files: selectedFiles })
        );
        if (res?.payload?.path) {
          updatedFormValues[index] = {
            ...updatedFormValues[index],
            evidence: res.payload.path,
          };
          setFiles(selectedFiles);
        } else {
          toast.error("Failed to upload evidence");
        }
      } catch (error) {
        console.error("File upload error:", error);
      }
    }
  
    // Update the form values for the specific field
    updatedFormValues[index] = {
      ...updatedFormValues[index],
      [name]: value,
    };
  
    // Calculate and update treated quantity based on the discharge period
    if (["discharge_period_start", "discharge_period_end"].includes(name)) {
      if (
        updatedFormValues[index].discharge_period_start &&
        updatedFormValues[index].discharge_period_end
      ) {
        const TreatedQuantity = calculateQuantity(
          updatedFormValues[index].discharge_period_start,
          updatedFormValues[index].discharge_period_end,
          withdrawal_period_start,
          withdrawal_period_end,
          Number(withdrawal_quantity),
          100 // Adjust the proportion as needed
        ).toFixed();
  
        updatedFormValues[index].discharge_quantity =
          TreatedQuantity > 0 ? TreatedQuantity : "";
      }
    }
  
    // Update payload for discharge quality and destination if applicable
    if (name === "discharge_quality_id") {
      const updatedDischargeQuality = 
        waterDischargeQuality.find((item) => item.id == value)?.discharge_quality || value;
  
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        discharge_quality: updatedDischargeQuality,
        discharge_quality_id: value,
      };
    }
  
    if (name === "discharge_destination_id") {
      const updatedDischargeDestination = 
        waterDischargeDestination.find((item) => item.id == value)?.discharge_destination || value;
  
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        discharge_destination: updatedDischargeDestination,
        discharge_destination_id: value,
      };
    }
  
    // Dispatch the updated form values
    dispatch(setFormValues(updatedFormValues));
  };
  
  const handleAddNew = () => {
    const form = document.querySelector("#purchase-WaterAndEffluents-form");
    if (!form.checkValidity()) {
      const firstInvalidControl = form.querySelector(":invalid");
      toast.error(
        `Error: ${firstInvalidControl.validationMessage}`
      );
      firstInvalidControl.focus();
      return
    }

    if (!validateTreatedQuantities(formValues)) {
      return;
    }
    setShouldUpdate(!shouldUpdate);
    dispatch(
      setFormValues([
        ...formValues,
        {
          ...initialFormValue,
          discharge_site_id: WaterAndEffluentsWithdrawalForm.withdrawal_site_id,
          discharge_unit_id: WaterAndEffluentsWithdrawalForm.withdrawal_unit_id,
       
        },
      ])
    );
  };
  const handleCopy = (index,formValue) => {
    const form = document.querySelector("#purchase-WaterAndEffluents-form");
    if (!form.checkValidity()) {
      const firstInvalidControl = form.querySelector(":invalid");
      toast.error(`Error: ${firstInvalidControl.validationMessage}`);
      firstInvalidControl.focus();
      return;
    }
    const newFormValues = [...formValues];
    newFormValues.splice(index + 1, 0, { ...formValue,discharge_proportion_of_waste:0,discharge_quantity:0  });
    if (!validateTreatedQuantities(newFormValues)) {
      return;
    }
    dispatch(setFormValues(newFormValues));
  };

  const handleRemove = (index) => {
    // if (formValues.length > 1) {
      dispatch(setFormValues(formValues.filter((_, i) => i !== index)));
    // }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!validateTreatedQuantities(formValues)) {
      return;
    }

    const form = document.querySelector("#purchase-WaterAndEffluents-form");
    if (form.length && form.checkValidity()) {
      
        const subIds = formValues.map(() => uuidv4());
        switch (actionType) {
          case 'edit':
            
            dispatch(
              updateWaterandEffluentsData({ api,id:WaterAndEffluentsWithdrawalForm.id, formValues:formValues.map((i,index)=>{
                return {
                  ...i,
                  sub_id:subIds[index],id:WaterAndEffluentsWithdrawalForm.id
                }
              }) })
            );
            break;
            case 'copy':
              dispatch(
                postWaterandEffluentsDischargeData({ api, formValues:formValues.map((i,index)=>{
                  return {
                    ...i,
                    sub_id:subIds[index]
                  }
                }) })
              );
            break;
        
          default:
            break;
        }

        closePurchasePopupForm();
      
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };
  useEffect(() => {
    const updatedFormValues = formValues.map((fv) => ({
      ...fv,
      discharge_site_id: WaterAndEffluentsWithdrawalForm.withdrawal_site_id,
      discharge_unit_id: WaterAndEffluentsWithdrawalForm.withdrawal_unit_id,
    }));
   
    dispatch(setFormValues(updatedFormValues));
  }, [
    WaterAndEffluentsWithdrawalForm.withdrawal_site_id,
   
    shouldUpdate,
  ]);
  const validateTreatedQuantities = (formValues) => {
    const treatedPeriods = formValues.map((item) => {
      return {
        start: item.discharge_period_start,
        end: item.discharge_period_end,
        quantity: Number(item.discharge_quantity),
      };
    });

    const result = calculateAllowableQuantity(
      withdrawal_period_start,
      withdrawal_period_end,
      Number(withdrawal_quantity),
      treatedPeriods
    );

    if (typeof result === "string" && result === "All treated quantities are valid.") {
      setisExceed(false);
     
      return true
    } else {
      setisExceed(true);
      toast.error(result,{theme:"dark"});
      return false
    }
  };
  

  return (
    <>
      <div className="flex flex-col justify-center items-center rounded-t-md text-xs">
        <form
          style={{
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "auto",
          }}
          id="purchase-WaterAndEffluents-form"
          className="flex flex-col w-full py-4"
          onSubmit={handlePurchaseFormSubmit}
        >
          {formValues?.length>0?formValues.map((formValue, index) => (
            <FormGroup
            key={index}
              formValue={formValue}
              handleFormChange={(e) => handleFormChange(e, index)}
              handleRemove={() => handleRemove(index)}
              handleCopy={() => handleCopy(index)}
              unitOptions={unitOptions}
              siteData={siteData}
              waterDischargeDestinations={waterDischargeDestination}
              waterDischargeQuality={waterDischargeQuality}
            />
          )):<h1 className="w-full flex items-center justify-center py-6">No waste treated for the time period</h1> }
          <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
            <button
              type="button"
              onClick={handleAddNew}
              className="py-2 px-6 text-green-600 text-sm rounded-md"
            >
              Add new
            </button>
            <div className="w-1/6 flex flex-col">
              <button
                disabled={loading}
                type="button"
                className="py-2 px-6 text-green-600 text-sm rounded-md"
                onClick={() => closePurchasePopupForm()}
              >
                Cancel
              </button>
            </div>
            <div className="w-1/6 flex flex-col">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-6 text-white text-sm ${
                  loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                } rounded-md`}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default WaterandEffluentsDischargeEditAndCopyForm;

