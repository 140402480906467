import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [
   
  ],
  waterTypeOptions: [
    
  ],
  waterDischargeOptions: [
   
  ],
  siteOptions: [
 
  ],
  financialYear: [2024],
  dataForCurrentYearChange: [],
  filter: {},
};

const waterSlice = createSlice({
  name: "water",
  initialState,
  reducers: {
    setFinancialYear(state, action) {
      state.financialYear = action.payload;
    },

    setWaterData(state, action) {
      state.data = action.payload;
    },
    setSiteOptions(state, action) {
      state.siteOptions = action.payload;
    },
    setwaterTypeOptions(state, action) {
      state.waterTypeOptions = action.payload;
    },
    setwaterDischargeOptions(state, action) {
      state.waterDischargeOptions = action.payload;
    },
    setdataForCurrentYearChange(state, action) {
      state.dataForCurrentYearChange = action.payload;
    },

    setfuelFilter(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const {
  setFinancialYear,
  setWaterData,
  setSiteOptions,
  setdataForCurrentYearChange,
  setfuelFilter,
  setwaterTypeOptions,
  setwaterDischargeOptions,
} = waterSlice.actions;

export default waterSlice.reducer;
