
export function getCurrentFinancialYear() {
  var today = new Date();
  var currentMonth = today.getMonth() + 1;

  if (currentMonth <= 3) {
    return today.getFullYear() - 1;
  } else {
    return today.getFullYear();
  }
}
export const calculateWaterDischargePercentage = (
  data,
  watertype = "discharge"
) => {
  const dischargePercentageBySite = {};
  const dataBySite = [...data].reduce((acc, entry) => {
    const { site, withdrawal_quantity, discharge_quantity } = entry;

    if (!acc[site]) {
      acc[site] = {
        withdrawal_quantity: 0,
        discharge_quantity: 0,
      };
    }

    acc[site].withdrawal_quantity += withdrawal_quantity;
    acc[site].discharge_quantity += discharge_quantity;

    return acc;
  }, {});

  Object.entries(dataBySite).forEach(
    ([site, { withdrawal_quantity, discharge_quantity }]) => {
      const consumed_quantity = withdrawal_quantity - discharge_quantity;
      const dischargePercentage =
        withdrawal_quantity > 0
          ? (discharge_quantity / withdrawal_quantity) * 100
          : 0;
      const consumedPercentage =
        withdrawal_quantity > 0
          ? (consumed_quantity / withdrawal_quantity) * 100
          : 0;

      dischargePercentageBySite[site] = {
        discharge_percentage: dischargePercentage.toFixed(),
        consumed_percentage: consumedPercentage.toFixed(),
        withdrawal_quantity: withdrawal_quantity.toFixed(),
        discharge_quantity: discharge_quantity.toFixed(),
        consumed_quantity: consumed_quantity.toFixed(),
      };
    }
  );

  return dischargePercentageBySite;
};

export const processStackedChartData = (data, year, typebreakdown, key) => {
 
  let processedData = {};

  let consumedData=[]
  let filteredData = [...data.filter((entry) => entry.year === Number(year))];
  if (key === "consumed_quantity") {
    // const totalWithdrawal = filteredData.reduce((acc, curr) => acc + curr.withdrawal_quantity, 0);
    // const totalDischarge = filteredData.reduce((acc, curr) => acc + curr.discharge_quantity, 0);
    // const totalConsumed = totalWithdrawal - totalDischarge;
    filteredData.forEach((entry) => {
      entry = { ...entry, consumed_quantity: Number(entry.withdrawal_quantity) -Number( entry.discharge_quantity) };
      consumedData.push(entry)
    });

   

  }else{
    consumedData=[...filteredData]
  }
  consumedData.forEach((entry) => {
    let { site } = entry;
    if (!entry[typebreakdown] && typebreakdown !== "") {
      return;
    }
    const breakdown = entry[typebreakdown] || "Total";

    if (!processedData[site]) {
      processedData[site] = {
        site: site,
        breakdown: {},
      };
    }
    
    if (!processedData[site].breakdown[breakdown]) {
      processedData[site].breakdown[breakdown] = {
        value: 0,
        percentage: 0,
      };
    }
    

    processedData[site].breakdown[breakdown].value += Number(entry[key]);
  });

  Object.values(processedData).forEach((entry) => {
    let total = 0;

    Object.values(entry.breakdown).forEach((breakdown) => {
      total += breakdown.value;
    });

    Object.keys(entry.breakdown).forEach((breakdown) => {
      const breakdownValue = entry.breakdown[breakdown].value;
      entry.breakdown[breakdown].percentage =
        total !== 0 ? (breakdownValue / total) * 100 : 0;
    });
  });

  return processedData;
};
export const generateSeriesDataForStackedChart = (
  processedData,
  waterDischargePercentageData,
  waterConsumedPercentageData,
  key
) => {
 
  const xAxisData = Object.keys(processedData);
  const legends = new Set();

  Object.values(processedData).forEach((entry) => {
    Object.keys(entry?.breakdown).forEach((breakdown) => {
      legends.add(breakdown);
    });
  });

  const series = [...legends].map((legend) => ({
    name: legend,
    type: "bar",
    stack: "total",
    label: {
      fontSize: 10,
        fontWeight: "bold",
      show: true,
      formatter: (params) => {
        const site = params.name;
        const entry = processedData[site]?.breakdown[params.seriesName];
       
        const percentage = entry?.percentage.toFixed();
        const value = entry?.value.toFixed();
        if (
          percentage !== undefined &&
          !isNaN(percentage) &&
          percentage !== 0
        ) {
          // return `${percentage.toFixed()}%`;
          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        } else {
          return "";
        }
      },
    },
    data: xAxisData.map(
      (site) => processedData[site]?.breakdown[legend]?.value || 0
    ),
  }));

  if (waterDischargePercentageData && (key === "withdrawal_quantity"||key === "discharge_quantity")) {
    series.push({
      name: "Water Discharged %",
      type: "line",
      label: { show: true, formatter: `{c}%` },
      data: xAxisData.map((site) => ({
        value: waterDischargePercentageData[site]?.discharge_percentage || 0,
        discharge_quantity:
          waterDischargePercentageData[site]?.discharge_quantity || 0,
        withdrawal_quantity:
          waterDischargePercentageData[site]?.withdrawal_quantity || 0,
        consumed_quantity:
        waterDischargePercentageData[site]?.consumed_quantity || 0,
      })),
    });
  }
  if (waterConsumedPercentageData && (key === "withdrawal_quantity"||key === "consumed_quantity")) {
    series.push({
      name: "Water Consumed %",
      type: "line",
      label: { show: true, formatter: `{c}%` },
      data: xAxisData.map((site) => ({
        value: waterDischargePercentageData[site]?.consumed_percentage || 0,
        discharge_quantity:
          waterDischargePercentageData[site]?.discharge_quantity || 0,
        withdrawal_quantity:
          waterDischargePercentageData[site]?.withdrawal_quantity || 0,
        consumed_quantity:
          waterDischargePercentageData[site]?.consumed_quantity || 0,
      })),
    });
  }

  return { series, legends: Array.from(legends) };
};
export const formatText = (text) => {
  if (text === "module") {
    text = "Scope";
  } else if (text === "sub_module") {
    text = "Category";
  }
  const words = text.split("_");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const formattedText = capitalizedWords.join(" ");

  return formattedText;
};

// current year and last year total emission
function currentYearLastYearEmissionDetail(
  data,
  currentYear,
  key = "withdrawal_quantity"
) {
  if (!currentYear) {
    return {
      currentYear: 0,
      previousYear: 0,
      percentageChange: 0,
    };
  }
  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );
  const totalKeyQuantitysCurrentYear = currentYearData?.reduce(
    (total, entry) => total + Number(entry[key]),
    0
  );
  const totalKeyQuantitysPreviousYear = previousYearData?.reduce(
    (total, entry) => total + Number(entry[key]),
    0
  );

  const percentageChange =
    ((totalKeyQuantitysCurrentYear - totalKeyQuantitysPreviousYear) /
      totalKeyQuantitysPreviousYear) *
    100;

  const roundedPercentageChange = Math.floor(percentageChange);

  return {
    currentYear: totalKeyQuantitysCurrentYear.toFixed(),
    previousYear: Math.floor(totalKeyQuantitysPreviousYear),
    percentageChange: roundedPercentageChange,
  };
}


function processData(Inputdata, timeInterval, breakpoint, year, key) {
  
  let dataCopy = JSON.parse(JSON.stringify(Inputdata));
  let data;

  if (year) {
    data = dataCopy.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval == "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    } else if (timeInterval == "Month") {
      function sortByYearAndMonth(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month);
        }
      }

      // Adjust the year for entries with quarter 4
      dataCopy.forEach((item) => {
        if (item.quarter == 4) {
          item.year += 1;
        }
      });

      dataCopy.sort(sortByYearAndMonth);
      data = dataCopy;
    } else if (timeInterval == "Year") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return b.year - a.year;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    }
  }
  let processedData = {};

  switch (key) {
    case "discharge_quantity":
      data.forEach((entry) => {
          let { year, quarter, withdrawal_quantity, month, discharge_quantity } =
            entry;
          withdrawal_quantity = Number(withdrawal_quantity);
          discharge_quantity = Number(discharge_quantity);
          if (!entry[breakpoint] && breakpoint !== "") {
            return;
          }
          let filter = entry[breakpoint] || "Total";

          switch (timeInterval) {
            case "Year":
              if (!processedData[year]) {
                processedData[year] = {};
              }
              if (!processedData[year][filter]) {
                processedData[year][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                };
              }

              processedData[year][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[year][filter].totalKeyQuantity += discharge_quantity;
              processedData[year][filter].discharge_quantity += discharge_quantity;
              break;

            case "Quarter":
              const quarterKey = `${year}-Q${quarter}`;
              if (!processedData[quarterKey]) {
                processedData[quarterKey] = {};
              }
              if (!processedData[quarterKey][filter]) {
                processedData[quarterKey][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                };
              }

              processedData[quarterKey][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[quarterKey][filter].totalKeyQuantity +=
                discharge_quantity;
              processedData[quarterKey][filter].discharge_quantity +=
                discharge_quantity;
              break;

            case "Month":
              const monthKey = `${year}-${month}`;
              if (!processedData[monthKey]) {
                processedData[monthKey] = {};
              }
              if (!processedData[monthKey][filter]) {
                processedData[monthKey][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                };
              }

              processedData[monthKey][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[monthKey][filter].totalKeyQuantity +=
                discharge_quantity;
              processedData[monthKey][filter].discharge_quantity +=
                discharge_quantity;
              break;

            default:
              break;
          }
        });

      break;
    case "withdrawal_quantity":
      data.forEach((entry) => {
          let {
            year,
            quarter,
            withdrawal_quantity,
            month,
            discharge_quantity,
            consumed_quantity,
          } = entry;

          withdrawal_quantity = Number(withdrawal_quantity);
          discharge_quantity = Number(discharge_quantity);
          consumed_quantity = Number(consumed_quantity);
          if (!entry[breakpoint] && breakpoint !== "") {
            return;
          }
          let filter = entry[breakpoint] || "Total";

          switch (timeInterval) {
            case "Year":
              if (!processedData[year]) {
                processedData[year] = {};
              }
              if (!processedData[year][filter]) {
                processedData[year][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                  percentDischarge: 0,
                  consumed_quantity: 0,
                  percentConsumed: 0,
                };
              }
              processedData[year][filter].consumed_quantity +=
                consumed_quantity;
              processedData[year][filter].discharge_quantity += discharge_quantity;
              processedData[year][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[year][filter].totalKeyQuantity += withdrawal_quantity;
              break;

            case "Quarter":
              const quarterKey = `${year}-Q${quarter}`;
              if (!processedData[quarterKey]) {
                processedData[quarterKey] = {};
              }
              if (!processedData[quarterKey][filter]) {
                processedData[quarterKey][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                  percentDischarge: 0,
                  percentConsumed: 0,
                  consumed_quantity: 0,
                };
              }
              processedData[quarterKey][filter].consumed_quantity +=
                consumed_quantity;
              processedData[quarterKey][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[quarterKey][filter].totalKeyQuantity +=
                withdrawal_quantity;
              processedData[quarterKey][filter].discharge_quantity +=
                discharge_quantity;
              break;

            case "Month":
              const monthKey = `${year}-${month}`;
              if (!processedData[monthKey]) {
                processedData[monthKey] = {};
              }
              if (!processedData[monthKey][filter]) {
                processedData[monthKey][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                  percentDischarge: 0,
                  consumed_quantity: 0,
                  percentConsumed: 0,
                };
              }

              processedData[monthKey][filter].consumed_quantity +=
                consumed_quantity;

              processedData[monthKey][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[monthKey][filter].totalKeyQuantity +=
                withdrawal_quantity;
              processedData[monthKey][filter].discharge_quantity +=
                discharge_quantity;
              break;

            default:
              break;
          }
        });

      for (const timeKey in processedData) {
        for (const key in processedData[timeKey]) {
          const dataItem = processedData[timeKey][key];
          if (dataItem.discharge_quantity > 0) {
            dataItem.percentDischarge = (
              (dataItem.discharge_quantity / dataItem.withdrawal_quantity) *
              100
            ).toFixed();
          } else {
            dataItem.percentDischarge = 0;
          }
          if (dataItem.consumed_quantity > 0) {
            dataItem.percentConsumed = (
              (dataItem.consumed_quantity / dataItem.withdrawal_quantity) *
              100
            ).toFixed();
          } else {
            dataItem.percentConsumed = 0;
          }
        }
      }

      break;
    case "consumed_quantity":
      data.forEach((entry) => {
          let { year, quarter, withdrawal_quantity, month, discharge_quantity } =
            entry;
          withdrawal_quantity = Number(withdrawal_quantity);
          discharge_quantity = Number(discharge_quantity);

          if (!entry[breakpoint] && breakpoint !== "") {
            return;
          }
          let filter = entry[breakpoint] || "Total";

          switch (timeInterval) {
            case "Year":
              if (!processedData[year]) {
                processedData[year] = {};
              }
              if (!processedData[year][filter]) {
                processedData[year][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                  percentDischarge: 0,
                };
              }

              processedData[year][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[year][filter].totalKeyQuantity += discharge_quantity;
              processedData[year][filter].discharge_quantity += discharge_quantity;
              break;

            case "Quarter":
              const quarterKey = `${year}-Q${quarter}`;
              if (!processedData[quarterKey]) {
                processedData[quarterKey] = {};
              }
              if (!processedData[quarterKey][filter]) {
                processedData[quarterKey][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                  percentDischarge: 0,
                };
              }

              processedData[quarterKey][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
              processedData[quarterKey][filter].totalKeyQuantity +=
                discharge_quantity;
              processedData[quarterKey][filter].discharge_quantity +=
                discharge_quantity;
              break;

            case "Month":
              const monthKey = `${year}-${month}`;
              if (!processedData[monthKey]) {
                processedData[monthKey] = {};
              }
              if (!processedData[monthKey][filter]) {
                processedData[monthKey][filter] = {
                  withdrawal_quantity: 0,
                  totalKeyQuantity: 0,
                  discharge_quantity: 0,
                  percentDischarge: 0,
                };
              }

              processedData[monthKey][filter].withdrawal_quantity += Number(
                entry["withdrawal_quantity"]
              );
             
  
              processedData[monthKey][filter].discharge_quantity +=
                discharge_quantity;
              break;

            default:
              break;
          }
        });

      // Calculate consumed quantity after all aggregates
      for (const timeKey in processedData) {
        for (const key in processedData[timeKey]) {
          const dataItem = processedData[timeKey][key];
          dataItem.consumed_quantity =
            dataItem.withdrawal_quantity - dataItem.discharge_quantity;
          dataItem.totalKeyQuantity =
            dataItem.withdrawal_quantity - dataItem.discharge_quantity;
        }
      }
     
      break;

    default:
      break;
  }
// console.log({pr})
  return processedData;
}
function sumQuantities(data) {
  const result = {};

  // Loop through each top-level key (e.g., "2023-Jul", "2023-Aug")
  for (const period in data) {
    let totalWithrawal = 0;
    let totalDischarge = 0;
    let totalConsumed = 0;

    // Loop through each second-level key (e.g., "Site 1", "Site 3")
    for (const key in data[period]) {
      const siteData = data[period][key];

      // Sum the quantities
      totalWithrawal += siteData.withdrawal_quantity || 0;
      totalDischarge += siteData.discharge_quantity || 0;
      totalConsumed += siteData.consumed_quantity || 0;
    }

    // Store the summed data for the period
    result[period] = {
      withdrawal_quantity: totalWithrawal,
      discharge_quantity: totalDischarge,
      consumed_quantity: totalConsumed,
    // Calculate percentages
  };
  const totalProduce = result[period].withdrawal_quantity;
  const percentDischarge = totalProduce > 0 ? (result[period].discharge_quantity / totalProduce) * 100 : 0;
  const percentConsumed = totalProduce > 0 ? (result[period].consumed_quantity / totalProduce) * 100 : 0;
  
  // Store the percentages in the result
  result[period].percentDischarge = percentDischarge.toFixed();
  result[period].percentConsumed = percentConsumed.toFixed();
}

  return result;
}
function processDataToLablesAndDataSets(data, key) {
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);
const sum=sumQuantities(data)

  // Extract unique legends (site names)
  for (const timeKey in data) {
    for (const siteKey in data[timeKey]) {
      if (!legends.includes(siteKey)) {
        legends.push(siteKey);
      }
    }
  }

  // Initialize series data arrays
  let dischargePercentageSeriesData = [];
  let consumedPercentageSeriesData = [];
  let dischargeSeriesData = [];
  let WithrawalSeriesData = [];
  let ConsumedSeriesData = [];

  for (const legend of legends) {
    let seriesData = [];
    let withdrawal_quantity = [];
    let consumed_quantity = [];
    let discharge_quantity_data = [];
    let dischargePercentageData = [];
    let consumedPercentageData = [];
    for (const timeKey of xAxisLabels) {
      if (sum[timeKey]) {
        seriesData.push(data[timeKey][legend]?.totalKeyQuantity?.toFixed()||0);
        withdrawal_quantity.push(sum[timeKey]?.withdrawal_quantity || 0);
        discharge_quantity_data.push(sum[timeKey]?.discharge_quantity || 0);
        consumed_quantity.push(sum[timeKey]?.consumed_quantity || 0);

        dischargePercentageData.push(
          isFinite(sum[timeKey]?.percentDischarge) && !isNaN(sum[timeKey]?.percentDischarge)
            ? sum[timeKey]?.percentDischarge
            : 0
        );
        consumedPercentageData.push(
          isFinite(sum[timeKey]?.percentConsumed) && !isNaN(sum[timeKey]?.percentConsumed)
            ? sum[timeKey]?.percentConsumed
            : 0
        );
      } else {
        // Handle missing data for the specific site/legend in that time period
        seriesData.push(0);
        discharge_quantity_data.push(0);
        withdrawal_quantity.push(0);
        consumed_quantity.push(0);
        dischargePercentageData.push(0);
        consumedPercentageData.push(0);
      }
    }

    // Accumulate data for each legend
    dischargePercentageSeriesData.push(...dischargePercentageData);
    consumedPercentageSeriesData.push(...consumedPercentageData);
    WithrawalSeriesData.push(...withdrawal_quantity);
    ConsumedSeriesData.push(...consumed_quantity);
    dischargeSeriesData.push(...discharge_quantity_data);

    // Prepare bar series
    let totalKeyQuantitysByTimeKey = xAxisLabels.reduce((acc, timeKey) => {
      acc[timeKey] = Object.values(data[timeKey]).reduce((total, siteData) => {
        return total + (siteData.totalKeyQuantity || 0);
      }, 0);
      return acc;
    }, {});

    series.push({
      withdrawal_quantity,
      name: legend,
      type: "bar",
      stack: "total",
      barWidth: "20%",
      label: {
        show: true,
        position: legend.trim() === "Total" ? "inside" : "", // Different label positions for "Total"
        formatter: (params) => {
          const timeKeyIndex = params.dataIndex; // Get timeKey index
          const timeKey = xAxisLabels[timeKeyIndex]; // Get the current timeKey
          const totalKeyQuantityForTimeKey = totalKeyQuantitysByTimeKey[timeKey]; // Total quantity for current timeKey
          const value = Number(params.value); // Current bar value

          // Calculate the percentage of the current block relative to the total for the timeKey
          let percentage = ((value / totalKeyQuantityForTimeKey) * 100).toFixed();

          // Handle edge cases
          if (isNaN(percentage) || !isFinite(percentage)) {
            percentage = 0;
          }

          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        },
        fontSize: 10,
        fontWeight: "bold",
      },
      data: seriesData.map((value, index) => ({
        value,
        discharge_quantity: discharge_quantity_data[index],
        withdrawal_quantity: withdrawal_quantity[index],
        percentDischarge: dischargePercentageData[index],
      })),
    });
  }

  // Add line series for discharge and consumed percentages
  if (key == "withdrawal_quantity") {
    series.push({
      name: "Water Discharged %",
      type: "line",
      yAxisIndex: 1, // Secondary y-axis for percentage
      label: { show: true, formatter: `{c}%` },
      data: xAxisLabels.map((_, index) => ({
        dischargeQuantity: dischargeSeriesData[index],
        withdrawalQuantity: WithrawalSeriesData[index],
        value: dischargePercentageSeriesData[index],
      })),
    });

    series.push({
      name: "Water Consumed %",
      type: "line",
      yAxisIndex: 1, // Secondary y-axis for percentage
      label: { show: true, formatter: `{c}%` },
      data: xAxisLabels.map((_, index) => ({
        dischargeQuantity: dischargeSeriesData[index],
        withdrawalQuantity: WithrawalSeriesData[index],
        consumedQuantity: WithrawalSeriesData[index]-dischargeSeriesData[index],
        value:(((WithrawalSeriesData[index]-dischargeSeriesData[index])/WithrawalSeriesData[index])*100).toFixed(),
      })),
    });
  }
  if (key == "discharge_quantity") {
    series.push({
      name: "Water Discharged %",
      type: "line",
      yAxisIndex: 1, // Secondary y-axis for percentage
      label: { show: true, formatter: `{c}%` },
      data: xAxisLabels.map((_, index) => ({
        dischargeQuantity: dischargeSeriesData[index],
        withdrawalQuantity: WithrawalSeriesData[index],
        value: dischargePercentageSeriesData[index],
      })),
    });

    // series.push({
    //   name: "Water Consumed %",
    //   type: "line",
    //   yAxisIndex: 1, // Secondary y-axis for percentage
    //   label: { show: true, formatter: `{c}%` },
    //   data: xAxisLabels.map((_, index) => ({
    //     dischargeQuantity: dischargeSeriesData[index],
    //     withdrawalQuantity: WithrawalSeriesData[index],
    //     consumedQuantity: WithrawalSeriesData[index]-dischargeSeriesData[index],
    //     value:(((WithrawalSeriesData[index]-dischargeSeriesData[index])/WithrawalSeriesData[index])*100).toFixed(),
    //   })),
    // });
  }
  if (key == "consumed_quantity") {
    // series.push({
    //   name: "Water Discharged %",
    //   type: "line",
    //   yAxisIndex: 1, // Secondary y-axis for percentage
    //   label: { show: true, formatter: `{c}%` },
    //   data: xAxisLabels.map((_, index) => ({
    //     dischargeQuantity: dischargeSeriesData[index],
    //     withdrawalQuantity: WithrawalSeriesData[index],
    //     value: dischargePercentageSeriesData[index],
    //   })),
    // });

    series.push({
      name: "Water Consumed %",
      type: "line",
      yAxisIndex: 1, // Secondary y-axis for percentage
      label: { show: true, formatter: `{c}%` },
      data: xAxisLabels.map((_, index) => ({
        dischargeQuantity: dischargeSeriesData[index],
        withdrawalQuantity: WithrawalSeriesData[index],
        consumedQuantity: WithrawalSeriesData[index]-dischargeSeriesData[index],
        value:(((WithrawalSeriesData[index]-dischargeSeriesData[index])/WithrawalSeriesData[index])*100).toFixed(),
      })),
    });
  }
  return { xAxisLabels, series };
}

function processDataToLablesAndDataSetsPie(data) {
 
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);

  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }
  let dischargePercentageSeriesData = [];
  let dischargeSeriesData = [];
  let WithrawalSeriesData = [];
  for (const legend of legends) {
    let seriesData = [];
    let withdrawal_quantity = [];
    let discharge_quantity_data = [];
    let dischargePercentageData = [];
    for (const timeKey of xAxisLabels) {
      if (data[timeKey][legend]) {
        seriesData.push(data[timeKey][legend].totalKeyQuantity.toFixed());
        withdrawal_quantity.push(data[timeKey][legend]?.withdrawal_quantity);
        discharge_quantity_data.push(
          data[timeKey][legend]?.discharge_quantity || 0
        );
        dischargePercentageData.push(
          isFinite(data[timeKey][legend]?.percentDischarge) &&
            !isNaN(data[timeKey][legend]?.percentDischarge)
            ? data[timeKey][legend]?.percentDischarge
            : 0
        );
      } else {
        seriesData.push(0); // Placeholder for missing data
        discharge_quantity_data.push(0); // Placeholder for missing consumed fuel data
        dischargePercentageData.push(0);
      }
    }
    dischargePercentageSeriesData = dischargePercentageData; // Store discharge percentages for the line chart
    WithrawalSeriesData = withdrawal_quantity;
    dischargeSeriesData = discharge_quantity_data;
    let totalKeyQuantitysByTimeKey = xAxisLabels.reduce((acc, timeKey) => {
      acc[timeKey] = Object.values(data[timeKey]).reduce((total, siteData) => {
        return total + (siteData.totalKeyQuantity || 0);
      }, 0);
      return acc;
    }, {});
    series.push({
      withdrawal_quantity,
      name: legend,
      type: "bar",
      stack: "total",
      barWidth: "20%",
      label: {
        show: true,
        position: legend.trim() === "Total" ? "top" : "", // Different label positions
        formatter: (params) => {
          const timeKeyIndex = params.dataIndex; // Current timeKey index
          const timeKey = xAxisLabels[timeKeyIndex]; // Get the current timeKey
          const totalKeyQuantityForTimeKey =
            totalKeyQuantitysByTimeKey[timeKey]; // Total emission across all sites for the current timeKey

          const value = Number(params.value); // Current block value

          // Calculate the percentage of the current block relative to the total emission for the timeKey
          let percentage = (
            (value / totalKeyQuantityForTimeKey) *
            100
          ).toFixed();

          // Handle edge cases for NaN, undefined, or 0
          if (isNaN(percentage) || !isFinite(percentage)) {
            percentage = 0;
          }

          // Show value or percentage based on legend name
          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        },
        fontSize: 10,
      },
      data: seriesData.map((value, index) => ({
        value,
        discharge_quantity: discharge_quantity_data[index],
        percentDischarge: dischargePercentageData[index], // Attach consumed fuel to each data point
      })),
    });
  }

  return { xAxisLabels, series };
}
const generateChartOption = (
  titleText,
  legendData,
  xAxisLabels,
  series,
  key
) => {
  
  return {
    color: [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
      "#D14A61",
      "#675BBA",
      "#F5994E",
      "#A0A7E6",
      "#7F9EC6",
      "#91C7AE",
      "#D48265",
      "#61A0A8",
      "#C4CCD3",
      "#D53A35",
      "#4E91D5",
      "#6B4A3E",
      "#CA8622",
      "#BDA29A",
      "#CC3C5D",
      "#00A29B",
      "#FF7F50",
      "#6495ED",
      "#FFD700",
      "#32CD32",
      "#FFA07A",
      "#20B2AA",
      "#87CEEB",
      "#778899",
      "#B0E0E6",
      "#FF6347",
      "#4682B4",
      "#8B0000",
      "#008080",
      "#FFD700",
      "#4B0082",
      "#DC143C",
      "#2E8B57",
      "#FF69B4",
      "#FF4500",
      "#808000",
      "#FF1493",
      "#00CED1",
      "#1E90FF",
      "#8A2BE2",
      "#A52A2A",
      "#5F9EA0",
      "#FF8C00",
      "#B22222",
      "#DAA520",
      "#228B22",
      "#FFDAB9",
      "#FF00FF",
      "#7FFF00",
      "#FA8072",
      "#F08080",
      "#FFB6C1",
      "#E0FFFF",
      "#FFE4E1",
      "#7B68EE",
      "#D2691E",
      "#9ACD32",
      "#B8860B",
      "#9932CC",
      "#00FF7F",
      "#40E0D0",
      "#FFFAF0",
      "#8FBC8F",
      "#BDB76B",
      "#FFDEAD",
      "#7CFC00",
      "#F0E68C",
      "#E6E6FA",
      "#FFF0F5",
      "#00FF00",
      "#F4A460",
      "#D8BFD8",
      "#FF7F50",
      "#FF6347",
      "#FFDAB9",
      "#FFE4B5",
      "#FFFACD",
      "#00FA9A",
      "#DDA0DD",
      "#FFF5EE",
      "#B0C4DE",
      "#B0E0E6",
      "#87CEEB",
      "#00BFFF",
      "#FFD700",
      "#32CD32",
      "#FFA500",
      "#CD5C5C",
      "#D3D3D3",
      "#4682B4",
      "#FF00FF",
      "#00CED1",
      "#808080",
    ],

    title: {
      text: titleText,
      left: "center",
    },
    // label:{
    // show:true
    // },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: {
          show: true,
          readOnly: true,
          option: {
            lang: ['Quantity', 'Percentage'],
            contentToOption: (opt) => {
              const seriesName = opt.seriesName;
              const data = opt.data;
              const value = opt.value;
              const dischargeQuantity = data?.dischargeQuantity || 0;
              const withdrawalQuantity = data?.withdrawalQuantity || 0;
              const consumedQuantity = data?.consumedQuantity || 0;
              const waterDisposalPercentage = (dischargeQuantity / withdrawalQuantity) * 100 || 0;
              const waterRecoveryPercentage = (consumedQuantity / withdrawalQuantity) * 100 || 0;

              if (seriesName === 'Water Discharged %') {
                return { value, lang: ['Quantity', 'Percentage'], content: `${value} Tonnes (${waterDisposalPercentage.toFixed(2)}%)` };
              } else if (seriesName === 'Water Consumed %') {
                return { value, lang: ['Quantity', 'Percentage'], content: `${value} Tonnes (${waterRecoveryPercentage.toFixed(2)}%)` };
              } else {
                return { value, lang: ['Quantity', 'Percentage'], content: `${value} Tonnes` };
              }
            },
          }
        },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    responsive: true,
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { seriesName, value, data } = params;

        let tooltipText;
        if (params.seriesType === "line") {
          if (params.seriesName === "Water Discharged %") {
            if (data) {
              tooltipText = `${seriesName} <br/>Discharged Quantity: ${data.dischargeQuantity?.toFixed()} Tonnes`;
            } else {
              console.warn(
                `No data found for Water Discharged percentage at site ${params.name}.`
              );
            }
          } else if (params.seriesName === "Water Consumed %") {
            tooltipText = `${seriesName} <br/>Consumed Quantity: ${data.consumedQuantity?.toFixed()} Tonnes`;
          } else {
            tooltipText = `${seriesName} <br/>${formatText(
              key
            )}: ${value} Tonnes`;
          }
        } else {
          tooltipText = `${seriesName} <br/>${formatText(
            key
          )}: ${value} Tonnes`;
        }

        // Add consumed fuel data if available and relevant to the series
        // if (data && data.discharge_quantity) {
        //   tooltipText += `<br/> Discharge: ${data.discharge_quantity.toFixed()} Tonnes`;
        // }

        return tooltipText;
      },
    },
    legend: {
      top: "bottom",
      padding: [30, 10, 10, 10], // top, right, bottom, left
      itemGap: 10,
    },
    labelLine: {
      show: true,
    },
    grid: {
      left: 60,
      right: 60,
      top: 50,
      bottom: series?.length > 6 ? series.length * 10 - 10 : 60, // Adjust bottom space based on series length
      containLabel: false,
    },
    yAxis: [
      {
        type: "value",
        name: "(in Tonnes)",
        axisLabel: {
          formatter: (value) => {
            if (value >= 1000000) {
              return (value / 1000000).toFixed() + "M ";
            } else if (value >= 1000) {
              return (value / 1000).toFixed() + "K ";
            } else {
              return (
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "
              );
            }
          },
        },
      },
      {
        type: "value",
        // name: "Water Discharged %",
        position: "right",
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          show: false,
        },
        // max: 100,
      },
      {
        type: "value",
        // name: "Water Consumed %",
        position: "right",
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          show: false,
        },
        // max: 100,
      },
    ],
    xAxis: {
      type: "category",
      data: xAxisLabels,
    },
    series: series.map((item) => ({
      ...item,
      data: item.data.map((dataPoint) => ({
        ...dataPoint,
        [key]: dataPoint[key] || 0, // Ensure discharge_quantity is included
      })),
    })),
  };
};
const generatePieChartOptions = (
  text,
  filteredData,
  breaktype,
  currentFinancialYear,
  key
) => {
  
  const { series } = processDataToLablesAndDataSetsPie(
    processData(
      filteredData,
      "Year",
      breaktype,
      Number(currentFinancialYear),
      key
    )
  );

  // console.log({series,breaktype})
  let pieChartData = series
    ? series.map((dataItem) => ({
        value: dataItem?.data
          ?.reduce((acc, curr) => acc + Number(curr?.value), 0)
          .toFixed(),
        name: dataItem.name,
      }))
    : [];

  pieChartData = pieChartData.filter(dataPoint => dataPoint.value !== '0');

  return {
    responsive: true,
   
    title: {
      text:
        key === "withdrawal_quantity"
          ? `Water Withdrawal ${text}`
          : key === "consumed_quantity"
          ? `Water Consumed ${text}`
          : key === "discharge_quantity"
          ? `Water Discharge ${text}`
          : "",
      left: "center",
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        let tooltipText = "";
        tooltipText += `${params.name}<br/>${formatText(key)}: ${parseFloat(
          params.value
        ).toFixed()} Tonnes`;

        return tooltipText;
      },
    },
    legend: {
      // type: 'scroll', // Enable scrollable legend
      // orient: 'horizontal', // Orient legend vertically
      // right: 10,
      // top: 20,
      // bottom: 20,
      // type: 'scroll', // Enable scrollable legend
      orient: "horizontal", // Orient legend vertically
      bottom: "5%",
      padding: [0, 0, 0, 0],
      top: "bottom",
      formatter: (name) => {
        // Shorten the legend name if it's too long
        // return name.length > 15 ? `${name.slice(0, 15)}...` : name;
        return name;
      },
      pageIconSize: [12, 12], // Set the size of the page icons
      pageTextStyle: {
        fontSize: 12,
      },
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "50%"], // Center the pie chart
        avoidLabelOverlap: false,
        label: {
          position: "inside",
          show: true,
          fontSize: 10,
          fontWeight: "bold",
          formatter: (params) => {
            return `${params?.percent?.toFixed()}%`;
          },
        },
        labelLine: {
          show: true,
        },
        data: pieChartData,
      },
    ],
  };
};

const generateStackedChartOptions = (
  chartData,
  currentFinancialYear,
  typeBreakdown,
  legends,
  xAxisData,
  series,
  key
) => {

  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  const yAxis = [
    {
      type: "value",
      name: "(in Tonnes)",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1000000) {
            return (value / 1000000).toFixed() + "M ";
          } else if (value >= 1000) {
            return (value / 1000).toFixed() + "K ";
          } else {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "";
          }
        },
      },
    },
    {
      type: "value",
      max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
      splitLine: {
        show: false,
      },
    },
  ];
  const text = "Facilitywise";
  return {
    title: {
      text:
        key === "withdrawal_quantity"
          ? `${text} Water Withdrawal `
          : key === "consumed_quantity"
          ? `${text} Water Consumed `
          : key === "discharge_quantity"
          ? `${text} Water Discharged `
          : "",
      left: "center",
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },

    tooltip: {
      trigger: "item",
      formatter: function (params) {
        let tooltipContent = `${params.seriesName}<br/>`;

        // Bar chart (emissions breakdown)
        if (params.seriesType === "bar") {
          tooltipContent += `${formatText(
            key
          )}: ${params.value.toFixed()} Tonnes<br/>`;

          const entry = currentYearChartData?.find(
            (item) =>
              item[typeBreakdown] === params.seriesName &&
              item.site === params.name
          );
          if (entry) {
            const waterWithrawal = entry["withdrawal_quantity"];
            // tooltipContent += `Water Withrawal: ${waterWithrawal.toFixed()} tonns<br/>`;
          } else {
            console.warn(
              `No data found for ${params.seriesName} at site ${params.name}.`
            );
          }
        }
        // Line chart (Water Discharged percentage)
        else if (
          params.seriesType === "line" &&
          (params.seriesName === "Water Discharged %" ||
            params.seriesName === "Water Consumed %")
        ) {
          if (params.seriesName === "Water Consumed %") {
            tooltipContent += `Consumed Quantity:  ${params?.data?.consumed_quantity}tonnes<br/>`;
          } else {
            tooltipContent += `Discharge Quantity: ${params?.data?.discharge_quantity} tonnes<br/>`;
          }
        }

        return tooltipContent;
      },
    },
    legend: {
      type: "scroll",
      data: [...legends, "Water Discharged %", "Water Consumed %"], // Include legend for line series
      top: "bottom",
    },
    grid: {
      left: 60,
      right: 60,
      top: 50,
      // bottom: series?.length > 6 ? series.length * 10 - 10 : 60, // Adjust bottom space based on series length
      containLabel: false,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    label: {
      show: true,
      
      fontSize: 10,
      fontWeight: "bold",
    },
    yAxis: yAxis,
    series: series.map((s) => {
      if (s.type === "line") {
        return {
          ...s,
          yAxisIndex: 1, // Assign to the secondary y-axis for percentage
          name:
            s.name === "Water Discharged Quantity"
              ? "Water Discharged %"
              : s.name === "Water Consumed Quantity"
              ? "Water Consumed %"
              : s.name,
        };
      }
      return s;
    }),
  };
};

function calculateWaterStatistics(data, currentYear) {
  const aggregateBySite = (data) => {
    const aggregatedData = {};
    data.forEach((entry) => {
      const { site, withdrawal_quantity, discharge_quantity } = entry;

      if (!aggregatedData[site]) {
        aggregatedData[site] = {
          waterWithdrawal: 0,
          waterDischarge: 0,
          waterConsumed: 0,
        };
      }

      aggregatedData[site].waterWithdrawal += withdrawal_quantity;
      aggregatedData[site].waterDischarge += discharge_quantity;
    });

    // Calculate waterConsumed for each site
    Object.keys(aggregatedData).forEach((site) => {
      const { waterWithdrawal, waterDischarge } = aggregatedData[site];
      aggregatedData[site].waterConsumed = waterWithdrawal - waterDischarge;
    });

    return aggregatedData;
  };

  const calculatePercentage = (aggregatedData) => {
    const percentages = {};
    Object.keys(aggregatedData).forEach((site) => {
      const { waterWithdrawal, waterDischarge, waterConsumed } = aggregatedData[site];

      const waterDischargePercentage =
        waterWithdrawal > 0 ? (waterDischarge / waterWithdrawal) * 100 : 0;
      const waterConsumedPercentage =
        waterWithdrawal > 0 ? (waterConsumed / waterWithdrawal) * 100 : 0;

      percentages[site] = {
        waterWithdrawal,
        waterDischarge,
        waterConsumed,
        waterDischargePercentage: waterDischargePercentage.toFixed(),
        waterConsumedPercentage: waterConsumedPercentage.toFixed(),
      };
    });
    return percentages;
  };

  const calculateYearlyChange = (currentYearData, previousYearData) => {
    Object.keys(currentYearData).forEach((site) => {
      const current = currentYearData[site];
      const previous = previousYearData[site] || {
        waterDischargePercentage: 0,
        waterConsumedPercentage: 0,
      };

      const calculateChange = (currentValue, previousValue) => {
        if (
          previousValue === 0 ||
          isNaN(currentValue) ||
          !isFinite(currentValue)
        ) {
          return 0;
        }
        return ((currentValue - previousValue) / previousValue) * 100;
      };

      const waterDischargePercentageChange = calculateChange(
        parseFloat(current.waterDischargePercentage),
        parseFloat(previous.waterDischargePercentage)
      );

      const waterConsumedPercentageChange = calculateChange(
        parseFloat(current.waterConsumedPercentage),
        parseFloat(previous.waterConsumedPercentage)
      );

      currentYearData[site].waterDischargePercentageChange =
        waterDischargePercentageChange.toFixed();
      currentYearData[site].waterConsumedPercentageChange =
        waterConsumedPercentageChange.toFixed();
    });
    return currentYearData;
  };

  const currentYearData = aggregateBySite(
    data.filter((entry) => entry.year === currentYear)
  );
  const previousYearData = aggregateBySite(
    data.filter((entry) => entry.year === currentYear - 1)
  );

  const currentYearPercentages = calculatePercentage(currentYearData);
  const previousYearPercentages = calculatePercentage(previousYearData);

  return calculateYearlyChange(currentYearPercentages, previousYearPercentages);
}

const calculateAggregateWaterMetrics = (data, currentYear) => {
  // Aggregate data for all sites in a year
  const aggregateYearData = (data, year) => {
    let totalWithdrawal = 0;
    let totalDischarge = 0;
    let totalRevenue = 0;

    data.forEach((entry) => {
      if (entry.year === year) {
        totalWithdrawal += entry.withdrawal_quantity || 0;
        totalDischarge += entry.discharge_quantity || 0;
        totalRevenue += entry.revenue || 0;
      }
    });

    const totalConsumed = totalWithdrawal - totalDischarge;

    return {
      totalWithdrawal,
      totalDischarge,
      totalConsumed,
      totalRevenue,
    };
  };

  // Calculate percentages and water intensity for the aggregated data
  const calculateMetrics = ({
    totalWithdrawal,
    totalDischarge,
    totalConsumed,
    totalRevenue,
  }) => {
    const dischargePercentage =
      totalWithdrawal > 0 ? (totalDischarge / totalWithdrawal) * 100 : 0;
    const consumedPercentage =
      totalWithdrawal > 0 ? (totalConsumed / totalWithdrawal) * 100 : 0;
    const waterIntensity =
      totalWithdrawal > 0 && totalRevenue > 0
        ? totalWithdrawal / (totalRevenue / 100000)
        : 0;

    return {
      dischargePercentage: dischargePercentage.toFixed(),
      consumedPercentage: consumedPercentage.toFixed(),
      waterIntensity: waterIntensity.toFixed(),
    };
  };

  // Calculate percentage change
  const calculateChange = (current, previous) => {
    if (previous === 0 || isNaN(current) || !isFinite(current)) {
      return 0;
    }
    return (((current - previous) / previous) * 100).toFixed();
  };

  // Aggregating the data for the current and previous year
  const currentYearData = aggregateYearData(data, currentYear);
  const previousYearData = aggregateYearData(data, currentYear - 1);

  // Calculate metrics for the current and previous year
  const currentMetrics = calculateMetrics(currentYearData);
  const previousMetrics = calculateMetrics(previousYearData);

  // Calculate year-over-year changes
  const changeWithdrawal = calculateChange(
    currentYearData.totalWithdrawal,
    previousYearData.totalWithdrawal
  );
  const changeDischarge = calculateChange(
    currentMetrics.dischargePercentage,
    previousMetrics.dischargePercentage
  );
  const changeConsumed = calculateChange(
    currentMetrics.consumedPercentage,
    previousMetrics.consumedPercentage
  );
  const changeIntensity = calculateChange(
    currentMetrics.waterIntensity,
    previousMetrics.waterIntensity
  );

  return {
    totalWithdrawal: currentYearData.totalWithdrawal,
    totalDischarge: currentYearData.totalDischarge,
    totalConsumed: currentYearData.totalConsumed,
    totalRevenue: currentYearData.totalRevenue,
    dischargePercentage: currentMetrics.dischargePercentage,
    consumedPercentage: currentMetrics.consumedPercentage,
    waterIntensity: currentMetrics.waterIntensity,
    changes: {
      withdrawalChange: isFinite(changeWithdrawal) ? changeWithdrawal : 0,
      dischargePercentageChange: isFinite(changeDischarge) ? changeDischarge : 0,
      consumedPercentageChange: isFinite(changeConsumed)
        ? changeConsumed
        : 0,
      intensityChange: isFinite(changeIntensity) ? changeIntensity : 0,
    },
  };
};

export const generateSingleCandleChartOptions = (
  chartData,
  currentFinancialYear
) => {
 
  // Filter data for the current financial year
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  // Calculate total emissions for the current year
  const totalQuantity = currentYearChartData.reduce(
    (total, item) => total + Number(item.withdrawal_quantity),
    0
  );

  const seriesData = currentYearChartData.reduce((acc, item) => {
    const material = item.water_type;
    const waterWithrawal = Number(item.withdrawal_quantity);
    const value = waterWithrawal ? (waterWithrawal / totalQuantity) * 100 : 0;

    const existingMaterial = acc.find((entry) => entry.name === material);
    if (existingMaterial) {
      existingMaterial.waterWithrawal += waterWithrawal;
      existingMaterial.value += value;
    } else {
      acc.push({
        name: material,
        waterWithrawal,
        value,
      });
    }

    return acc;
  }, []);
  const series = seriesData.map((item) => ({
    name: item.name,
    type: "bar",
    stack: "total",
    label: {
      show: true,
      formatter: "{c}%", // Show percentage inside the bar
    },
    data: [item.value.toFixed()],
  }));
  // Chart Options
  return {
    title: { text: "Water Withdrawal by Water Type", left: "center" },
    barWidth: 60,
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        const materialData = seriesData.find((m) => m.name === params.seriesName);
        if (materialData) {
          return `
            <b>${params.seriesName}</b><br/>
            Water Withrawal: ${materialData.waterWithrawal?.toFixed()} tonnes
          `;
        }
        return `Data not found for ${params.name}`;
      },
    },
    legend: {
      // data: seriesData.map((s) => s.name), // Legend based on site names
      top: "bottom",
    },
    label: {
      show: true,
      
      fontSize: 10,
      fontWeight: "bold",
    },
    // grid: {
    //   left: "1%",
    //   right: "1%",
    //   bottom: "30%",
    //   containLabel: true,
    // },
    xAxis: {
      type: "value",
      // name: "Water Material Share (%)",
    },
    yAxis: {
      type: "category",
      data: ["Water Materials"], // Single bar with no y-axis label
      // position: "top",
       axisLabel: {
    rotate: 90, // Rotate the text 90 degrees
  },


    },
    series: series,
  };
};

export {
  processData,
  generateStackedChartOptions,
  processDataToLablesAndDataSets,
  currentYearLastYearEmissionDetail,
  generatePieChartOptions,
  generateChartOption,
  calculateWaterStatistics,
  calculateAggregateWaterMetrics,
};

// fuel cases validation

export function validateFuelData(data) {
  // Validate Case 1: quantity * carbon_content_of_fuel
  // const case1Valid = (data.quantity && data.carbon_content_of_fuel);

  // // Validate Case 2: quantity * heat_content_of_fuel * ef_of_fuel
  // const case2Valid = (data.quantity && data.heat_content_of_fuel && data.ef_of_fuel);

  // // Validate Case 3: quantity * ef_of_fuel
  // const case3Valid = (data.quantity && data.ef_of_fuel);

  // // // Validate Case 4: paid_amount * ef_of_fuel
  // const case4Valid = (data.amount_paid && data.ef_of_fuel);

  // Return true if any of the cases are valid and no extra fields are present
  return true;
}
// Electricity cases validation

export function validateElectricityData(data) {
  // Validate Case 1: quantity * ef_of_fuel
  const case1Valid = data.quantity && data.ef_of_fuel;

  // Return true if any of the cases are valid and no extra fields are present
  return case1Valid;
}

export function removeEmptyProperties(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
}
