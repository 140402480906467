import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../../customHooks/useApi";
import { filterDataByKeys, getLatestYearPreviousYearObjects, ProcessHealthAndSafetyData } from "../../function/DataProcessFunction";
import { fetchSocialEmpDemographicInputs, fetchSocialTrainingInputs } from "../../../../features/social/socialThunk";

// Sample Data Structure (replace this with real dynamic data as needed)

const HealthSafetySkillTable = ({question }) => {
  const { socialEmpDemographicsRecords, socialTrainingRecords } =
  useSelector((state) => state.social);
  const { reportYear} =
  useSelector((state) => state.BRSR.FinancialYearFilter);
const dispatch = useDispatch();
const api = useApi();
const { currentYear, previousYear } = getLatestYearPreviousYearObjects(
  socialEmpDemographicsRecords,reportYear
);
const { currentYear:currentYearTrainingData, previousYear:previousYearTrainingData } = getLatestYearPreviousYearObjects(
  socialTrainingRecords,reportYear
);
const data=ProcessHealthAndSafetyData(  currentYear, previousYear ,previousYearTrainingData,currentYearTrainingData)
  useEffect(()=>{
dispatch(fetchSocialTrainingInputs({api,params:{}}))
// dispatch(fetchSocialEmpDemographicInputs({api,params:{}}))
  },[])
  return (
    <div className="overflow-auto">
      <table className="min-w-full border-collapse border border-gray-300  text-center text-gray-700">
        <thead>
        <tr className="bg-gray-300">
          <th colSpan={12} className="border border-gray-300 px-4 py-2 text-start text-base font-semibold ">
          {question}       </th>
        </tr>
          <tr className="bg-gray-100 text-sm font-semibold text-gray-700">
            <th rowSpan="3" className="border border-gray-300 p-2">Category</th>
            <th colSpan="5" className="border border-gray-300 p-2"> Current Financial Year</th>
            <th colSpan="5" className="border border-gray-300 p-2"> Previous Financial Year</th>
            {/* <th rowSpan="3" className="border border-gray-300 p-2">MATERIALITY</th> */}
          </tr>
          <tr className="bg-gray-100 text-sm font-semibold text-gray-700">
            <th rowSpan="2" className="border border-gray-300 p-2">Total (A)</th>
            <th colSpan="2" className="border border-gray-300 p-2">On Health and Safety measures</th>
            <th colSpan="2" className="border border-gray-300 p-2">On Skill upgradation</th>
            <th rowSpan="2" className="border border-gray-300 p-2">Total (D)</th>
            <th colSpan="2" className="border border-gray-300 p-2">On Health and Safety measures</th>
            <th colSpan="2" className="border border-gray-300 p-2">On Skill upgradation</th>
          </tr>
          <tr className="bg-gray-100 text-sm font-semibold text-gray-700">
            {["No.", "%", "No.", "%", "No.", "%", "No.", "%"].map((header, index) => (
              <th key={index} className="border border-gray-300 p-2">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Employees Section */}
          <tr>
            <td colSpan="12" className="border border-gray-300 p-2 font-semibold bg-gray-50">Employees</td>
          </tr>
          {["male", "female", "total"].map((category) => (
            <TableRow
              key={`employees-${category}`}
              category={category}
              data={data.employees}
              materiality="High, Sector Dependent"
              totals={data.employees.totals}
            />
          ))}

          {/* Workers Section */}
          <tr>
            <td colSpan="12" className="border border-gray-300 p-2 font-semibold bg-gray-50">Workers</td>
          </tr>
          {["male", "female", "total"].map((category) => (
            <TableRow
              key={`workers-${category}`}
              category={category}
              data={data.workers}
              materiality="High, Sector Dependent"
              totals={data.workers.totals}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Reusable row component
const TableRow = ({ category, data, materiality, totals }) => {
  return (
    <tr className="text-sm text-gray-700">
      <td className="border border-gray-300 p-2 capitalize">{category}</td>
      {/* Current Year Health & Safety */}
      <td className="border border-gray-300 p-2">{totals.totalA}</td>
      <td className="border border-gray-300 p-2">{data.currentYear[category].healthSafety.number}</td>
      <td className="border border-gray-300 p-2">{data.currentYear[category].healthSafety.percentage}</td>
      {/* Current Year Skill Upgrade */}
      <td className="border border-gray-300 p-2">{data.currentYear[category].skillUpgrade.number}</td>
      <td className="border border-gray-300 p-2">{data.currentYear[category].skillUpgrade.percentage}</td>
      {/* Total (A) */}
      {/* Previous Year Health & Safety */}
      <td className="border border-gray-300 p-2">{totals.totalD}</td>
      <td className="border border-gray-300 p-2">{data.previousYear[category].healthSafety.number}</td>
      <td className="border border-gray-300 p-2">{data.previousYear[category].healthSafety.percentage}</td>
      {/* Previous Year Skill Upgrade */}
      <td className="border border-gray-300 p-2">{data.previousYear[category].skillUpgrade.number}</td>
      <td className="border border-gray-300 p-2">{data.previousYear[category].skillUpgrade.percentage}</td>
      {/* Total (D) */}
      {/* Materiality */}
      {/* <td className="border border-gray-300 p-2">{materiality}</td> */}
    </tr>
  );
};

export default HealthSafetySkillTable;
