import { createSlice } from "@reduxjs/toolkit";

// Assume you have thunks for login and logout (similar to the previous example)
const initialFormValue = {
  discharge_period_start: "",
  discharge_period_end: "",
  discharge_site_id: "",

  discharge_quantity: "",
  discharge_unit_id: "",
  discharge_evidence: null,
};
const WaterAndEffluentsSlice = createSlice({
  name: "WaterAndEffluents",
  initialState: {
    WaterAndEffluentsRecordsDischarged: [
     
    
    ],
    WaterAndEffluentsWithdrawalForm: {},
    WaterAndEffluentsDischargedForm: {},
    WaterAndEffluentsDischargedFormCopyEdit: {},
    WaterAndEffluentsWithdrawalInputRecords: [
      
     
    ],
    siteDataForFilter: [],
    waterSourceData: [],
    waterType: [],
    waterDischargeDestination: [],
    waterDischargeQuality: [],
    formValues: [initialFormValue],

    filters: null,
    sorting: null,
    treatmentMechanism: [],
    wasteTreatmentMapping: [],
    WithdrawaldQuantityByIndex: {},
    WaterAndEffluentsRecordType: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
    totalCount: 10,
    siteData: [],
    StoreDischarged: [],
    unitData: [],
    currencyData: [],
  },
  reducers: {
    setWaterAndEffluentsRecordsDischarged: (state, action) => {
      state.WaterAndEffluentsRecordsDischarged = action.payload;
    },
    setWaterAndEffluentsWithdrawaldInputRecords: (state, action) => {
      state.WaterAndEffluentsWithdrawalInputRecords = action.payload;
    },
    setWaterAndEffluentsWithdrawalForm: (state, action) => {
      return {
        ...state,
        WaterAndEffluentsWithdrawalForm: {
          ...state.WaterAndEffluentsWithdrawalForm,
          ...action.payload,
        },
      };
    },
    setWaterAndEffluentsDischargedForm: (state, action) => {
      return {
        ...state,
        WaterAndEffluentsDischargedForm: {
          ...state.WaterAndEffluentsDischargedForm,
          ...action.payload,
        },
      };
    },
    setWaterAndEffluentsDischargedFormCopyEdit: (state, action) => {
      return {
        ...state,
        WaterAndEffluentsDischargedFormCopyEdit: {
          ...state.WaterAndEffluentsDischargedFormCopyEdit,
          ...action.payload,
        },
      };
    },
    resetWaterAndEffluentsForm: (state, action) => {
      state.WaterAndEffluentsDischargedForm = {};
      state.WaterAndEffluentsWithdrawalForm = {};
      state.formValues = [initialFormValue];
    },
    resetWaterAndEffluentsDischargedForme3Category5Form: (state) => {
      state.WaterAndEffluentsDischargedForm = {};
    },
    setEvidence: (state, action) => {
      const { type, evidence } = action.payload;
      if (type === "waterWithdrawal") {
        state.WaterAndEffluentsWithdrawalForm.evidence = evidence;
      } else {
        state.WaterAndEffluentsDischargedForm.evidence = evidence;
      }
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setStoreDischarged: (state, action) => {
      state.StoreDischarged = action.payload;
    },
    setSiteDataForFilter: (state, action) => {
      state.siteDataForFilter = action.payload;
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setWaterType: (state, action) => {
      state.waterType = action.payload;
    },
    setWaterSourceData: (state, action) => {
      state.waterSourceData = action.payload;
    },
    setWaterDischargeQuality: (state, action) => {
      state.waterDischargeQuality = action.payload;
    },
    setDischargeDestinationData: (state, action) => {
      state.waterDischargeDestination = action.payload;
    },
    setFormValues: (state, action) => {
      state.formValues = action.payload;
    },
    setWithdrawaldQuantityByIndex: (state, action) => {
      state.WithdrawaldQuantityByIndex = action.payload;
    },

    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },

    setUnitData: (state, action) => {
      state.unitData = action.payload;
    },

    // setdeletes3c9Data: (state, action) => {
    //   state.WaterAndEffluentsWithdrawaldInputRecords =
    //     state.WaterAndEffluentsWithdrawaldInputRecords.filter(
    //       (i) => i.id != action.payload
    //     );
    // },
  },
});

export const {
  setfiltredwasteMaterial,
  setWaterAndEffluentsDischargedForm,
  setWaterAndEffluentsDischargedFormCopyEdit,
  resetWaterAndEffluentsDischargedForme3Category5Form,
  setDischargeDestinationData,
  setWaterDischargeQuality,
  setWaterSourceData,
  setWaterType,
  setdeletes3c9Data,
  setFormValues,
  setwasteMaterial,
  setFilters,
  setStoreDischarged,
  setEvidence,
  setWaterAndEffluentsWithdrawalForm,
  setWithdrawaldQuantityByIndex,
  setSiteDataForFilter,
  setWaterAndEffluentsRecordsDischarged,
  setSorting,
  setCurrentPage,
  setItemsPerPage,

  setTotalCount,
  setTotalPages,
  

  setSiteData,
  setUnitData,

  resetWaterAndEffluentsForm,

  setWaterAndEffluentsWithdrawaldInputRecords,

  setWaterAndEffluentsCountryData,
  
  setSourceTypeData,
} = WaterAndEffluentsSlice.actions;
export default WaterAndEffluentsSlice.reducer;
