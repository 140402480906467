import React from 'react'

function WaterWithdrawalTable({tableData}) {

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead className="bg-gray-200 sticky top-0">
          <tr>
            <th className="px-4 py-2">Site</th>
            <th className="px-2 py-2">Withdrawal (tonnes)</th>
            <th className="px-2 py-2">Discharged (tonnes)</th>
            <th className="px-2 py-2">Consumed (tonnes)</th>
            <th className="px-2 py-2">Discharged %</th>
            <th className="px-2 py-2">Consumed %</th>
            <th className="px-2 py-2">Discharged % <br/>YOY Change </th>
            <th className="px-2 py-2">Consumed % <br/> YOY Change</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(tableData).map((key, index) => {
            const waterDischargePercentageChangeClass = tableData[key].waterDischargePercentageChange < 0 ? 'text-green-500' : 'text-red-500'
            const waterConsumedPercentageChangeClass = tableData[key].waterConsumedPercentageChange < 0 ? 'text-green-500' : 'text-red-500'
            return (
              <tr key={index}>
                <td className="px-4 py-2 border text-center overflow-ellipsis whitespace-nowrap">{key}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].waterWithdrawal?.toFixed()}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].waterDischarge?.toFixed()}</td>
                <td className="px-4 py-2 border text-center">{tableData[key]?.waterConsumed?.toFixed()}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].waterDischargePercentage}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].waterConsumedPercentage}</td>
                <td className={`px-4 py-2 border text-center ${waterDischargePercentageChangeClass}`}>{tableData[key].waterDischargePercentageChange}</td>
                <td className={`px-4 py-2 border text-center ${waterConsumedPercentageChangeClass}`}>{tableData[key].waterConsumedPercentageChange}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default WaterWithdrawalTable
