import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { setWaterAndEffluentsWithdrawalForm } from "../../features/waterandeffluents/WaterandEffluentsSlice";
import useApi from "../../customHooks/useApi"
import { WaterandEffluentsUploadEvidence } from "../../features/waterandeffluents/WaterandEffluentsThunk";


const WaterandEffluentsFormWithdrawalEditAndCopyForm = ({
  actionType,setActiveTab
}) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [WaterDischargeQuantitySum, setWaterDischargeQuantitySum] = useState(0);
  const {
    siteData,
    WaterAndEffluentsRecordsdischarge,
    unitData,
    waterType,
    waterTreatmentMapping,waterSourceData
  } = useSelector((state) => state.WaterAndEffluents);
  const formValue = useSelector(
    (state) => state.WaterAndEffluents.WaterAndEffluentsWithdrawalForm
  );
  
  const loading = useSelector((state) => state.common.isLoading);
  

  const [file, setFile] = useState(null);
  

  const handleFormChange = async (e) => {
    const { name, value, files } = e.target;
   
    if (name === "withdrawal_evidence") {
      const selectedFiles = files;
      try {
        const res = await dispatch(
          WaterandEffluentsUploadEvidence({ api, files: selectedFiles })
        );
        if (res?.payload?.path) {
          setFile(res.payload.path);
          dispatch(
            setWaterAndEffluentsWithdrawalForm({
              evidence: res.payload.path,
            })
          );
        } else {
          toast.error("Failed to upload evidence");
        }
      } catch (error) {
        console.error("File upload error:", error);
      }
    } else {
     
      const updatedValue = name === "water_source_id"
      ? waterSourceData.find((item) => parseInt(item.id )=== parseInt(value))?.water_source || value
      : value;

    const updatePayload = name === "water_source_id"
      ? { withdrawal_water_source: updatedValue, [name]: value }
      : { [name]: value };
      const updatedWaterType = name === "water_type_id"
          ? waterType.find((item) => item.id == value)?.water_type || value
          : value;
  
        const updateWaterTypePayload = name === "water_type_id"
          ? { withdrawal_water_type: updatedWaterType, [name]: value }
          : { [name]: value };
      const updatedFormData = { ...formValue, ...updatePayload,...updateWaterTypePayload };
     
      dispatch(setWaterAndEffluentsWithdrawalForm(updatedFormData));
    }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector("#purchase-WaterAndEffluents-form");

    if (form.checkValidity()) {
      if (WaterDischargeQuantitySum > formValue.withdrawal_quantity) {
        toast.error("discharge quantity exceeds produced quantity", {
          theme: "dark",
        });
        return;
      }
      if (actionType === "edit") {
        setActiveTab("waterDischarge")
 
      } else if (actionType === "copy") {
        setActiveTab("waterDischarge")
      }

    } else {
      form.querySelector('input[type="submit"]').click();
    }
  };

  useEffect(() => {
    if (WaterAndEffluentsRecordsdischarge) {
     
      const dischargeAllrest = WaterAndEffluentsRecordsdischarge?.filter(
        (i) => Number(i.id) === Number(formValue.id)
      );
     
      setWaterDischargeQuantitySum(
        dischargeAllrest?.reduce(
          (acc, curr) => acc + Number(curr.discharge_quantity),
          0
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
 
  }, [formValue.water_source_id, waterTreatmentMapping]);

  return (
    <>
      <div className="flex flex-col justify-center items-center rounded-t-md text-xs">
        <form
          id="purchase-WaterAndEffluents-form"
          className="flex flex-col w-full py-4"
          style={{
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "auto",
          }}
          onSubmit={handlePurchaseFormSubmit}
        >
          <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
            <div className="col-span-1 flex flex-col">
              <label htmlFor="withdrawal_period_start" className="text-xs py-1.5">
                Period Start<span className="text-red-500">*</span>
              </label>
              <input
                max={new Date().toISOString().split("T")[0]}
                type="date"
                name="withdrawal_period_start"
                id="withdrawal_period_start"
                value={formValue.withdrawal_period_start || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="withdrawal_period_end" className="text-xs py-1.5">
                Period End<span className="text-red-500">*</span>
              </label>
              <input
                max={new Date().toISOString().split("T")[0]}
                type="date"
                name="withdrawal_period_end"
                id="withdrawal_period_end"
                value={formValue.withdrawal_period_end || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                min={formValue.withdrawal_period_start || ""}
                required
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="withdrawal_site_id" className="text-xs py-1.5">
                Site<span className="text-red-500">*</span>
              </label>
              <select
                name="withdrawal_site_id"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.withdrawal_site_id || ""}
              >
                <option value="" disabled>
                  Choose the site
                </option>
                {siteData &&
                  siteData.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.site}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-1 flex flex-col">
              <label
                htmlFor="water_source"
                className="text-xs py-1.5"
              >
                Water Source<span className="text-red-500">*</span>
              </label>
              <select
                name="water_source_id"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.water_source_id || ""}
              >
                <option value="" disabled>
                  Choose the water source
                </option>
                {waterSourceData &&
                  waterSourceData.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.water_source}
                    </option>
                  ))}
              </select>
            </div>
           
            
            <div className="col-span-1 flex flex-col">
              <label htmlFor="water_type_id" className="text-xs py-1.5">
                Water Type <span className="text-red-500">*</span>
              </label>
              <select
                name="water_type_id"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.water_type_id || ""}
              >
                <option value="" disabled>
                  Choose the water type
                </option>
                {waterType &&
                  waterType.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.water_type}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="withdrawal_quantity" className="text-xs py-1.5">
              Withdrawal Quantity
              </label>
              <input
                type="number"
                name="withdrawal_quantity"
                value={formValue.withdrawal_quantity || ""}
                onChange={(e) => {
                
                  handleFormChange(e);
                }}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                placeholder="Type the quantity"
                min={WaterDischargeQuantitySum}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="withdrawal_unit_id" className="text-xs py-1.5">
                Unit
              </label>
              <select
                name="withdrawal_unit_id"
                value={formValue.withdrawal_unit_id || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
              >
                <option value="" disabled>
                  Choose the unit
                </option>
                {unitData &&
                  unitData.map((unit, index) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unit}
                    </option>
                  ))}
              </select>
            </div>

           
         <div className=" flex flex-col">
        <label htmlFor={`withdrawal_evidence`} className="text-xs py-1.5 flex flex-col">
          <span>Evidence </span> 
          {formValue.evidence ? (
            <p className="text-green-600 mt-1 underline cursor-pointer">
              Evidence Uploaded
            </p>
          ) : (
           ""
          )}
        </label>
        <input
          // required={!formValue.evidence}
          type="file"
          name="withdrawal_evidence"
          id={`withdrawal_evidence`}
          multiple
          accept=".jpg, .jpeg, .png, .pdf, .zip"
          onChange={handleFormChange}
          className={`appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none ${formValue.evidence ? 'hidden' : ''}`}
        />
      </div>
          </div>

          <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
           
            <div className="w-1/6 flex flex-col">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-6 text-white text-sm ${
                  loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                } rounded-md`}
              >
                Save and Continue
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* </div> */}
    </>
  );
};

export default WaterandEffluentsFormWithdrawalEditAndCopyForm;
