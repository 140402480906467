export function aggregateDataByEmploymentType(data, employmentType) {
    // Filter data based on the provided employment type
    const filteredData = data.filter(item => item.employee_type === employmentType);
  
    // Initialize an empty result object
    const aggregatedData = {};
  
    // Aggregate data by financial_year, site, employement_category, and gender
    filteredData.forEach(item => {
      const { financial_year, employement_category, gender, no_of_employees, site = "Overall" } = item;
  
      // Initialize the first level (financial year)
      if (!aggregatedData[financial_year]) {
        aggregatedData[financial_year] = {};
      }
      
      // Initialize the second level (site)
      if (!aggregatedData[financial_year][site]) {
        aggregatedData[financial_year][site] = {};
      }
     
  
      // Initialize the third level (employee type)
      if (!aggregatedData[financial_year][site][employement_category]) {
        aggregatedData[financial_year][site][employement_category] = {};
      }
  
      // Initialize the fourth level (gender)
      if (!aggregatedData[financial_year][site][employement_category][gender]) {
        aggregatedData[financial_year][site][employement_category][gender] = 0;
      }
  
      // Sum up the number of employees
      aggregatedData[financial_year][site][employement_category][gender] += parseInt(no_of_employees, 10);
    });
  
    return aggregatedData;
}

  export function transformApiData(data,employmentType) {
    const filteredData = data.filter(item => item.employement_category === employmentType);
  
    const structuredData={}
    filteredData.forEach(entry => {
    const { financial_year, gender } = entry;

    // Set up base structure if financial year doesn't exist
    if (!structuredData[financial_year]) {
      structuredData[financial_year] = {
        // financialYear: financial_year,
        life_insurance: { male: "", female: "", other: "", 'not disclosed': "" },
        disability_coverage: { male: "", female: "", other: "", 'not disclosed': "" },
        stock_ownership: { male: "", female: "", other: "", 'not disclosed': "" },
        health_insurance: { male: "", female: "", other: "", 'not disclosed': "" },
        accident_insurance: { male: "", female: "", other: "", 'not disclosed': "" },
        maternity_benefits: { male: "", female: "", other: "", 'not disclosed': "" },
        paternity_benefits: { male: "", female: "", other: "", 'not disclosed': "" },
        day_care_facilities: { male: "", female: "", other: "", 'not disclosed': "" }
      };
    }

    // Map coverage fields to the structure based on gender
    const coverageTypes = [
      "life_insurance",
      "disability_coverage",
      "stock_ownership",
      "health_insurance",
      "accident_insurance",
      "maternity_benefits",
      "paternity_benefits",
      "day_care_facilities"
    ];

    coverageTypes.forEach(type => {
      const coverageKey = `${type}_coverage`; // Form key dynamically (e.g., "life_insurance_coverage")
      structuredData[financial_year][type][gender] = entry[coverageKey];
    });
  });

  return structuredData;
}


  // Function to populate data for Hiring and Turnover
export function populateDataHiringAndTurnover(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);

  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    const site = row.site || 'Overall';

    if (!formData[financialYear]) {
      formData[financialYear] = { financialYear: row.financial_year, sites: {} };
    }

    if (!formData[financialYear].sites[site]) {
      formData[financialYear].sites[site] = { male: {}, female: {}, other: {}, 'not disclosed': {} };
    }

    const genderKey =  row.gender;
    formData[financialYear].sites[site][genderKey][row.age_group] = {
      left: row.employees_left,
      recruits: row.new_recruits,
      total: row.total_employees
    };
  });

  return formData;
}


// Function to populate data for Parental Leave
export function populateParentalLeaveData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);

  filteredData.forEach(row => {
    const financialYear = row.financial_year;
    const site = row.site;
    
    if (!formData[financialYear]) {
      formData[financialYear] = {};
    }

    if (!formData[financialYear][site]) {
      formData[financialYear][site] = {
        employees_entitled_to_Parental_Leave: { male: 0, female: 0, other: 0, 'not disclosed': 0 },
        employees_took_Parental_Leave: { male: 0, female: 0, other: 0, 'not disclosed': 0 },
        employees_returned_after_Parental_Leave: { male: 0, female: 0, other: 0, 'not disclosed': 0 },
        employees_still_employed_12_months_after_return: { male: 0, female: 0, other: 0, 'not disclosed': 0 }
      };
    }

    const genderKey =row.gender;
    formData[financialYear][site].employees_entitled_to_Parental_Leave[genderKey] = row.employees_entitled_to_parental_leave;
    formData[financialYear][site].employees_took_Parental_Leave[genderKey] = row.employees_took_parental_leave;
    formData[financialYear][site].employees_returned_after_Parental_Leave[genderKey] = row.employees_returned_after_parental_leave;
    formData[financialYear][site].employees_still_employed_12_months_after_return[genderKey] = row.employees_still_employed_12_months_after_return;
  });
// console.log(formData)
  return formData;
}

// Function to populate data for Board and Management
export function populateBoardAndManagementData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = {
        financialYear: row.financial_year,
        board_of_directors: { male: 0, female: 0, other: 0, notDisclosed: 0 },
        key_management_personnel: { male: 0, female: 0, other: 0, notDisclosed: 0 }
      };
    }

    const genderKey = row.gender === 'not disclosed' ? 'notDisclosed' : row.gender;
    formData[financialYear].board_of_directors[genderKey] = row.board_of_directors;
    formData[financialYear].key_management_personnel[genderKey] = row.key_management_personnel;
  });

  return formData;
}

// Function to populate data for Retirement Benefits
export function populateRetirementBenefitData(data, employmentCategory) {
  const formData = {};
  // console.log(data,employmentCategory)
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = { financialYear: row.financial_year, benefits: {} };
    }

    formData[financialYear].benefits[row.benefit_type] = {
      employeesCovered: row.number_of_employees_covered,
      status: row.deducted_and_deposited_with_authority
    };
  });

  return formData;
}

// Function to populate data for Union Data
export function populateUnionData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = {'Number of Employees who are part of Association(s) or Union': {}, 'Number of Workers who are part of Association(s) or Union': {} };
    }

    formData[financialYear]['Number of Employees who are part of Association(s) or Union'][row.gender] = row.no_of_employees_in_union;
    formData[financialYear]['Number of Workers who are part of Association(s) or Union'][row.gender] = row.no_of_workers_in_union;
  });

  return formData;
}

// Function to populate data for Health and Safety or Skill Upgradation Trainings
export function populateTrainingData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = {  health_Safety_Training: {}, skill_Upgradation_Training: {} };
    }

    const genderKey = row.gender;
    formData[financialYear].health_Safety_Training[genderKey] = row.health_safety_training;
    formData[financialYear].skill_Upgradation_Training[genderKey] = row.skill_upgradation_training;
  });

  return formData;
}

// Function to populate data for Injury Data
export function populateSafetyRelatedData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = { fatalities: {}, total_recordable_work_related_injuries: {}, 'High consequence work-related injury or ill-health (excluding fatalities)': {}, 'Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)': {} };
    }

    const genderKey = row.gender;
    formData[financialYear].fatalities[genderKey] = row.fatalities;
    formData[financialYear].total_recordable_work_related_injuries[genderKey] = row.total_recordable_work_related_injuries;
    formData[financialYear]['High consequence work-related injury or ill-health (excluding fatalities)'][genderKey] = row.high_consequence_work_related_injury_or_ill_health;
    formData[financialYear]['Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)'][genderKey] = row.lost_time_injury_frequency_rate;
  });

  return formData;
}

// Function to populate data for Condition and Safety
export function populateComplaintsData(data, employmentCategory) {

  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = {  Working_Conditions: {}, Health_and_Safety: {} };
    }

    
      formData[financialYear][row.complaint_type] = {
        'filed during the year': row.no_of_complaint_per_year,
        "pending resolution at the end of year": row.pending_resolution        ,
        remark: row?.remark
      }
    
  });
// console.log(formData)
  return formData;
}
export function populateRehabilitation(data,employmentCategory) {
  // console.log(data);
  
  const formData = {};
  const filteredData = [...data]
  filteredData.forEach(row => {
    const financialYear = row.financial_year;

    // Ensure that the financialYear exists in the formData object
    if (!formData[financialYear]) {
      formData[financialYear] = {
        // site: '',
        employees: { 'number of individual affected': 0, 'number of individual/family member rehabilitated or placed in suitable employment': 0 },
        workers: { 'number of individual affected': 0, 'number of individual/family member rehabilitated or placed in suitable employment': 0 }
      };
    }

    // Add the total number of employees and workers 'Number of individual affected' and rehabilitated
    formData[financialYear].employees['number of individual affected'] += Number(row.individuals_affected);
    formData[financialYear].employees['number of individual/family member rehabilitated or placed in suitable employment'] += Number(row.individuals_or_family_rehabilitated_or_employed);
    formData[financialYear].workers['number of individual affected']+= Number(row.individuals_affected);
    formData[financialYear].workers['number of individual/family member rehabilitated or placed in suitable employment'] += Number(row.individuals_or_family_rehabilitated_or_employed);
  });

  return formData;
}

export function populateContractual(data,employmentCategory) {
  const formData = {};
  const filteredData = [...data];
  filteredData.forEach(row => {
    const site = row.site;
    const financialYear = row.financial_year;

    // Ensure that the site and financialYear exists in the formData object
    if (!formData[financialYear]) {
      formData[financialYear] = {};
    }

    if (!formData[financialYear][site]) {
      formData[financialYear][site] = {
        contracts: []
      };
    }

    // Add the contract details
    formData[financialYear][site].contracts.push({
      contract_type: row.contract_type,
      contact_detail: row.contact_detail,
      type_of_work: row.type_of_work,
      no_of_emp: row.no_of_emp
    });
  });

  return formData;
}

export function transformDataOHS(inputArray) {
  // Create an empty object to hold the transformed data
 
  const transformedData = {};
  const revData=[...inputArray].reverse()

  revData.forEach((item) => {
    
      const { financial_year, site, metric, count } = item;

      // Ensure financial year exists in the transformed object
      if (!transformedData[financial_year]) {
          transformedData[financial_year] = {};
      }

      // Ensure site exists in the financial year object
      if (!transformedData[financial_year][site]) {
        transformedData[financial_year][site] = {};
      }

      // Add the metric to the site object
      transformedData[financial_year][site][metric] = {
          count
      };
  });
  return transformedData;
}

export function populateGovernance(data,employmentCategory) {
  const formData = {};
  const filteredData = [...data];
  filteredData.forEach(row => {
    const financialYear = row.financial_year;
    const gender = row.gender;

    // Ensure that the financialYear exists in the formData object
    if (!formData[financialYear]) {
      formData[financialYear] = {
        // financialYear: financialYear,
        // site: row.site_id,
        board_of_Directors: {
          male: 0,
          female: 0,
          other: 0,
          'not disclosed': 0,
        },
        key_Management_Personnel: {
          male: 0,
          female: 0,
          other: 0,
          'not disclosed': 0,
        },
      };
    }

    // Assign the values from the row to the corresponding gender and category
    if (row.board_of_directors !== undefined) {
      formData[financialYear].board_of_Directors[gender] = row.board_of_directors;
    }
    if (row.key_management_personnel !== undefined) {
      formData[financialYear].key_Management_Personnel[gender] = row.key_management_personnel;
    }

  });

  return formData;
}



