
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "./InputField";
import { ProgressBar } from "./ProgressBar";
import { ReactComponent as DownloadIcon } from "../../../app/assets/DownloadIcon.svg";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { TempleteDocument } from "../Document";
import { getPdfAllData } from "../Redux/BRSRThunk";
import useApi from "../../../customHooks/useApi";
import { setBaseYearDates, setFinancialYearFilter, setReportYearDates } from "../Redux/BRSRSlices";

export const ReportFilters = ({ setFinancialYear }) => {
  const dispatch = useDispatch();
  const api = useApi();
  const { AllData, renewableData, nonrenewableData,reportYearDates,baseYearDates } = useSelector((state) => state.BRSR);

  const data = { ...AllData, ...renewableData, ...nonrenewableData };

  useEffect(() => {
    dispatch(getPdfAllData({ api }));
  }, [dispatch, api]);
  useEffect(() => {
    const calculateFinancialYear = (yearData) =>
      new Date(`${yearData.startDate}-01`).getMonth() < 3
        ? yearData.startDate.slice(0, 4) - 1
        : yearData.startDate.slice(0, 4);

    dispatch(
      setFinancialYearFilter({
        baseYear: calculateFinancialYear(baseYearDates),
        reportYear: calculateFinancialYear(reportYearDates),
      })
    );
  }, [baseYearDates, reportYearDates, dispatch]);

  return (
    <div className="flex items-center justify-between mb-6 overflow-hidden">
      {/* Report Year Input */}
      <InputField
        label="Report year"
        startDate={reportYearDates.startDate}
        endDate={reportYearDates.endDate}
        setStartDate={(startDate) =>dispatch(setReportYearDates({ ...reportYearDates.endDate, startDate }))}
        setEndDate={(endDate) => dispatch(setReportYearDates({ ...reportYearDates.startDate, endDate }))}
        setFinancialYear={setFinancialYear}
        fieldKey="reportYear"
      />

      {/* Base Year Input */}
      <InputField
        label="Base year"
        startDate={baseYearDates.startDate}
        endDate={baseYearDates.endDate}
        setStartDate={(startDate) => dispatch(setBaseYearDates({ ...baseYearDates.endDate, startDate }))}
        setEndDate={(endDate) => dispatch(setBaseYearDates({ ...baseYearDates.startDate, endDate }))}
        setFinancialYear={setFinancialYear}
        fieldKey="baseYear"
      />

      {/* Progress and Download Button */}
      <div className="flex items-center space-x-2">
        <ProgressBar progress={36} />
        <button className="bg-green-500 text-white px-4 py-2 rounded-md flex justify-center items-center gap-1">
          Index
          <span>
            <DownloadIcon className="text-white fill-white" />
          </span>
        </button>
        <PDFDownloadLink document={<TempleteDocument data={data} />} fileName="BRSR report.pdf">
          {({ loading }) =>
            loading ? (
              <span className="text-xs">Loading...</span>
            ) : (
              <button className="bg-green-500 text-white px-4 py-2 rounded-md flex justify-center items-center gap-1 flex-shrink-0">
               <span className="flex flex-shrink-0">PDF</span> 
                <span>
                  <DownloadIcon className="text-white fill-white" />
                </span>
              </button>
            )
          }
        </PDFDownloadLink>
      </div>
    </div>
  );
};
