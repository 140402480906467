
import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import React, { useEffect, useState } from "react";
import { ReactComponent as RecyclingIcon } from "../../app/assets/RecyclingIcon.svg";
import { ReactComponent as WaterDischargeIcon } from "../../app/assets/WaterandEffluents.svg";
import { ReactComponent as WithdrawaldIcon } from "../../app/assets/WaterandEffluents.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Header from "../../components/common/Header";
import TableMeta from "../../components/common/Table/TableMeta";
import TablePagination from "../../components/common/Table/TablePagination";

import useApi from "../../customHooks/useApi";

import TableComponent from "./TableComponent";
import { useSearchParams } from "react-router-dom";
import FilterComponent from "../../components/common/FilterComponent";
import { resetWaterAndEffluentsForm, setCurrentPage, setFilters, setFormValues, setItemsPerPage, setWaterAndEffluentsRecordsDischarged, setWaterAndEffluentsWithdrawalForm, setWaterandEffluentsWithdrawalForm } from "../../features/waterandeffluents/WaterandEffluentsSlice";
import { deleteWaterandEffluentsData, fetchDischargeDestinationMaster, fetchDischargeQualityMaster, fetchSiteData, fetchUnitData, fetchWaterandEffluentsInputData, fetchWaterSourceMaster, fetchWaterTypeMaster, updateWaterandEffluentsData } from "../../features/waterandeffluents/WaterandEffluentsThunk";
import WaterandEffluentsFormPopUp from "../../components/WaterandEffluents/WaterandEffluentsFormPopUp";

const WaterandEffluents = () => {
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const read_only = useSelector((state) => state?.auth?.read_only);
  const [searchParams, setSearchParams] = useSearchParams();
  const api = useApi();
  const dispatch = useDispatch();
  const { formValues,WaterAndEffluentsRecordsDischarged, WaterAndEffluentsWithdrawalInputRecords ,siteDataForFilter} =
    useSelector((state) => state?.WaterAndEffluents);
  const { itemsPerPage, totalPages, totalCount, currentPage } = useSelector(
    (state) => state?.WaterAndEffluents
  );

  const [files, setFiles] = useState(null);
  const [manualEntry, setManualEntry] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedWaterType, setSelectedWaterType] = useState(1);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isWaterandEffluentsFormPopupOpen, setIsWaterandEffluentsFormPopupOpen] =
    useState(false);
    const filters = [
      {
        name: "Withdrawal_site_id",
        label: "Facility",
        options:
          siteDataForFilter &&
          siteDataForFilter.map((site) => ({
            value: site.id,
            label: site.site,
          })),
      }
     
    ];
  const headingsToDataKeyMap = {
    " ": "copy",
    "PERIOD START": "withdrawal_period_start",
    "PERIOD END": "withdrawal_period_end",
    FACILITY: "site",
    "WATER SOURCE": "withdrawal_water_source",
    "WATER TYPE": "withdrawal_water_type",
    "WITHDRAWAL QUANTITY": "withdrawal_quantity",
    UNIT: "unit",
    // "AMOUNT SPENT": "amount_spent",
    STATUS: "status",
    EVIDENCE: "evidence",
   
  };
  if (!read_only) {
    headingsToDataKeyMap.EDIT = "edit";
    headingsToDataKeyMap.DELETE = "delete";
  }
  const headingsToDataKeyMapforDischarge = {
    // " ": "copy",
    "PERIOD START": "discharge_period_start",
    "PERIOD END": "discharge_period_end",
    FACILITY: "site",
    "DISCHARGE DESTINATION": "discharge_destination",
    "DISCHARGE QUALITY": "discharge_quality",
    "DISCHARGE QUANTITY": "discharge_quantity",
    UNIT: "discharge_unit",
    STATUS: "status",
    EVIDENCE: "evidence",
    // EDIT: "edit",
    // DELETE: "",
  };
  if (!read_only) {
    headingsToDataKeyMapforDischarge.EDIT = "edit";
    headingsToDataKeyMapforDischarge.DELETE = "delete";
  }
  const handleDelete = (row) => {
   

    if (selectedWaterType === 2) {
      if (row.id && row.sub_id) {
      
        dispatch(setWaterAndEffluentsWithdrawalForm(WaterAndEffluentsWithdrawalInputRecords.find(i=>i.id==row.id)))
        dispatch(
          updateWaterandEffluentsData({
            api,
            id: row.id,
            formValues:WaterAndEffluentsRecordsDischarged.filter(i=>i.id==row.id&&i.sub_id!=row.sub_id),
          })
        );
      } else {
        toast.error("Failed please try again");
      }
    } else if (selectedWaterType === 1) {
      if (row.id) {
        dispatch(deleteWaterandEffluentsData({ api, id: row.id }));
      } else {
        toast.error("Failed please try again");
      }
    } else {
      toast.error("Invalid request");
    }
  };
  const handleEdit = (row) => {
    
    dispatch(resetWaterAndEffluentsForm());

      if (row.id) {
        dispatch(
          setFormValues([
            ...WaterAndEffluentsRecordsDischarged.filter((i) => i.id == row.id),
          ])
        );
        dispatch(setWaterAndEffluentsWithdrawalForm(WaterAndEffluentsWithdrawalInputRecords.find(i=>i.id==row.id)))
      } else {
        toast.error("Failed please try again");
      }
    setActionType("edit");
    setSelectedRowData(row);
    setIsWaterandEffluentsFormPopupOpen(true);
  };

  const handleCopy = (row) => {
    dispatch(resetWaterAndEffluentsForm());
    if (row.id) {
      dispatch(
        setFormValues([
          ...WaterAndEffluentsRecordsDischarged.filter((i) => i.id == row.id),
        ])
      );
      dispatch(setWaterAndEffluentsWithdrawalForm(WaterAndEffluentsWithdrawalInputRecords.find(i=>i.id==row.id)))
    } else {
      toast.error("Failed please try again");
    }
    setActionType("copy");
    setSelectedRowData(row);
    setIsWaterandEffluentsFormPopupOpen(true);
  };

  const openPopupForm = () => {
    dispatch(resetWaterAndEffluentsForm());
  
    setActionType("newEntry");
    setIsWaterandEffluentsFormPopupOpen(true);
  };

  const closePurchasePopupForm = () => {
    dispatch(resetWaterAndEffluentsForm());
    setIsWaterandEffluentsFormPopupOpen(false);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };
  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };

  const handleFileDownload = (filePath) => {

    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };
  const handleFilterChange = async(newFilters) => {
    // fetchData(newFilters);
    if(!newFilters.treatment_mechanism_id==""){
      // dispatch(setWaterAndEffluentsRecordsDischarged(StoreDischarge.filter(i=>i.treatment_mechanism_id==newFilters.treatment_mechanism_id)))
     return
    }else{

      // dispatch(fetchWaterandEffluentsInputData({ api, params: newFilters }));
    }
    const updatedParams = new URLSearchParams(newFilters).toString();
    dispatch(setFilters(updatedParams))
    setSearchParams(updatedParams);
    
  };
  const handleFormChange = async (e) => {
  
  };


  const handleWaterTypeChange = (type) => {
    setSearchParams(new URLSearchParams());
    setSelectedWaterType(type);
    handleFilterChange({})
  };

  useEffect(() => {
    const filters = Object.fromEntries(searchParams.entries());
    const initializeData = async () => {
   
      await dispatch(fetchWaterandEffluentsInputData({ api,params:filters }));
      await dispatch(fetchUnitData({ api }));
      await dispatch(fetchWaterSourceMaster({ api }));
      await dispatch(fetchWaterTypeMaster({ api }));
      await dispatch(fetchWaterSourceMaster({ api }));
      await dispatch(fetchSiteData({ api }));
      await dispatch(fetchDischargeQualityMaster({ api }));
      await dispatch(fetchDischargeDestinationMaster({ api }));
     
    };

    initializeData();
  }, [dispatch, api]);

  return (
    <div className="flex flex-col main-container w-full min-h-screen">
      <Header
        pageTitle="Water and Effluents"
        PageIcon={RecyclingIcon}
        onClick={openPopupForm}
      />
      <div className="flex flex-col main-container w-full px-10 py-6 h-full">
        <div className="flex justify-between items-center text-xs">
          <div className="flex">
            <button
              className={`flex  items-center border border-green-600 rounded-md mr-4 cursor-pointer px-2 flex-shrink-0 h-8 ${
                selectedWaterType === 1
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleWaterTypeChange(1)}
            >
              <WithdrawaldIcon className="fill-current w-4 h-4" />
              <span className="px-2">Water Withdrawal</span>
            </button>
            <button
              className={`flex border border-green-600 items-center rounded-md mr-4 cursor-pointer px-2  flex-shrink-0 h-8 ${
                selectedWaterType === 2
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleWaterTypeChange(2)}
            >
              <WaterDischargeIcon className="fill-current w-4 h-4 stroke-gray-400" />
              <span className="px-2">Water Discharge</span>
            </button>
          </div>
          <div className="flex  justify-between items-center text-xs">
          <div className="flex"></div>
          <div className="flex">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={1}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />

          {manualEntry && (
            <>
              {" "}
              {selectedWaterType === 1 && (
                <TableComponent
                  headings={headingsToDataKeyMap}
                  dataRows={WaterAndEffluentsWithdrawalInputRecords}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  handleFileDownload={handleFileDownload}
                />
              )}
              {selectedWaterType === 2 && (
                <TableComponent
                  headings={headingsToDataKeyMapforDischarge}
                  dataRows={WaterAndEffluentsRecordsDischarged}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  handleFileDownload={handleFileDownload}
                />
              )}
              <div className="flex w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                <AddIconBig onClick={() => openPopupForm(1)} />
              </div>
            </>
          )}

          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
          <TablePagination
            paginationObject={{
              paginationEnabled: true,
              currentPage: currentPage,
              totalPages: totalPages,
              itemsPerPage: itemsPerPage,
              handleItemsPerPage: handleItemsPerPage,
              handlePageChange: handlePageChange,
            }}
          />
        </div>
      </div>
      {isWaterandEffluentsFormPopupOpen && (
        <WaterandEffluentsFormPopUp
          selectedWaterType={selectedWaterType}
          handleFormChange={handleFormChange}
          file={files}
          type={actionType}
          data={selectedRowData}
          closePopup={closePurchasePopupForm}
        />
      )}
    </div>
  );
};

export default WaterandEffluents;
